import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Divider, Grid, Message, Tab } from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import {
    useAddDerogationTaxMutation,
    useGetDegatoriesTaxesQuery
} from "../../../../../Services/InsurancePackage/Queries";
import { VersionDTO } from "../../../../../Services/Version/Types";
import DerogatoriesTaxesAccordion from "./DerogatoriesTaxesAccordion";

type CoverageTaxesPropsType = {
    insurancePackageId: string;
    registryCode: string;
    version: VersionDTO;
};

const DerogatoriesTaxes = ({insurancePackageId, registryCode, version}: CoverageTaxesPropsType) => {
    const {t} = useTranslation();
    const [activeConditionIndex, setActiveConditionIndex] = useState<number>(0);

    const addDerogationTaxMutation = useAddDerogationTaxMutation(insurancePackageId, registryCode);
    const getDerogatoriesTaxes = useGetDegatoriesTaxesQuery(insurancePackageId, registryCode);

    const onAddConditionValidation = () => {
        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            taxCategoryCode: "",
            registryCode: registryCode,
        };
        addDerogationTaxMutation.mutate(condition);
    };

    return (
        <Tab.Pane>
            <Message info>
                <Message.Header>{t("Derogatories Taxes by insurance package")}</Message.Header>
                <Message.Content>{t("Message info derogatories taxes")}</Message.Content>
            </Message>
            <Divider />
            {getDerogatoriesTaxes.status === "success" &&
            getDerogatoriesTaxes.data !== undefined &&
            getDerogatoriesTaxes.data.length === 0 ? (
                <Message warning style={{marginBottom: 0}}>
                    <Message.Header>{t("No derogatories taxes")}</Message.Header>
                    <Message.Content>{t("Message warning derogatories taxes")}</Message.Content>
                </Message>
            ) : (
                getDerogatoriesTaxes.status === "success" &&
                getDerogatoriesTaxes.data !== undefined && (
                    <Accordion styled fluid>
                        {getDerogatoriesTaxes.data
                            .sort((a, b) => a.conditionOrder - b.conditionOrder)
                            .map((condition, index) => (
                                <DerogatoriesTaxesAccordion
                                    key={index}
                                    insurancePackageId={insurancePackageId}
                                    numberOfConditions={getDerogatoriesTaxes.data.length}
                                    index={index}
                                    condition={condition}
                                    activeIndex={activeConditionIndex}
                                    setActiveIndex={setActiveConditionIndex}
                                    registryCode={registryCode}
                                />
                            ))}
                    </Accordion>
                )
            )}

            {version.pipDate === null && (
                <Grid style={{marginTop: 0}}>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <ModalAdd
                                isPending={addDerogationTaxMutation.isPending}
                                isSuccess={addDerogationTaxMutation.isSuccess}
                                isError={addDerogationTaxMutation.isError}
                                resetMutation={addDerogationTaxMutation.reset}
                                error={addDerogationTaxMutation.error}
                                onValidate={() => onAddConditionValidation()}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="add-condition"
                                renderModalContent={() => (
                                    <Message info>
                                        {t(
                                            "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                        )}
                                    </Message>
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Tab.Pane>
    );
};

export default DerogatoriesTaxes;
