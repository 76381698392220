import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetProductRetrocessionsQuery} from "../../../Services/ProductRetrocession/Queries";
import ProductRetrocessionDetails from "./Fragments/ProductRetrocessionDetails";

export interface RouteParams extends Params {
    id: string;
    retrocessionId: string;
}

const ProductRetrocessionPage = ({...props}) => {
    const {t} = useTranslation();
    const routeParams = useParams() as RouteParams;
    const [menus, setMenus] = useState<any>([]);
    const {data: retrocessions, isSuccess} = useGetProductRetrocessionsQuery(routeParams.id);

    useEffect(() => {
        if (isSuccess && retrocessions !== undefined) {
            const sideBarItems = new SidebarMenu(
                "",
                retrocessions.map(
                    (retrocessions) =>
                        new SidebarItem(
                            String(retrocessions.businessProviderRank),
                            t("Business provider") + " " + String(retrocessions.businessProviderRank),
                            `/products/${routeParams.id}/retrocessions/${retrocessions.id}`
                        )
                )
            );
            setMenus(sideBarItems);
        }
    }, [isSuccess, retrocessions, routeParams.id, t]);

    return (
        <Container fluid>
            <Sidebar title={t("Retrocessions")} menus={[menus]} />
            <CustomBreadcrumb sections={props.sections} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <ProductRetrocessionDetails
                                productId={routeParams.id}
                                retrocessionId={routeParams.retrocessionId}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default ProductRetrocessionPage;
