import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, Params, useParams} from "react-router-dom";
import {Card, Grid, Input, Label, Menu, Message, Segment, SemanticCOLORS, Tab} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetQuestionsQuery} from "../../../../../Services/Question/Queries";
import {QuestionDTO, QuestionValueTypeEnum} from "../../../../../Services/Question/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import {
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsQuestionType,
} from "../../../../../Utils/SortUtils";
import ModalAddQuestion from "../../../Question/Fragments/ModalAddQuestion";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PropsType = {
    version: VersionDTO | undefined;
};

const QuestionsList: React.FC<PropsType> = ({version}: PropsType) => {
    const {t} = useTranslation();
    const userContext = useContext(AuthContext);
    const {id: productId, versionId} = useParams() as IParams;

    const getQuestionsQuery = useGetQuestionsQuery(versionId);
    const [sortMethod, setSortMethod] = useState<(a: any, b: any) => number>(() => (a: any, b: any) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    const sortOptions = [
        ...sortOptionsName,
        ...sortOptionsId,
        ...sortOptionsModificationDate,
        ...sortOptionsQuestionType,
    ];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (name: string, valueType: QuestionValueTypeEnum) => {
        return (
            name.toLowerCase().includes(search.toLowerCase()) ||
            valueType.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const getColorOfValueTypeLabel = (questionValueType: QuestionValueTypeEnum): SemanticCOLORS | undefined => {
        switch (questionValueType) {
            case QuestionValueTypeEnum.STRING:
                return "blue";
            case QuestionValueTypeEnum.DATE:
                return "green";
            case QuestionValueTypeEnum.NOMBRE:
                return "purple";
            case QuestionValueTypeEnum.LISTE:
                return "brown";
            default:
                return undefined;
        }
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>

                                <Menu.Item>
                                    <Input
                                        name="searchQuestion"
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "female",
                                            entity: t("Question").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>

                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddQuestion />
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getQuestionsQuery.isSuccess &&
                            getQuestionsQuery.data !== undefined &&
                            getQuestionsQuery.data.length === 0 && (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No question")}
                                        content={t("There is no question for this version")}
                                    />
                                </Segment>
                            )}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getQuestionsQuery.isSuccess &&
                                getQuestionsQuery.data !== undefined &&
                                getQuestionsQuery.data.length !== 0 &&
                                getQuestionsQuery.data
                                    .slice()
                                    .filter((question: QuestionDTO) =>
                                        hiddenBySearch(question.name, question.valueType)
                                    )
                                    .sort(sortMethod)
                                    .map((question: QuestionDTO, index: number) => (
                                        <Card
                                            key={`question-${index}`}
                                            as={Link}
                                            to={`/products/${productId}/versions/${versionId}/questions/${question.id}`}>
                                            <Card.Content>
                                                <Card.Header>
                                                    <TextOverflow value={question.name} />
                                                </Card.Header>
                                                <Card.Description>
                                                    <Label
                                                        data-cy="question-card-valueType"
                                                        color={getColorOfValueTypeLabel(question.valueType)}>
                                                        {t("enums.QuestionValueType." + question.valueType)}
                                                    </Label>
                                                </Card.Description>

                                                {question.defaultValue && (
                                                    <Card.Description
                                                        style={{marginTop: "10px"}}
                                                        data-cy="question-card-defaultValue">
                                                        {t("Default value")} :{" "}
                                                        <strong>
                                                            {(question?.valueType == QuestionValueTypeEnum.DATE &&
                                                                t("format_date", {value: question?.defaultValue})) ||
                                                                question?.defaultValue}
                                                        </strong>
                                                    </Card.Description>
                                                )}

                                                {question.mandatory && (
                                                    <Label corner="right" color="orange" size="tiny" icon="asterisk" />
                                                )}
                                            </Card.Content>

                                            {userContext.user !== undefined && (
                                                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                                                    <span>
                                                        {displayAuditLog(
                                                            {
                                                                entityContext: "female",
                                                                createdDate: question.createdDate,
                                                                modifiedDate: question.modifiedDate,
                                                                createdBy: question.createdBy,
                                                                modifiedBy: question.modifiedBy,
                                                                createdOnVersionId: question.createdOnVersionId,
                                                            },
                                                            userContext.user,
                                                            versionId,
                                                            t
                                                        )}
                                                    </span>
                                                </Card.Content>
                                            )}
                                        </Card>
                                    ))}
                        </div>

                        {getQuestionsQuery.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default QuestionsList;
