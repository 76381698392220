import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "@tanstack/react-query";
import { Button, Card, Input, Menu, Modal } from "semantic-ui-react";
import { SystemVariableDTO, SystemVariableList } from "../../Services/Variables/Types";
import { SortOption } from "../../Utils/SortUtils";
import Sort from "../Sort/Sort";

type SystemVariablePickPropsType = {
    object: string;
    objectContext: "male" | "female";
    entityListGetMethod: (...args: any[]) => UseQueryResult<SystemVariableList, Error>;
    entityListGetParameters: any[];
    renderCardContent: (entity: SystemVariableDTO) => JSX.Element;
    filterEntity: (entity: SystemVariableDTO) => boolean;
    filterBySearch: (entity: SystemVariableDTO, search: string) => boolean;
    onClickOnEntity: (entity: SystemVariableDTO) => void;
    onCancel: () => void;
    sortOptions?: SortOption[];
    defaultSortMethod?: (a: SystemVariableDTO, b: SystemVariableDTO) => number;
};

const SystemVariablePicker = ({
    object,
    objectContext,
    entityListGetMethod,
    entityListGetParameters,
    renderCardContent,
    filterEntity,
    filterBySearch,
    onClickOnEntity,
    onCancel,
    sortOptions,
    defaultSortMethod,
}: SystemVariablePickPropsType) => {
    const {t} = useTranslation();

    const renderCard = (entity: any, index: number) => {
        return (
            <Card
                key={"card_" + object + "_" + index}
                onClick={() => onClickOnEntity(entity)}
                style={{display: filterBySearch(entity, search) ? "flex" : "none"}}>
                {renderCardContent(entity)}
            </Card>
        );
    };

    const {data: entityList, status} = entityListGetMethod(...entityListGetParameters);
    const [sortMethod, setSortMethod] = useState<(a: any, b: any) => number>(
        defaultSortMethod !== undefined ? () => defaultSortMethod : () => (a: any, b: any) => 1
    );
    const [search, setSearch] = useState("");

    return (
        <Modal open size="large" dimmer="blurring" data-cy="entityPicker">
            <Modal.Header>
                {t("Choose", {
                    name: t(object).toLowerCase(),
                    context: objectContext,
                })}
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                {sortOptions !== undefined && sortOptions.length !== 0 && (
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                <Input
                                    icon="search"
                                    placeholder={t("Find entity", {
                                        context: objectContext,
                                        entity: t("enums.ConditionKindEnum." + object.toUpperCase()).toLowerCase(),
                                    })}
                                    defaultValue={search}
                                    onChange={(event, data) => setSearch(data.value)}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <Card.Group itemsPerRow={4}>
                        {status === "success" &&
                            entityList !== undefined &&
                            entityList.dynamicSystemVariable.length !== 0 &&
                            entityList.dynamicSystemVariable
                                .slice()
                                .sort(sortMethod)
                                .filter(filterEntity)
                                .map((entity, index) => renderCard(entity, index))}
                        {status === "success" &&
                            entityList !== undefined &&
                            entityList.staticSystemVariable.length !== 0 &&
                            entityList.staticSystemVariable
                                .slice()
                                .sort(sortMethod)
                                .filter(filterEntity)
                                .map((entity, index) => renderCard(entity, index))}
                    </Card.Group>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onCancel}>
                    {t("Cancel")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SystemVariablePicker;
