import FileSaver from "file-saver";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Grid, Header, Icon, Menu, Segment} from "semantic-ui-react";
import FormDimensionValue from "../../../../../Components/Forms/FormDimensionValue";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {downloadDimensionValues} from "../../../../../Services/PricingGrid/AxiosRequests";
import {
    useAddPricingGridDimensionValueMutation,
    useGetPricingGridDimensionQuery,
    useUploadDimensionValues,
} from "../../../../../Services/PricingGrid/Queries";
import {DimensionGridValueDTOWithId} from "../../../../../Services/PricingGrid/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import PricingGridDimensionValueCard from "./PricingGridDimensionValueCard";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PricingGridDimensionValuesViewPropsType = {
    version: VersionDTO;
    gridCode: string;
    dimensionId: number;
};

const PricingGridDimensionValuesView = ({version, gridCode, dimensionId}: PricingGridDimensionValuesViewPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    // Get dimension
    const {data: dimension, status: getPricingGridDimensionQueryStatus} = useGetPricingGridDimensionQuery(
        params.gridId,
        dimensionId.toString()
    );

    // Add dimension value
    const addDimensionValueMutation = useAddPricingGridDimensionValueMutation(
        params.versionId,
        params.gridId,
        dimensionId.toString()
    );

    const addDimensionValueForm = useForm<any>();
    const submitDimensionValue = (form: any) => {
        addDimensionValueMutation.mutate({...form});
    };

    const [defaultValueDimensionValueCode, setDefaultValueDimensionValueCode] = useState<string>("");
    const [defaultValueDimensionValueLabel, setDefaultValueDimensionValueLabel] = useState<string>("");
    const [defaultValueDimensionValueMin, setDefaultValueDimensionValueMin] = useState<string>("");
    const [defaultValueDimensionValueMax, setDefaultValueDimensionValueMax] = useState<string>("");

    const uploadMutation = useUploadDimensionValues(params.versionId, params.gridId, dimensionId.toString());

    const handleExportDimensionValues = async () => {
        if (getPricingGridDimensionQueryStatus === "success" && dimension) {
            const blob = new Blob([await downloadDimensionValues(params.gridId, dimensionId.toString())], {
                type: "text/csv",
            });
            FileSaver.saveAs(blob, gridCode + "_" + dimension.code + ".csv");
        }
    };

    const handleUploadGrid = () => {
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.click();
        }
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    const {columnNumber, columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Grid.Column width={12}>
            {getPricingGridDimensionQueryStatus === "success" && dimension && (
                <Segment>
                    <Menu secondary>
                        <Menu.Item position="left">
                            <Header size="medium">{`${dimension.code} - ${t("Dimension_values")}`}</Header>
                        </Menu.Item>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                {version.pipDate === null && (
                                    <ModalAdd
                                        isValidationDisabled={
                                            Object.keys(addDimensionValueForm.formState.errors).length !== 0
                                        }
                                        isPending={addDimensionValueMutation.isPending}
                                        isSuccess={addDimensionValueMutation.isSuccess}
                                        isError={addDimensionValueMutation.isError}
                                        resetMutation={addDimensionValueMutation.reset}
                                        error={addDimensionValueMutation.error}
                                        onValidate={addDimensionValueForm.handleSubmit(submitDimensionValue)}
                                        onSuccess={() => {
                                            setDefaultValueDimensionValueCode("");
                                            setDefaultValueDimensionValueLabel("");
                                            setDefaultValueDimensionValueMin("");
                                            setDefaultValueDimensionValueMax("");
                                        }}
                                        onCancel={() => {
                                            setDefaultValueDimensionValueCode("");
                                            setDefaultValueDimensionValueLabel("");
                                            setDefaultValueDimensionValueMin("");
                                            setDefaultValueDimensionValueMax("");
                                        }}
                                        iconOnOpenButton
                                        openButtonIcon="add"
                                        objectToAdd="Dimension_value"
                                        objectToAddContext="female"
                                        renderModalContent={() => {
                                            return (
                                                <FormDimensionValue
                                                    errors={addDimensionValueForm.formState.errors}
                                                    control={addDimensionValueForm.control}
                                                    dimension={dimension}
                                                    defaultValueCode={defaultValueDimensionValueCode}
                                                    setDefaultValueCode={setDefaultValueDimensionValueCode}
                                                    defaultValueLabel={defaultValueDimensionValueLabel}
                                                    setDefaultValueLabel={setDefaultValueDimensionValueLabel}
                                                    defaultValueMin={defaultValueDimensionValueMin}
                                                    setDefaultValueMin={setDefaultValueDimensionValueMin}
                                                    defaultValueMax={defaultValueDimensionValueMax}
                                                    setDefaultValueMax={setDefaultValueDimensionValueMax}
                                                />
                                            );
                                        }}
                                    />
                                )}

                                <Button
                                    name="download_dimension_values"
                                    positive
                                    onClick={handleExportDimensionValues}
                                    icon="download"
                                    style={{margin: "0 0.25em 0 0.25em"}}
                                />

                                {version.pipDate === null && (
                                    <>
                                        {selectedFile !== null && (
                                            <ModalUpdate
                                                hidden
                                                isModalOpenAtStart
                                                customContent={t("You are about to replace all dimensions values")}
                                                onSuccess={clearSelectedFile}
                                                onCancel={clearSelectedFile}
                                                onClose={clearSelectedFile}
                                                resetMutation={uploadMutation.reset}
                                                isSuccess={uploadMutation.isSuccess}
                                                isPending={uploadMutation.isPending}
                                                isError={uploadMutation.isError}
                                                error={uploadMutation.error}
                                                onValidate={() => uploadMutation.mutate(selectedFile)}
                                            />
                                        )}

                                        <Button name="upload_dimension_values" onClick={handleUploadGrid} positive icon>
                                            <Icon name={"upload"} />
                                            <input
                                                ref={hiddenFileInput}
                                                type={"file"}
                                                hidden
                                                onChange={({target: {files}}: any) => setSelectedFile(files[0])}
                                            />
                                        </Button>
                                    </>
                                )}
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>

                    <div className={"ui cards " + columnNumberAsText} ref={ref}>
                        {dimension.dimensionGridValueDTOs.map(
                            (dimensionGridValueDTO: DimensionGridValueDTOWithId, index: number) => {
                                return (
                                    <PricingGridDimensionValueCard
                                        key={index}
                                        dimensionGridId={dimensionId.toString()}
                                        dimensionGridValue={dimensionGridValueDTO}
                                        type={dimension.dimensionType}
                                        valueType={dimension.dimensionValueType}
                                    />
                                );
                            }
                        )}
                    </div>
                </Segment>
            )}
        </Grid.Column>
    );
};

export default PricingGridDimensionValuesView;
