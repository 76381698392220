import {useEffect, useReducer, useState} from "react";
import {useTranslation} from "react-i18next";
import {UseMutationResult} from "@tanstack/react-query";
import {Params, useParams} from "react-router-dom";
import {Button, Card, Dropdown, Grid, GridColumn, Icon, Input, Label, Segment, SemanticCOLORS} from "semantic-ui-react";
import EntityPicker from "../../../../Components/Modals/EntityPicker";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import TextOverflow from "../../../../Components/Text/TextOverflow";
import {
    ComputingOperatorEnum,
    ComputingRuleTypeEnum,
    ParenthesisEnum,
    RulePart,
} from "../../../../Services/ComputingRule/Types";
import {ConditionKindEnum, ContentTypeEnum, OperandEnum} from "../../../../Services/Condition/Types";
import {ComputingRuleRoundingMode} from "../../../../Services/InsurancePackage/Types";
import {useGetPricingCriteriasList} from "../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItem, PricingCriteriaItemType} from "../../../../Services/PricingCriterias/Types";
import {useGetQuestionsQuery} from "../../../../Services/Question/Queries";
import {QuestionDTO, QuestionValueTypeEnum} from "../../../../Services/Question/Types";
import {CustomError} from "../../../../Services/RequestUtils";
import {useGetVariable, useGetVariablesList} from "../../../../Services/Variables/Queries";
import {Variable, VariableType, VariableValueType} from "../../../../Services/Variables/Types";
import {GenericReducerActionType} from "../../../../Utils/ReducerUtils";
import {SortOption, sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../../../Utils/SortUtils";
import {
    AssignmentType,
    VariableConditionActionType,
} from "../../Variables/Fragments/VariableConditions/VariableConditionAssignementReducer";
import "./computingRule.css";

type ConditionViewPropsType = {
    computingRuleRoundingMode: ComputingRuleRoundingMode | undefined;
    computingRuleDecimalCount: number | undefined;
    computingRuleRulePartList: RulePart[] | undefined;
    dispatchCondition: React.Dispatch<VariableConditionActionType>;
    checkSyntax: UseMutationResult<boolean, CustomError, RulePart[], Error>;
    setIsErrorVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsComputingRuleChange: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    isComputingRuleChange: boolean | undefined;
};

enum Direction {
    R = "RIGHT",
    L = "LEFT",
}

export type ActionType =
    | {type: "add"; payload: string}
    | {type: "addPrefilled"; payload: {ruleType: string; value: RulePart}}
    | {type: "remove"; payload: number}
    | {type: "move"; payload: {index: number; direction: Direction}}
    | {
          type: "edit";
          payload: {index: number; value: RulePart};
      }
    | {type: "editRoundingMode"; payload: ComputingRuleRoundingMode}
    | {type: "editDecimalCount"; payload: number}
    | {type: "updateList"; payload: RulePart[]}
    | {type: "reset"};

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

const ComputingRuleAssignmentEdit = ({
    computingRuleRoundingMode,
    computingRuleDecimalCount,
    computingRuleRulePartList,
    dispatchCondition,
    checkSyntax,
    setIsErrorVisible,
    setIsComputingRuleChange,
    isComputingRuleChange,
}: ConditionViewPropsType) => {
    const {t} = useTranslation();
    const {versionId, variableId} = useParams() as IParams;

    const computingRuleRoundingModeList = [
        {
            key: 1,
            text: t("enums.ComputingRuleRoundingMode." + ComputingRuleRoundingMode["ROUND_HALF_UP"]),
            value: "ROUND_HALF_UP",
        },
        {
            key: 2,
            text: t("enums.ComputingRuleRoundingMode." + ComputingRuleRoundingMode["ROUND_HALF_DOWN"]),
            value: "ROUND_HALF_DOWN",
        },
        {
            key: 3,
            text: t("enums.ComputingRuleRoundingMode." + ComputingRuleRoundingMode["ROUND_UP"]),
            value: "ROUND_UP",
        },
        {
            key: 4,
            text: t("enums.ComputingRuleRoundingMode." + ComputingRuleRoundingMode["ROUND_DOWN"]),
            value: "ROUND_DOWN",
        },
    ];

    const computingRuleKindList: {
        key: number;
        text: string;
        value: OperandEnum;
        type: "female" | "male";
    }[] = [
        {key: 1, text: "Question", value: OperandEnum.QUESTION, type: "female"},
        {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
        {
            key: 4,
            text: "Computing rule variables",
            value: OperandEnum.VARIABLE_COMPUTING_RULE,
            type: "female",
        },
    ];

    const computingOperatorList = [
        {key: 1, text: t("enums.ComputingOperatorEnum." + ComputingOperatorEnum["*"]), value: "*"},
        {key: 2, text: t("enums.ComputingOperatorEnum." + ComputingOperatorEnum["+"]), value: "+"},
        {key: 3, text: t("enums.ComputingOperatorEnum." + ComputingOperatorEnum["/"]), value: "/"},
        {key: 4, text: t("enums.ComputingOperatorEnum." + ComputingOperatorEnum["-"]), value: "-"},
        {key: 5, text: t("enums.ComputingOperatorEnum." + ComputingOperatorEnum["^"]), value: "^"},
    ];

    const parenthesisList = [
        {key: 1, text: t("enums.ParenthesisEnum." + ParenthesisEnum["("]), value: "("},
        {key: 2, text: t("enums.ParenthesisEnum." + ParenthesisEnum[")"]), value: ")"},
    ];

    const dropdownTypeOptions: {
        key: number;
        text: string;
        value: ComputingRuleTypeEnum;
        optionList?: {key: number; text: string; value: string}[];
    }[] = [
        {
            key: 1,
            text: t("enums.ComputingRuleTypeEnum." + ComputingRuleTypeEnum.DATA),
            value: ComputingRuleTypeEnum.DATA,
            optionList: computingRuleKindList,
        },
        {
            key: 2,
            text: t("enums.ComputingRuleTypeEnum." + ComputingRuleTypeEnum.OPERATOR),
            value: ComputingRuleTypeEnum.OPERATOR,
            optionList: computingOperatorList,
        },
        {
            key: 3,
            text: t("enums.ComputingRuleTypeEnum." + ComputingRuleTypeEnum.FIXED_VALUE),
            value: ComputingRuleTypeEnum.FIXED_VALUE,
        },
        {
            key: 4,
            text: t("enums.ComputingRuleTypeEnum." + ComputingRuleTypeEnum.PARENTHESIS),
            value: ComputingRuleTypeEnum.PARENTHESIS,
            optionList: parenthesisList,
        },
    ];

    const sortOptions: SortOption[] = [...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate];

    const {data: variablesComputingRules, status: getVariablesComputingRulesStatus} = useGetVariablesList(
        versionId,
        VariableType.R
    );
    const {data: currentVariable} = useGetVariable(versionId, variableId);

    const [roundingMode, setRoundingMode] = useState<ComputingRuleRoundingMode>(
        computingRuleRoundingMode ?? ComputingRuleRoundingMode.ROUND_HALF_UP
    );
    const [decimalCount, setDecimalCount] = useState<number>(computingRuleDecimalCount ?? 2);

    const [selectedOperand, setSelectedOperand] = useState<{
        key: number;
        text: string;
        value: OperandEnum;
        type: "female" | "male";
    }>();
    const [indexRulePartOperand, setIndexRulePartOperand] = useState<number>(0);
    const [segmentColor, setSegmentColor] = useState<SemanticCOLORS | undefined>();

    const reducerRulePartList = (state: {rulePartList: RulePart[]}, action: ActionType) => {
        const rulePartList = [...state.rulePartList];
        switch (action.type) {
            case "add":
                switch (action.payload) {
                    case ComputingRuleTypeEnum.DATA:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: "",
                            kind: null,
                            contentType: null,
                        });
                        break;
                    case ComputingRuleTypeEnum.OPERATOR:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: "*",
                            kind: ConditionKindEnum.OPERATOR,
                            contentType: null,
                        });
                        break;
                    case ComputingRuleTypeEnum.FIXED_VALUE:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: "1",
                            kind: ConditionKindEnum.NUMBER,
                            contentType: null,
                        });
                        break;
                    case ComputingRuleTypeEnum.PARENTHESIS:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: "(",
                            kind: ConditionKindEnum.OPENING_PARENTHESIS,
                            contentType: null,
                        });
                }
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: rulePartList, roundingMode: roundingMode, decimalCount: decimalCount},
                });
                return {rulePartList: rulePartList};
            case "addPrefilled":
                switch (action.payload.ruleType) {
                    case ComputingRuleTypeEnum.DATA:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: "",
                            kind: action.payload.value.kind,
                            contentType: null,
                        });
                        break;
                    case ComputingRuleTypeEnum.OPERATOR:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: action.payload.value.value,
                            kind: ConditionKindEnum.OPERATOR,
                            contentType: null,
                        });
                        break;
                    case ComputingRuleTypeEnum.FIXED_VALUE:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: "1",
                            kind: ConditionKindEnum.NUMBER,
                            contentType: null,
                        });
                        break;
                    case ComputingRuleTypeEnum.PARENTHESIS:
                        rulePartList.splice(rulePartList.length, 0, {
                            value: action.payload.value.value,
                            kind: action.payload.value.kind,
                            contentType: null,
                        });
                }
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: rulePartList, roundingMode: roundingMode, decimalCount: decimalCount},
                });
                return {rulePartList: rulePartList};

            case "remove":
                rulePartList.splice(action.payload, 1);
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: rulePartList, roundingMode: roundingMode, decimalCount: decimalCount},
                });
                return {rulePartList: rulePartList};
            case "move":
                if (action.payload.direction === Direction.L) {
                    rulePartList.splice(action.payload.index - 1, 0, rulePartList.splice(action.payload.index, 1)[0]);
                } else {
                    rulePartList.splice(action.payload.index + 1, 0, rulePartList.splice(action.payload.index, 1)[0]);
                }
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: rulePartList, roundingMode: roundingMode, decimalCount: decimalCount},
                });
                return {rulePartList: rulePartList};
            case "edit":
                rulePartList[action.payload.index] = action.payload.value;
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: rulePartList, roundingMode: roundingMode, decimalCount: decimalCount},
                });
                return {rulePartList: rulePartList};
            case "editRoundingMode":
                setRoundingMode(action.payload);
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: rulePartList, roundingMode: action.payload, decimalCount: decimalCount},
                });
                return {rulePartList: rulePartList};
            case "editDecimalCount":
                setDecimalCount(action.payload);
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: rulePartList, roundingMode: roundingMode, decimalCount: action.payload},
                });
                return {rulePartList: rulePartList};
            case "updateList":
                return {rulePartList: action.payload};
            case "reset":
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {rulePartList: [], roundingMode: roundingMode, decimalCount: decimalCount},
                });
                return {rulePartList: []};
            default:
                throw new Error();
        }
    };

    const [state, dispatchRulePartList] = useReducer(reducerRulePartList, {rulePartList: []});

    useEffect(() => {
        dispatchRulePartList({
            type: "updateList",
            payload: computingRuleRulePartList !== undefined ? computingRuleRulePartList : [],
        });
        setIsErrorVisible(false);
    }, [computingRuleRulePartList, dispatchRulePartList, setIsErrorVisible]);

    useEffect(() => {
        if (!isComputingRuleChange) {
            if (checkSyntax.isSuccess && checkSyntax.data) {
                setSegmentColor("green");
            }
            if (checkSyntax.isError || checkSyntax.data === false) {
                setSegmentColor("red");
            }
        }
    }, [checkSyntax, isComputingRuleChange]);

    const getColorOfQuestionValueType = (questionValueType: QuestionValueTypeEnum): SemanticCOLORS | undefined => {
        switch (questionValueType) {
            case QuestionValueTypeEnum.STRING:
                return "blue";
            case QuestionValueTypeEnum.DATE:
                return "green";
            case QuestionValueTypeEnum.NOMBRE:
                return "purple";
            case QuestionValueTypeEnum.LISTE:
                return "brown";
            default:
                return undefined;
        }
    };

    const getColorOfPricingCriteriaItemType = (
        pricingCriteriaItemType: PricingCriteriaItemType
    ): SemanticCOLORS | undefined => {
        switch (pricingCriteriaItemType) {
            case PricingCriteriaItemType.STRING:
                return "blue";
            case PricingCriteriaItemType.DATE:
                return "green";
            case PricingCriteriaItemType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    const getColorOfVariableType = (variableValueType: VariableValueType): SemanticCOLORS | undefined => {
        switch (variableValueType) {
            case VariableValueType.STRING:
                return "blue";
            case VariableValueType.DATE:
                return "green";
            case VariableValueType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    const isConditionKindData = (conditionKind: ConditionKindEnum | null | undefined): boolean => {
        return (
            conditionKind === ConditionKindEnum.QUESTION ||
            conditionKind === ConditionKindEnum.CRITERE ||
            conditionKind === ConditionKindEnum.VARIABLE ||
            conditionKind === ConditionKindEnum.VARIABLE_COMPUTING_RULE
        );
    };

    return (
        <>
            <Segment secondary color={segmentColor}>
                {selectedOperand !== undefined && selectedOperand.value === OperandEnum.QUESTION && (
                    <EntityPicker
                        object={selectedOperand.text}
                        objectContext={selectedOperand.type}
                        entityListGetMethod={useGetQuestionsQuery}
                        entityListGetParameters={[versionId, versionId !== undefined]}
                        renderCardContent={(question: QuestionDTO) => (
                            <Card.Content>
                                <Card.Header>
                                    <TextOverflow value={question.name} />
                                </Card.Header>
                                <Card.Description style={{marginTop: "10px"}}>
                                    <Label name="valueTypeCard" color={getColorOfQuestionValueType(question.valueType)}>
                                        {t("enums.QuestionValueType." + question.valueType)}
                                    </Label>
                                </Card.Description>
                            </Card.Content>
                        )}
                        filterEntity={(questionToFilter: QuestionDTO) =>
                            questionToFilter.valueType !== QuestionValueTypeEnum.DATE
                        }
                        filterBySearch={(questionToFilter: QuestionDTO, search: string): boolean => {
                            return (
                                questionToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                                questionToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                                search === ""
                            );
                        }}
                        onClickOnEntity={(operandItem: QuestionDTO) => {
                            const rulePart: RulePart = {
                                value: operandItem.name,
                                kind: ConditionKindEnum.QUESTION,
                                contentType: ContentTypeEnum[operandItem.valueType === "NOMBRE" ? "NUMERIC" : "STRING"],
                            };
                            dispatchRulePartList({
                                type: "edit",
                                payload: {index: indexRulePartOperand, value: rulePart},
                            });
                            setSelectedOperand(undefined);
                        }}
                        onCancel={() => setSelectedOperand(undefined)}
                        defaultSortMethod={(a: QuestionDTO, b: QuestionDTO) =>
                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                        }
                        sortOptions={sortOptions}
                    />
                )}

                {selectedOperand !== undefined && selectedOperand.value === OperandEnum.CRITERE && (
                    <EntityPicker
                        object={selectedOperand.text}
                        objectContext={selectedOperand.type}
                        entityListGetMethod={useGetPricingCriteriasList}
                        entityListGetParameters={[versionId]}
                        renderCardContent={(criteria: PricingCriteriaItem) => (
                            <Card.Content>
                                <Card.Header>{criteria.name}</Card.Header>
                                <Card.Description style={{marginTop: "10px"}}>
                                    <Label
                                        name="valueTypeCard"
                                        color={getColorOfPricingCriteriaItemType(criteria.type)}>
                                        {t("enums.PricingCriteriaType." + criteria.type)}
                                    </Label>
                                    <p style={{marginTop: "12px"}}>{criteria.description}</p>
                                </Card.Description>
                            </Card.Content>
                        )}
                        filterEntity={(criteriaToFilter: PricingCriteriaItem) =>
                            criteriaToFilter.type !== PricingCriteriaItemType.DATE
                        }
                        filterBySearch={(criteriaToFilter: PricingCriteriaItem, search: string): boolean => {
                            return (
                                criteriaToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                                criteriaToFilter.type.toLowerCase().includes(search.toLowerCase()) ||
                                search === ""
                            );
                        }}
                        onClickOnEntity={(operandItem: PricingCriteriaItem) => {
                            const type = operandItem.type;
                            const rulePart: RulePart = {
                                value: operandItem.name,
                                kind: ConditionKindEnum.CRITERE,
                                contentType: ContentTypeEnum[type],
                            };
                            dispatchRulePartList({
                                type: "edit",
                                payload: {index: indexRulePartOperand, value: rulePart},
                            });
                            setSelectedOperand(undefined);
                        }}
                        onCancel={() => setSelectedOperand(undefined)}
                        defaultSortMethod={(a: PricingCriteriaItem, b: PricingCriteriaItem) =>
                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                        }
                        sortOptions={sortOptions}
                    />
                )}

                {selectedOperand !== undefined && selectedOperand.value === OperandEnum.VARIABLE && (
                    <EntityPicker
                        object={selectedOperand.text}
                        objectContext={selectedOperand.type}
                        entityListGetMethod={useGetVariablesList}
                        entityListGetParameters={[versionId, VariableType.V, versionId !== undefined]}
                        renderCardContent={(variable: Variable) => (
                            <Card.Content>
                                <Card.Header>{variable.name}</Card.Header>
                                <Card.Description style={{marginTop: "10px"}}>
                                    <Label name="valueTypeCard" color={getColorOfVariableType(variable.valueType)}>
                                        {t("enums.VariableValueType." + variable.valueType)}
                                    </Label>
                                    <p style={{marginTop: "12px"}}>{variable.description}</p>
                                </Card.Description>
                            </Card.Content>
                        )}
                        filterEntity={(variableToFilter: Variable) =>
                            variableToFilter.valueType !== VariableValueType.DATE
                        }
                        filterBySearch={(variableToFilter: Variable, search: string): boolean => {
                            return (
                                variableToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                                variableToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                                search === ""
                            );
                        }}
                        onClickOnEntity={(operandItem: Variable) => {
                            const type = operandItem.valueType;
                            const rulePart: RulePart = {
                                value: operandItem.name,
                                kind: ConditionKindEnum.VARIABLE,
                                contentType: ContentTypeEnum[type],
                            };
                            dispatchRulePartList({
                                type: "edit",
                                payload: {index: indexRulePartOperand, value: rulePart},
                            });

                            setSelectedOperand(undefined);
                        }}
                        onCancel={() => setSelectedOperand(undefined)}
                        defaultSortMethod={(a: Variable, b: Variable) =>
                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                        }
                        sortOptions={sortOptions}
                    />
                )}

                {selectedOperand !== undefined && selectedOperand.value === OperandEnum.VARIABLE_COMPUTING_RULE && (
                    <EntityPicker
                        object={selectedOperand.text}
                        objectContext={selectedOperand.type}
                        entityListGetMethod={useGetVariablesList}
                        entityListGetParameters={[versionId, VariableType.R]}
                        renderCardContent={(variable: Variable) => (
                            <Card.Content>
                                <Card.Header>{variable.name}</Card.Header>
                                <Card.Description style={{marginTop: "10px"}}>
                                    <Label name="valueTypeCard" color={getColorOfVariableType(variable.valueType)}>
                                        {t("enums.VariableValueType." + variable.valueType)}
                                    </Label>
                                    <p style={{marginTop: "12px"}}>{variable.description}</p>
                                </Card.Description>
                            </Card.Content>
                        )}
                        filterEntity={(variableToFilter: Variable) => {
                            return (
                                variableToFilter.valueType !== VariableValueType.DATE &&
                                variableToFilter.name !== currentVariable?.name
                            );
                        }}
                        filterBySearch={(variableToFilter: Variable, search: string): boolean => {
                            return (
                                variableToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                                variableToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                                search === ""
                            );
                        }}
                        onClickOnEntity={(operandItem: Variable) => {
                            const type = operandItem.valueType;
                            const rulePart: RulePart = {
                                value: operandItem.name,
                                kind: ConditionKindEnum.VARIABLE,
                                contentType: ContentTypeEnum[type],
                            };

                            dispatchRulePartList({
                                type: "edit",
                                payload: {index: indexRulePartOperand, value: rulePart},
                            });
                            setSelectedOperand(undefined);
                        }}
                        onCancel={() => setSelectedOperand(undefined)}
                        defaultSortMethod={(a: Variable, b: Variable) =>
                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                        }
                        sortOptions={sortOptions}
                    />
                )}

                {getVariablesComputingRulesStatus === "success" &&
                    state.rulePartList !== null &&
                    state.rulePartList.map((rulePart, index) => (
                        <div style={{display: "inline-block", textAlign: "right"}} className="rulePart" key={index}>
                            <div className="icons">
                                {index > 0 && (
                                    <Icon
                                        color="black"
                                        name="long arrow alternate left"
                                        size="small"
                                        link
                                        onClick={() => {
                                            setIsComputingRuleChange(true);
                                            setSegmentColor(undefined);
                                            dispatchRulePartList({
                                                type: "move",
                                                payload: {index: index, direction: Direction.L},
                                            });
                                        }}
                                    />
                                )}
                                {index < state.rulePartList.length - 1 && (
                                    <Icon
                                        color="black"
                                        name="long arrow alternate right"
                                        size="small"
                                        link
                                        onClick={() => {
                                            setIsComputingRuleChange(true);
                                            setSegmentColor(undefined);
                                            dispatchRulePartList({
                                                type: "move",
                                                payload: {index: index, direction: Direction.R},
                                            });
                                        }}
                                    />
                                )}
                                <Icon
                                    color="red"
                                    name="close"
                                    size="small"
                                    link
                                    style={{margin: "0px"}}
                                    onClick={() => {
                                        setIsComputingRuleChange(true);
                                        setSegmentColor(undefined);
                                        dispatchRulePartList({type: "remove", payload: index});
                                    }}
                                />
                            </div>

                            <div className="rulePartItem">
                                {isConditionKindData(rulePart.kind) && (
                                    <Button
                                        className="data"
                                        basic
                                        content={rulePart.value}
                                        onClick={() => {
                                            const kind = computingRuleKindList.find(
                                                (el) => t(el.value) === t(rulePart.kind || "")
                                            );

                                            if (kind && kind.value === OperandEnum.VARIABLE) {
                                                const isComputingRule = variablesComputingRules.find(
                                                    (el) => el.name === rulePart.value
                                                );

                                                setSelectedOperand(
                                                    isComputingRule
                                                        ? computingRuleKindList.find(
                                                              (el) =>
                                                                  t(el.value) === OperandEnum.VARIABLE_COMPUTING_RULE
                                                          )
                                                        : computingRuleKindList.find(
                                                              (el) => t(el.value) === OperandEnum.VARIABLE
                                                          )
                                                );
                                            } else
                                                setSelectedOperand(
                                                    computingRuleKindList.find(
                                                        (el) => t(el.value) === t(rulePart.kind || "")
                                                    )
                                                );

                                            setIndexRulePartOperand(index);
                                            setIsComputingRuleChange(true);
                                            setSegmentColor(undefined);
                                        }}
                                    />
                                )}

                                {(rulePart.kind === ConditionKindEnum.OPENING_PARENTHESIS ||
                                    rulePart.kind === ConditionKindEnum.CLOSING_PARENTHESIS) && (
                                    <Button
                                        className="parenthesis"
                                        basic
                                        onClick={() => {
                                            setIsComputingRuleChange(true);
                                            setSegmentColor(undefined);

                                            const newRulePart: RulePart =
                                                rulePart.kind === ConditionKindEnum.OPENING_PARENTHESIS
                                                    ? {
                                                          value: ")",
                                                          kind: ConditionKindEnum.CLOSING_PARENTHESIS,
                                                          contentType: null,
                                                      }
                                                    : {
                                                          value: "(",
                                                          kind: ConditionKindEnum.OPENING_PARENTHESIS,
                                                          contentType: null,
                                                      };

                                            dispatchRulePartList({
                                                type: "edit",
                                                payload: {index, value: newRulePart},
                                            });
                                        }}>
                                        {rulePart.kind === ConditionKindEnum.OPENING_PARENTHESIS ? "(" : ")"}
                                    </Button>
                                )}

                                {rulePart.kind === ConditionKindEnum.OPERATOR && (
                                    <Button
                                        className="operator"
                                        basic
                                        content={rulePart.value}
                                        onClick={(_, value) => {
                                            setIsComputingRuleChange(true);
                                            setSegmentColor(undefined);
                                            const actualOperator = computingOperatorList.find(
                                                (operator) => operator.value === value.content
                                            );
                                            const operatorChange =
                                                computingOperatorList[actualOperator ? actualOperator.key % 5 : 0];
                                            const rulePart = {
                                                value: operatorChange.value,
                                                kind: ConditionKindEnum.OPERATOR,
                                                contentType: null,
                                            };
                                            dispatchRulePartList({
                                                type: "edit",
                                                payload: {index: index, value: rulePart},
                                            });
                                        }}
                                    />
                                )}

                                {rulePart.kind === ConditionKindEnum.NUMBER && (
                                    <Input
                                        name="fixedValue"
                                        style={{width: "100px", marginRight: "3.5px"}}
                                        placeholder={t("value")}
                                        type={"number"}
                                        value={rulePart.value}
                                        maxLength={"20"}
                                        onChange={(_, value) => {
                                            setIsComputingRuleChange(true);
                                            setSegmentColor(undefined);
                                            const rulePart = {
                                                value: value.value,
                                                kind: ConditionKindEnum.NUMBER,
                                                contentType: null,
                                            };
                                            dispatchRulePartList({
                                                type: "edit",
                                                payload: {index: index, value: rulePart},
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    ))}

                <Dropdown
                    data-cy="add-computing-rule-item-button"
                    className="addComputingRuleItem"
                    as={Button}
                    color="blue"
                    simple
                    compact
                    style={{padding: 8.25}}
                    icon={<Icon name="plus" style={{margin: 0}} />}>
                    <Dropdown.Menu>
                        {dropdownTypeOptions.map((option) => (
                            <Dropdown.Item
                                data-cy="add-computing-rule-item-first-level"
                                value={option.optionList !== undefined ? undefined : option.value}
                                key={option.key}>
                                {option.optionList !== undefined ? (
                                    <>
                                        <Icon name="dropdown" />
                                        <span className="text">{option.text}</span>
                                        <Dropdown.Menu>
                                            {option.optionList.map((opt) => (
                                                <Dropdown.Item
                                                    data-cy="add-computing-rule-item-second-level"
                                                    onClick={() => {
                                                        setSelectedOperand(
                                                            opt.value === ComputingRuleTypeEnum.DATA
                                                                ? computingRuleKindList.filter(
                                                                      (rule) => rule.value === opt.value
                                                                  )[0]
                                                                : undefined
                                                        );

                                                        if (option.value === ComputingRuleTypeEnum.PARENTHESIS) {
                                                            const rulePart = {
                                                                value: opt.value,
                                                                kind:
                                                                    opt.value === "("
                                                                        ? ConditionKindEnum.OPENING_PARENTHESIS
                                                                        : ConditionKindEnum.CLOSING_PARENTHESIS,
                                                                contentType: null,
                                                            };

                                                            dispatchRulePartList({
                                                                type: "addPrefilled",
                                                                payload: {ruleType: option.value, value: rulePart},
                                                            });
                                                        } else if (option.value === ComputingRuleTypeEnum.OPERATOR) {
                                                            const rulePart = {
                                                                value: opt.value,
                                                                kind: ConditionKindEnum.OPERATOR,
                                                                contentType: null,
                                                            };

                                                            dispatchRulePartList({
                                                                type: "addPrefilled",
                                                                payload: {ruleType: option.value, value: rulePart},
                                                            });
                                                        } else {
                                                            setIndexRulePartOperand(state.rulePartList.length);
                                                            setIsComputingRuleChange(true);
                                                            setSegmentColor(undefined);
                                                            setSelectedOperand(
                                                                computingRuleKindList.find(
                                                                    (rule) => rule.value === opt.value
                                                                )
                                                            );
                                                        }
                                                    }}
                                                    value={opt.value}
                                                    key={opt.key}>
                                                    {t(opt.text)}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </>
                                ) : (
                                    <Dropdown.Item
                                        onClick={() => {
                                            dispatchRulePartList({
                                                type: "add",
                                                payload: typeof option.value === "string" ? option.value : "",
                                            });
                                        }}
                                        value={option.value}
                                        key={option.key}>
                                        {option.text}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                {state.rulePartList !== null && state.rulePartList.length > 0 && (
                    <ModalDelete
                        isPending={false}
                        isSuccess={false}
                        isError={false}
                        onValidate={() => {
                            dispatchRulePartList({
                                type: "reset",
                            });
                        }}
                        objectToDelete={t("computing rules")}
                        objectType={"plurial"}
                        basicButton={true}
                        iconAndTextOnOpenButton={false}
                        iconOnOpenButton
                        renderOpenButton={() => (
                            <Button
                                title={t("Erase all")}
                                className="deleteAllComputingRuleItem"
                                color="red"
                                icon="close"
                                compact
                                onClick={() =>
                                    dispatchRulePartList({
                                        type: "reset",
                                    })
                                }></Button>
                        )}
                    />
                )}
            </Segment>

            <Grid columns="equal" verticalAlign="middle" className="grid-padding" textAlign="left">
                <GridColumn width={6}>
                    <Label size="large" basic style={{border: "none"}} horizontal>
                        {t("Computing rule rounding mode") + " : "}
                    </Label>
                    <Dropdown
                        selection
                        className="selectComputingRuleRoundingMode"
                        value={roundingMode}
                        options={computingRuleRoundingModeList}
                        onChange={(_, value) => {
                            dispatchRulePartList({
                                type: "editRoundingMode",
                                payload: value.value as ComputingRuleRoundingMode,
                            });
                        }}
                    />
                </GridColumn>
                <GridColumn width={6}>
                    <Label size="large" basic style={{border: "none"}} horizontal>
                        {t("Number of decimal") + " : "}
                    </Label>
                    <Input
                        type="number"
                        step="1"
                        min={0}
                        value={decimalCount}
                        onKeyPress={(e: {key: string; preventDefault: () => void}) => {
                            if (!/\d/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(_, value) => {
                            dispatchRulePartList({
                                type: "editDecimalCount",
                                payload: parseInt(value.value),
                            });
                        }}
                    />
                </GridColumn>
            </Grid>
        </>
    );
};

export default ComputingRuleAssignmentEdit;
