import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {codeValidation} from "../../Pages/Global/Form/Validation";
import React from "react";

const FormSplitting = ({
    control,
    errors,
    setValue,
    defaultValueSplittingCode,
    setDefaultValueSplittingCode,
    defaultValueSplittingLabel,
    setDefaultValueSplittingLabel,
    defaultValueSplittingType,
    setDefaultValueSplittingType,
    defaultValueUnity,
    setDefaultValueUnity,
    defaultValueDuration,
    setDefaultValueDuration,
    defaultValueCoeffIncrease,
    setDefaultValueCoeffIncrease,
}: any) => {
    const {t} = useTranslation();

    const splittingTypeOptions = [
        {text: t("enums.A"), value: "A"},
        {text: t("enums.S"), value: "S"},
        {text: t("enums.T"), value: "T"},
        {text: t("enums.M"), value: "M"},
        {text: t("enums.X"), value: "X"},
    ];

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            name={"splittingType"}
                            defaultValue={defaultValueSplittingType}
                            rules={{required: true}}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Select
                                    required
                                    fluid
                                    name={name}
                                    label={t("Splitting type")}
                                    placeholder={t("Splitting type")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueSplittingType(value);
                                        onChange(value);
                                        switch (value) {
                                            case "A":
                                                setDefaultValueSplittingCode("A");
                                                setDefaultValueSplittingLabel("Annuel");
                                                setValue("code", "A");
                                                setValue("label", "Annuel");
                                                break;
                                            case "S":
                                                setDefaultValueSplittingCode("S");
                                                setDefaultValueSplittingLabel("Semestriel");
                                                setValue("code", "S");
                                                setValue("label", "Semestriel");
                                                break;
                                            case "T":
                                                setDefaultValueSplittingCode("T");
                                                setDefaultValueSplittingLabel("Trimestriel");
                                                setValue("code", "T");
                                                setValue("label", "Trimestriel");
                                                break;
                                            case "M":
                                                setDefaultValueSplittingCode("M");
                                                setDefaultValueSplittingLabel("Mensuel");
                                                setValue("code", "M");
                                                setValue("label", "Mensuel");
                                        }
                                    }}
                                    value={value}
                                    options={splittingTypeOptions}
                                    error={getErrorMessage(t, errors, "splittingType")}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueSplittingCode}
                            rules={{required: true, maxLength: 5, pattern: codeValidation()}}
                            name={"code"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueSplittingCode(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueSplittingCode}
                                    maxLength={5}
                                    error={getErrorMessage(t, errors, "code", undefined, 5)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueSplittingLabel}
                            rules={{required: true, maxLength: 30}}
                            name={"label"}
                            render={({field: {name, value, onBlur, onChange}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    maxLength={"30"}
                                    placeholder={t("Label")}
                                    label={t("Label")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueSplittingLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueSplittingLabel}
                                    error={getErrorMessage(t, errors, "label", undefined, 30)}
                                />
                            )}
                        />

                        {defaultValueSplittingType === "X" && (
                            <React.Fragment>
                                <Controller
                                    control={control}
                                    name={"unit"}
                                    rules={{required: true}}
                                    defaultValue={defaultValueUnity}
                                    render={({field: {onChange, value, name}}) => (
                                        <Form.Select
                                            fluid
                                            required={true}
                                            label={t("Unity")}
                                            name={name}
                                            placeholder={t("Unity")}
                                            onChange={(_, {value}) => {
                                                setDefaultValueUnity(value);
                                                onChange(value);
                                            }}
                                            value={value}
                                            options={[
                                                {text: t("enums.unit.A"), value: "A"},
                                                {text: t("enums.unit.M"), value: "M"},
                                                {text: t("enums.unit.J"), value: "J"},
                                            ]}
                                            error={getErrorMessage(t, errors, "unit")}
                                        />
                                    )}
                                />

                                <Controller
                                    name="duration"
                                    control={control}
                                    defaultValue={defaultValueDuration}
                                    rules={{
                                        required: true,
                                        min: {value: 0, message: t("The value must be greater than 0")},
                                        max: {value: 999, message: t("The value must be less than 999")},
                                    }}
                                    render={({field: {name, value, onBlur, onChange}}) => (
                                        <Form.Input
                                            type="number"
                                            required={true}
                                            name={name}
                                            label={t("Duration")}
                                            fluid
                                            min={0}
                                            max={999}
                                            placeholder={t("Duration")}
                                            onChange={(_, {value}) => {
                                                setDefaultValueDuration(value);
                                                onChange(Number.parseInt(value) || "");
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            error={getErrorMessage(t, errors, "duration")}
                                        />
                                    )}
                                />
                            </React.Fragment>
                        )}

                        <Controller
                            name="increaseCoef"
                            control={control}
                            defaultValue={defaultValueCoeffIncrease}
                            rules={{required: true, min: {value: 0, message: t("The value must be greater than 0")}}}
                            render={({field: {name, value, onBlur, onChange}}) => (
                                <Form.Input
                                    required
                                    type={"number"}
                                    name={name}
                                    fluid
                                    min={0}
                                    step={".01"}
                                    placeholder={t("Coefficient increase")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCoeffIncrease(value);
                                        onChange(Number.parseFloat(value) || "");
                                    }}
                                    onBlur={onBlur}
                                    label={t("Coefficient increase")}
                                    value={value}
                                    error={getErrorMessage(t, errors, "increaseCoef")}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormSplitting;
