import { Control, Controller, DeepMap, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid, GridColumn } from "semantic-ui-react";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import { codeValidation } from "../../../Global/Form/Validation";

type FormDuplicateVariablePropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    defaultValueName: string;
    setDefaultValueName: React.Dispatch<React.SetStateAction<string>>;
    defaultValueDescription: string;
    setDefaultValueDescription: React.Dispatch<React.SetStateAction<string>>;
};

const FormDuplicateVariable = ({
    control,
    errors,
    defaultValueName,
    setDefaultValueName,
    defaultValueDescription,
    setDefaultValueDescription,
}: FormDuplicateVariablePropsType) => {
    const {t} = useTranslation();

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <GridColumn>
                        <Controller
                            control={control}
                            rules={{required: true, maxLength: 50, pattern: codeValidation()}}
                            defaultValue={defaultValueName}
                            name={"duplicated_variable_name"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Name")}
                                    placeholder={t("Name")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueName(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueName}
                                    maxLength="50"
                                    error={getErrorMessage(t, errors, "duplicated_variable_name", undefined, 50)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: false, maxLength: 255}}
                            defaultValue={defaultValueDescription}
                            name={"duplicated_variable_description"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    type={"text"}
                                    name={name}
                                    label={t("Description")}
                                    placeholder={t("Description")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueDescription(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueDescription}
                                    maxLength="255"
                                    error={getErrorMessage(
                                        t,
                                        errors,
                                        "duplicated_variable_description",
                                        undefined,
                                        255
                                    )}
                                />
                            )}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormDuplicateVariable;
