import React from "react";
import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";

type FormVersionPropsType = {
    control: Control<{label: string; effectiveDate: string}>;
    errors: DeepMap<any, FieldErrors>;

    defaultValueVersionLabel: string | undefined;
    setDefaultValueVersionLabel:
        | React.Dispatch<React.SetStateAction<string | undefined>>
        | React.Dispatch<React.SetStateAction<string>>;
    defaultValueEffectiveDate: string | undefined;
    setDefaultValueEffectiveDate:
        | React.Dispatch<React.SetStateAction<string | undefined>>
        | React.Dispatch<React.SetStateAction<string>>;
};

const FormVersion = ({
    control,
    errors,
    defaultValueVersionLabel,
    setDefaultValueVersionLabel,
    defaultValueEffectiveDate,
    setDefaultValueEffectiveDate,
}: FormVersionPropsType) => {
    const {t} = useTranslation();

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueVersionLabel}
                            rules={{required: true, maxLength: 45}}
                            name={"label"}
                            render={({field: {name, value, onChange}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    required
                                    onChange={(_, {value}) => {
                                        setDefaultValueVersionLabel(value);
                                        onChange(value);
                                    }}
                                    value={value}
                                    maxLength="45"
                                    error={getErrorMessage(t, errors, name, undefined, 45)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueEffectiveDate}
                            rules={{required: true}}
                            name={"effectiveDate"}
                            render={({field: {value, name, onChange}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type={"date"}
                                    label={t("Effective date")}
                                    required
                                    placeholder={t("Effective date")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueEffectiveDate(value);
                                        onChange(value);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormVersion;
