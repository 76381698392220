import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addVariable,
    addVariableCondition,
    deleteVariable,
    deleteVariableCondition,
    duplicateVariable,
    getSystemVariables,
    getVariable,
    getVariables,
    updateVariable,
    updateVariableCondition,
} from "./AxiosRequests";
import {SystemVariableList, Variable, VariableCondition, VariableType} from "./Types";

/* ------ VARIABLES ------ */
export const useGetVariablesList = (
    versionId: string,
    type: VariableType,
    enabled: boolean = true
): UseQueryResult<Array<Variable>, Error> => {
    return useQuery({
        queryKey: ["getVariables", {versionId, type}],
        queryFn: () => getVariables(versionId, type),
        enabled,
    });
};

export const useGetVariable = (versionId: string, variableId: string): UseQueryResult<Variable, Error> => {
    return useQuery({
        queryKey: ["getVariable", {versionId, variableId}],
        queryFn: () => getVariable(versionId, variableId),
    });
};

export const useGetSystemVariableList = (versionId: string): UseQueryResult<SystemVariableList, Error> => {
    return useQuery({
        queryKey: ["getSystemVariables", {versionId}],
        queryFn: () => getSystemVariables(versionId),
    });
};

export const useAddVariable = (
    productId: string,
    versionId: string
): UseMutationResult<Variable, CustomError, Variable, Error> => {
    const navigate = useNavigate();
    return useMutation<Variable, CustomError, Variable, Error>({
        mutationFn: (variable: Variable) => addVariable(versionId, variable),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getVariables", {versionId, type: result.variableType}]});
            navigate(`/products/${productId}/versions/${versionId}/variables/${result.id}`);
        },
    });
};

export const useUpdateVariable = (
    versionId: string,
    variableId: string
): UseMutationResult<any, CustomError, any, Error> => {
    return useMutation<any, CustomError, any, Error>({
        mutationFn: (variable: Variable) => updateVariable(versionId, variableId, variable),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getVariables", {versionId, type: result.variableType}]});
            await queryClient.invalidateQueries({queryKey: ["getVariable", {versionId, variableId}]});
        },
    });
};

export const useDeleteVariable = (
    productId: string,
    versionId: string,
    variableId: number,
    type: VariableType.R | VariableType.V
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteVariable(versionId, variableId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getVariables", {versionId, type}]});
            queryClient.removeQueries({queryKey: ["getVariable", {versionId, variableId}]});
            navigate(`/products/${productId}/versions/${versionId}`, {
                state: {activeTabIndex: type === VariableType.R ? 5 : 4},
            });
        },
    });
};

export const useDuplicateVariable = (
    productId: string,
    versionId: string,
    variableId: number
): UseMutationResult<Variable, CustomError, {name: string; description: string}, Error> => {
    const navigate = useNavigate();
    return useMutation<Variable, CustomError, {name: string; description: string}, Error>({
        mutationFn: (data: {name: string; description: string}) =>
            duplicateVariable(versionId, variableId, data.name, data.description),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getVariables", {versionId, type: result.variableType}]});
            navigate(`/products/${productId}/versions/${versionId}/variables/${result.id}`);
        },
    });
};

/* ------ VARIABLES CONDITIONS ------ */
export const useAddVariableCondition = (
    versionId: string,
    variableId: string,
    type: VariableType.R | VariableType.V
): UseMutationResult<VariableCondition, CustomError, VariableCondition, Error> => {
    return useMutation<VariableCondition, CustomError, VariableCondition, Error>({
        mutationFn: (variableCondition: VariableCondition) => addVariableCondition(variableId, variableCondition),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getVariables", {versionId, type}]});
            await queryClient.invalidateQueries({queryKey: ["getVariable", {versionId, variableId}]});
        },
    });
};

export const useUpdateVariableCondition = (
    versionId: string,
    variableId: string,
    variableConditionId: string,
    type: VariableType.R | VariableType.V
): UseMutationResult<VariableCondition, CustomError, VariableCondition, Error> => {
    return useMutation<VariableCondition, CustomError, VariableCondition, Error>({
        mutationFn: (variableCondition: VariableCondition) =>
            updateVariableCondition(variableId, variableConditionId, variableCondition),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getVariables", {versionId, type}]});
            await queryClient.invalidateQueries({queryKey: ["getVariable", {versionId, variableId}]});
        },
    });
};

export const useDeleteVariableCondition = (
    versionId: string,
    variableId: string,
    variableConditionId: string,
    type: VariableType.R | VariableType.V
): UseMutationResult<void, CustomError, any, Error> => {
    return useMutation<void, CustomError, any, Error>({
        mutationFn: () => deleteVariableCondition(variableId, variableConditionId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getVariables", {versionId, type}]});
            await queryClient.invalidateQueries({queryKey: ["getVariable", {versionId, variableId}]});
        },
    });
};

