import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Divider, Form, Grid, Header} from "semantic-ui-react";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {useUpdateSplittingMutation} from "../../../../Services/Splitting/Queries";
import {Splitting} from "../../../../Services/Splitting/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {codeValidation} from "../../../Global/Form/Validation";

type SplittingDetailsEditProps = {
    productId: string;
    splittingId: string;
    splitting: Splitting;
    setEdit: Function;
};

const SplittingDetailsEdit: React.FC<SplittingDetailsEditProps> = ({productId, splittingId, splitting, setEdit}) => {
    const {t} = useTranslation();
    const {
        control,
        setValue,
        resetField,
        handleSubmit,
        formState: {errors},
        getValues,
    } = useForm<Splitting>({mode: "onBlur", shouldUnregister: true});
    const {mutate, isError, isPending, isSuccess, reset, error} = useUpdateSplittingMutation(productId, splittingId);
    const [displayUnityAndDuration, setDisplayUnityAndDuration] = useState("none");
    const [mandatoryUnityAndDuration, setMandatoryUnityAndDuration] = useState(false);

    useEffect(() => {
        determineUnityAndDuration(getValues("splittingType"));
    });

    const updateSplitting = (formData: Splitting) => {
        mutate({
            ...formData,
            unit: formData.splittingType === "X" ? formData.unit : null,
            duration: formData.splittingType === "X" ? formData.duration : null,
        });
    };

    const determineUnityAndDuration = (splittingType: string) => {
        if (isNotXSplittingType(splittingType)) {
            setDisplayUnityAndDuration("none");
            setMandatoryUnityAndDuration(false);
            resetField("unit");
            resetField("duration");
        } else {
            setDisplayUnityAndDuration("block");
            setMandatoryUnityAndDuration(true);
            setValue("unit", splitting.unit);
            setValue("duration", splitting.duration);
        }
    };

    const isNotXSplittingType = (splittingType: string) => {
        return ["A", "S", "T", "M"].find((value) => value === splittingType) !== undefined;
    };

    return (
        <Form>
            <Grid columns="equal" verticalAlign="middle">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Code")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            name="code"
                            control={control}
                            rules={{required: true, maxLength: 5, pattern: codeValidation()}}
                            defaultValue={splitting.code}
                            render={({field: {name, value, onBlur, onChange, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    required
                                    name={name}
                                    fluid
                                    maxLength={"5"}
                                    placeholder={t("Code")}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                    error={getErrorMessage(t, errors, "code", undefined, 5)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <label htmlFor={"label"}>
                            <strong>{t("Label")} * :</strong>
                        </label>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            name="label"
                            control={control}
                            defaultValue={splitting.label}
                            rules={{required: true, maxLength: 30}}
                            render={({field: {name, value, onBlur, onChange, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    required
                                    name={name}
                                    fluid
                                    maxLength={"30"}
                                    placeholder={t("Label")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, "label", undefined, 30)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Splitting type")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"splittingType"}
                            defaultValue={splitting.splittingType}
                            rules={{required: true}}
                            render={({field: {onChange, value, name, ref}}) => (
                                <Form.Select
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    placeholder={t("Splitting type")}
                                    onChange={(e, {value}) => {
                                        determineUnityAndDuration(String(value));
                                        onChange(value);
                                    }}
                                    value={value}
                                    options={[
                                        {text: t("enums.A"), value: "A"},
                                        {text: t("enums.S"), value: "S"},
                                        {text: t("enums.T"), value: "T"},
                                        {text: t("enums.M"), value: "M"},
                                        {text: t("enums.X"), value: "X"},
                                    ]}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div
                style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    marginRight: "0",
                    marginLeft: "0",
                    display: displayUnityAndDuration,
                }}
            >
                <Header>{t("Duration")}</Header>
                <Grid columns="equal" verticalAlign="middle">
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <label>
                                <strong>{t("Unity")} * : </strong>
                            </label>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Controller
                                control={control}
                                name={"unit"}
                                defaultValue={splitting.unit}
                                rules={{required: mandatoryUnityAndDuration}}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Form.Select
                                        inpuref={ref}
                                        required={mandatoryUnityAndDuration}
                                        fluid
                                        name={name}
                                        placeholder={t("Unity")}
                                        onChange={(e, {value}) => onChange(value)}
                                        value={value}
                                        options={[
                                            {text: t("enums.unit.A"), value: "A"},
                                            {text: t("enums.unit.M"), value: "M"},
                                            {text: t("enums.unit.J"), value: "J"},
                                        ]}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <label>
                                <strong>{t("Duration")} * : </strong>
                            </label>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Controller
                                name="duration"
                                control={control}
                                defaultValue={splitting.duration}
                                rules={{
                                    required: mandatoryUnityAndDuration,
                                    min: {value: 0, message: t("The value must be greater than 0")},
                                    max: {value: 999, message: t("The value must be less than 999")},
                                }}
                                render={({field: {name, value, onBlur, onChange, ref}}) => (
                                    <Form.Input
                                        inputref={ref}
                                        required={mandatoryUnityAndDuration}
                                        type="number"
                                        name={name}
                                        fluid
                                        min={0}
                                        max={999}
                                        placeholder={t("Duration")}
                                        onChange={(e, {value}) => onChange(Number.parseInt(value) || "")}
                                        onBlur={onBlur}
                                        value={value}
                                        error={getErrorMessage(t, errors, "duration")}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>

            <Grid columns="equal" verticalAlign="middle">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Coefficient increase")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            name="increaseCoef"
                            control={control}
                            defaultValue={splitting.increaseCoef}
                            rules={{required: true, min: {value: 0, message: t("The value must be greater than 0")}}}
                            render={({field: {name, value, onBlur, onChange}}) => (
                                <Form.Input
                                    type={"number"}
                                    name={name}
                                    fluid
                                    min={0}
                                    step={".01"}
                                    placeholder={t("Coefficient increase")}
                                    onChange={(e, {value}) => onChange(Number.parseFloat(value) || "")}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, "increaseCoef")}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Default splitting")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            defaultValue={splitting.defaultSplitting}
                            name={"defaultSplitting"}
                            render={({field: {value, name, onChange, ref}}) => (
                                <Form.Radio
                                    toggle
                                    aria-label={name}
                                    onChange={(e, {checked}) => onChange(checked)}
                                    name={name}
                                    checked={value}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className={"bottom-button-card"} style={{bottom: 0}}>
                <Divider />
                <Button secondary name="cancel" onClick={() => setEdit(false)}>
                    {t("Cancel")}
                </Button>
                <ModalUpdate
                    onSuccess={() => setEdit(false)}
                    isModalDisabled={Object.keys(errors).length !== 0}
                    onValidate={handleSubmit(updateSplitting)}
                    resetMutation={reset}
                    isError={isError}
                    isPending={isPending}
                    isSuccess={isSuccess}
                    error={error}
                    customContent={t("You are about to change splitting information")}
                />
            </div>
        </Form>
    );
};

export default SplittingDetailsEdit;
