import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {ApplicationMode, OfferType, PromotionalCampaign} from "../../../../Services/PromotionalCampagins/Types";
import {Button, Divider, Form, Grid} from "semantic-ui-react";
import {useUpdatePromotionalCampaignMutation} from "../../../../Services/PromotionalCampagins/Queries";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import PromotionalCampaignFreeMonthDetailsEdit from "./FreeMonth/PromotionalCampaignFreeMonthDetailsEdit";

type PromotionalCampaignDetailsEditProps = {
    productId: string;
    promotionalCampaignId: string;
    promotionalCampaign: PromotionalCampaign;
    setEdit: Function;
};

const PromotionalCampaignDetailsEdit: React.FC<PromotionalCampaignDetailsEditProps> = ({
    productId,
    promotionalCampaignId,
    promotionalCampaign,
    setEdit,
}) => {
    const {t} = useTranslation();

    // Update
    const updatePromotionalCampaignMutation = useUpdatePromotionalCampaignMutation(productId, promotionalCampaignId);
    const {control, setValue, handleSubmit, formState, trigger} = useForm<PromotionalCampaign>({mode: "onChange"});
    const updatePromotionalCampaign = (formData: PromotionalCampaign) => {
        updatePromotionalCampaignMutation.mutate({
            ...formData,
            label: formData.label && formData.label.length > 0 ? formData.label : null,
            description: formData.description && formData.description?.length > 0 ? formData.description : null,
        });
    };

    // Options Type d'offre
    const offerTypeOptions = Object.values(OfferType).map((offerType: OfferType) => {
        return {
            key: offerType.toString(),
            text: t("enums.OfferType." + offerType),
            value: offerType,
        };
    });

    // Options Mode d'application
    const applicationModeOptions = Object.values(ApplicationMode).map((applicationMode: ApplicationMode) => {
        return {
            key: applicationMode.toString(),
            text: t("enums.ApplicationMode." + applicationMode),
            value: applicationMode,
        };
    });

    return (
        <Form>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Code")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"code"}
                            defaultValue={promotionalCampaign.code}
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    required
                                    fluid
                                    name={name}
                                    type="text"
                                    placeholder={t("Code")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Label")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"label"}
                            defaultValue={promotionalCampaign.label || ""}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type="text"
                                    placeholder={t("Label")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Description")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"description"}
                            defaultValue={promotionalCampaign.description || ""}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type="text"
                                    placeholder={t("Description")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Start date")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"startDate"}
                            defaultValue={promotionalCampaign.startDate}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur, ref}}) => (
                                <Form.Input
                                    required
                                    fluid
                                    name={name}
                                    type="datetime-local"
                                    placeholder={t("Start date")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("End date")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"endDate"}
                            defaultValue={promotionalCampaign.endDate}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur, ref}}) => (
                                <Form.Input
                                    required
                                    fluid
                                    name={name}
                                    type="datetime-local"
                                    placeholder={t("End date")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Offer type")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"offerType"}
                            defaultValue={promotionalCampaign.offerType}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur, ref}}) => (
                                <Form.Select
                                    inputref={ref}
                                    name={name}
                                    disabled
                                    fluid
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                    options={offerTypeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Application mode")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"applicationMode"}
                            defaultValue={promotionalCampaign.applicationMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    required
                                    name={name}
                                    fluid
                                    placeholder={t("Application mode")}
                                    onChange={(e, {value}) => onChange(value)}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                    options={applicationModeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                {promotionalCampaign.offerType === OfferType.MOIS_GRATUIT && (
                    <PromotionalCampaignFreeMonthDetailsEdit
                        productId={productId}
                        promotionalCampaignId={promotionalCampaignId}
                        promotionalCampaign={promotionalCampaign}
                        control={control}
                        errors={formState.errors}
                    />
                )}

                <Grid.Column>
                    <Grid.Row>
                        <Divider />
                    </Grid.Row>
                    <Grid.Row>
                        <div className={"bottom-button-card"} style={{bottom: 0}}>
                            <Button secondary name="cancel-edit" onClick={() => setEdit(false)}>
                                {t("Cancel")}
                            </Button>

                            <ModalUpdate
                                isModalDisabled={Object.keys(formState.errors).length !== 0}
                                isPending={updatePromotionalCampaignMutation.isPending}
                                isSuccess={updatePromotionalCampaignMutation.isSuccess}
                                isError={updatePromotionalCampaignMutation.isError}
                                resetMutation={updatePromotionalCampaignMutation.reset}
                                error={updatePromotionalCampaignMutation.error}
                                onValidate={handleSubmit(updatePromotionalCampaign)}
                                objectToUpdate="Promotional campaign"
                                objectType="female"
                            />
                        </div>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default PromotionalCampaignDetailsEdit;
