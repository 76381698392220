import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Segment } from "semantic-ui-react";
import { useGetProductRetrocessionQuery } from "../../../../Services/ProductRetrocession/Queries";
import ProductRetrocessionDetailsEdit from "./ProductRetrocessionDetailsEdit";
import ProductRetrocessionDetailsRead from "./ProductRetrocessionDetailsRead";

const ProductRetrocessionDetails: React.FC<{productId: string; retrocessionId: string}> = ({
    productId,
    retrocessionId,
}) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const {data: retrocession} = useGetProductRetrocessionQuery(productId, retrocessionId);

    useEffect(() => {
        setEditMode(false);
    }, [retrocessionId, retrocession]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {retrocession !== undefined && (
                        <Segment className="card-container">
                            <Header as="h2" dividing>
                                {t("Business provider") + " " + String(retrocession.businessProviderRank)}
                            </Header>
                            {editMode ? (
                                <ProductRetrocessionDetailsEdit
                                    productId={productId}
                                    retrocessionId={retrocessionId}
                                    productRetrocession={retrocession}
                                    setEdit={setEditMode}
                                />
                            ) : (
                                <ProductRetrocessionDetailsRead
                                    productId={productId}
                                    retrocession={retrocession}
                                    setEdit={setEditMode}
                                />
                            )}
                        </Segment>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ProductRetrocessionDetails;
