import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import {RegistryEntryType} from "../../../../Services/Registry/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";

export const FormRegistry = ({
    control,
    errors,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
    defaultValueType,
    setDefaultValueType,
}: any) => {
    const {t} = useTranslation();

    const typeOptions = Object.values(RegistryEntryType).map((registryEntryType: RegistryEntryType, index: number) => {
        return {
            key: index,
            text: t(`enums.RegistryEntryType.${registryEntryType}`),
            value: registryEntryType,
        };
    });

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            name="code"
                            defaultValue={defaultValueCode}
                            rules={{required: true, maxLength: 15}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type="text"
                                    name={name}
                                    placeholder={t("Code")}
                                    label={t("Code")}
                                    maxLength={15}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCode(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueCode}
                                    error={getErrorMessage(t, errors, name, undefined, 15)}
                                />
                            )}
                        />

                        <Controller
                            name="label"
                            defaultValue={defaultValueLabel}
                            rules={{required: true, maxLength: 90}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    required
                                    fluid
                                    type="text"
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    maxLength={90}
                                    name={name}
                                    onChange={(_, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueLabel}
                                    error={getErrorMessage(t, errors, name, undefined, 90)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueType}
                            name={"selectedType"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Select
                                    label={t("Choose an entry type")}
                                    name={name}
                                    required
                                    onBlur={onBlur}
                                    placeholder={t("Type")}
                                    onChange={(_, {value}) => {
                                        onChange(value);
                                        setDefaultValueType(value);
                                    }}
                                    error={getErrorMessage(t, errors, name)}
                                    options={typeOptions}
                                    value={defaultValueType}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};
