import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid } from "semantic-ui-react";
import getErrorMessage from "../../Global/Form/ErrorMessage";

const FormRange = ({control, errors, defaultValueLabel, setDefaultValueLabel}: any) => {
    const {t} = useTranslation();

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueLabel}
                            rules={{required: true, maxLength: 50}}
                            name={"label"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type="text"
                                    maxLength="50"
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueLabel}
                                    error={getErrorMessage(t, errors, "label", undefined, 50)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormRange;
