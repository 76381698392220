import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Grid, Message, Tab } from "semantic-ui-react";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import { useAddProductRegistryTaxJoinMutation } from "../../../../Services/ProductBehaviour/Queries";
import { ProductBehaviorWithRegistryDTO } from "../../../../Services/ProductBehaviour/Types";
import BehaviourTaxesAccordion from "./BehaviourTaxesAccordion";

type BehaviourTaxesPropsType = {
    behaviour: ProductBehaviorWithRegistryDTO;
};

const BehaviourTaxes = ({behaviour}: BehaviourTaxesPropsType) => {
    const {t} = useTranslation();
    const [activeConditionIndex, setActiveConditionIndex] = useState<number>(0);

    const addProductRegistryTaxJoinMutation = useAddProductRegistryTaxJoinMutation(
        String(behaviour.productRegistryJoin.product),
        String(behaviour.productRegistryJoin.id),
        String(behaviour.productRegistryJoin.registry)
    );

    const onAddConditionValidation = () => {
        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            taxCategoryCode: "",
        };
        addProductRegistryTaxJoinMutation.mutate(condition);
    };

    return (
        <Tab.Pane>
            <Accordion styled fluid>
                {behaviour.productRegistryTaxJoins
                    .sort((a, b) => a.conditionOrder - b.conditionOrder)
                    .map((condition, index) => (
                        <BehaviourTaxesAccordion
                            key={index}
                            behaviour={behaviour}
                            condition={condition}
                            numberOfConditions={behaviour.productRegistryTaxJoins.length}
                            index={index}
                            activeIndex={activeConditionIndex}
                            setActiveIndex={setActiveConditionIndex}
                        />
                    ))}
            </Accordion>

            <Grid style={{marginTop: 0}}>
                <Grid.Row>
                    <Grid.Column textAlign="center">
                        <ModalAdd
                            isPending={addProductRegistryTaxJoinMutation.isPending}
                            isSuccess={addProductRegistryTaxJoinMutation.isSuccess}
                            isError={addProductRegistryTaxJoinMutation.isError}
                            resetMutation={addProductRegistryTaxJoinMutation.reset}
                            error={addProductRegistryTaxJoinMutation.error}
                            onValidate={() => onAddConditionValidation()}
                            objectToAdd="Condition"
                            objectToAddContext="female"
                            openModalButtonName="add-condition"
                            renderModalContent={() => (
                                <Message info>
                                    {t(
                                        "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                    )}
                                </Message>
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default BehaviourTaxes;
