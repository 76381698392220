import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Container, Grid, Segment, Tab} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetAllGridQuery, useGetGridQuery, useGetPricingGridViewQuery} from "../../../Services/PricingGrid/Queries";
import {BasicGridType} from "../../../Services/PricingGrid/Types";
import {useGetProductDetailsQuery} from "../../../Services/Product/Queries";
import {CustomError} from "../../../Services/RequestUtils";
import {useGetVersionQuery} from "../../../Services/Version/Queries";
import BreadcrumbPricingGrid from "./Fragments/BreadcrumbPricingGrid";
import ModalAddPricingGrid from "./Fragments/ModalAddPricingGrid";
import PricingGridStructure from "./Fragments/PricingGridStructure/PricingGridStructure";
import PricingGridView from "./Fragments/PricingGridView/PricingGridView";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

const PricingGridPage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    const pricingGridListQuery = useGetAllGridQuery(params.versionId);
    const {
        data: viewData,
        status: viewStatus,
        error: viewError,
    } = useGetPricingGridViewQuery(params.versionId, params.gridId);
    const {data: version} = useGetVersionQuery(params.id, params.versionId);
    const {data: pricingGrid, isSuccess, isLoading} = useGetGridQuery(params.versionId, params.gridId);

    const hasGridBeenAddedOrModified = (grid: BasicGridType) => {
        return (
            new Date(grid.modifiedDate).getTime() > new Date(grid.createdDate).getTime() ||
            (grid.createdOnVersionId === Number(params.versionId) &&
                new Date(grid.modifiedDate).getTime() === new Date(grid.createdDate).getTime())
        );
    };

    useEffect(() => {
        if (pricingGridListQuery.isSuccess && pricingGridListQuery.data) {
            setMenus([
                new SidebarMenu(
                    "",
                    pricingGridListQuery.data.map(
                        (grid) =>
                            new SidebarItem(
                                grid.label,
                                grid.label,
                                `/products/${params.id}/versions/${params.versionId}/pricing-grid/${grid.id}`,
                                hasGridBeenAddedOrModified(grid)
                            )
                    )
                ),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingGridListQuery.isSuccess, pricingGridListQuery.data]);

    const panes = [
        {
            menuItem: {key: "visualization", content: t("Visualization")},
            render: () => (
                <PricingGridView data={viewData} status={viewStatus} error={viewError as CustomError | null} />
            ),
        },
        {
            menuItem: {key: "structure", content: t("Structure")},
            render: () => <PricingGridStructure gridCode={pricingGrid?.code} />,
        },
    ];

    return (
        <Container fluid>
            <Sidebar
                title={t("pricing_grids")}
                menus={menus}
                renderAdditionnalButton={
                    version !== undefined && version.pipDate === null
                        ? () => (
                              <ModalAddPricingGrid
                                  alternateOpenButton={() => (
                                      <Button primary name="new_pricing_grid" className="menu-button">
                                          {t("New entity", {
                                              entity: t("Pricing grid").toLowerCase(),
                                              context: "female",
                                          })}
                                      </Button>
                                  )}
                              />
                          )
                        : undefined
                }
            />
            {isLoading && <CustomBreadcrumb sections={props.sections} />}
            {version !== undefined && pricingGrid !== undefined && isSuccess && (
                <BreadcrumbPricingGrid pricingGrid={pricingGrid} version={version} sections={props.sections} />
            )}
            <div className={"main-container"}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment className="card-container">
                                <Tab panes={panes} />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default PricingGridPage;
