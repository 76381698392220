import {Dispatch, SetStateAction} from "react";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";

export type FormUserGroupFieldsTypes = {
    name: string;
    nbDaysAuthorizedBeforePricingDate: number;
};

type FormUserGroupProps = {
    control: Control<FormUserGroupFieldsTypes>;
    errors: FieldErrors<FormUserGroupFieldsTypes>;
    defaultValueForm: FormUserGroupFieldsTypes;
    setDefaultValueForm: Dispatch<SetStateAction<FormUserGroupFieldsTypes>>;
};

const FormUserGroup = ({control, errors, defaultValueForm, setDefaultValueForm}: FormUserGroupProps) => {
    const {t} = useTranslation();

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueForm.name}
                            rules={{required: true}}
                            name={"name"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type="text"
                                    name={name}
                                    label={t("Name")}
                                    placeholder={t("Name")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueForm({
                                            ...defaultValueForm,
                                            name: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueForm.nbDaysAuthorizedBeforePricingDate}
                            name={"nbDaysAuthorizedBeforePricingDate"}
                            rules={{required: true, min: 0}}
                            render={({field: {value, name, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    name={name}
                                    label={t("nbDaysAuthorizedBeforePricingDate")}
                                    placeholder={t("nbDaysAuthorizedBeforePricingDate")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueForm({
                                            ...defaultValueForm,
                                            nbDaysAuthorizedBeforePricingDate: parseInt(value),
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    type="number"
                                    min="0"
                                    error={getErrorMessage(t, errors, name, undefined, undefined, undefined, 0)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormUserGroup;
