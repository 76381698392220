import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { AccordionContent, AccordionTitle, Button, Divider, Grid, GridColumn, Header, Icon } from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import { ConditionPart, OperandEnum } from "../../../../Services/Condition/Types";
import {
    useDeleteProductRegistryTaxJoinMutation,
    useUpdateProductRegistryTaxJoinMutation
} from "../../../../Services/ProductBehaviour/Queries";
import { ProductBehaviorWithRegistryDTO, ProductRegistryTaxJoin } from "../../../../Services/ProductBehaviour/Types";
import { useGetVersionsQuery } from "../../../../Services/Version/Queries";
import { conditionPartListToString } from "../../../../Utils/ConditionPartUtils";
import ConditionHandling from "../../Condition/ConditionHandling";
import ConditionView from "../../Condition/Fragments/ConditionView";
import BehaviourTaxesAssignement from "./BehaviourTaxesAssignement";

interface IParams extends Params {
    id: string;
}

type BehaviourTaxesAccordionPropsType = {
    behaviour: ProductBehaviorWithRegistryDTO;
    condition: ProductRegistryTaxJoin;
    index: number;
    activeIndex: number;
    setActiveIndex: Function;
    numberOfConditions: number;
};

const BehaviourTaxesAccordion = ({
    behaviour,
    condition,
    index,
    activeIndex,
    setActiveIndex,
    numberOfConditions,
}: BehaviourTaxesAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const conditionKindList = [
        {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
        {key: 2, text: "Question", value: OperandEnum.QUESTION, type: "female"},
        {key: 3, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 4, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
        {key: 5, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
    ];

    const updateProductRegistryTaxJoinMutation = useUpdateProductRegistryTaxJoinMutation(
        String(behaviour.productRegistryJoin.product),
        String(behaviour.productRegistryJoin.id),
        String(condition.id),
        String(behaviour.registry.id)
    );

    const deleteProductRegistryTaxJoinMutation = useDeleteProductRegistryTaxJoinMutation(
        String(behaviour.productRegistryJoin.product),
        String(behaviour.productRegistryJoin.id),
        String(behaviour.registry.id)
    );

    const versionList = useGetVersionsQuery(params.id);
    const [versionId, setVersionId] = useState<string>("");

    useEffect(() => {
        if (versionList.isSuccess) {
            const versionListSorted = versionList.data.sort(
                (version1, version2) =>
                    new Date(version2.effectiveDate).getTime() - new Date(version1.effectiveDate).getTime()
            );
            setVersionId(String(versionListSorted[0].id));
        }
    }, [versionList]);

    return (
        <div key={index}>
            <AccordionTitle
                name={"condition-" + (index + 1)}
                style={{display: "flex", alignItems: "center"}}
                active={activeIndex === index}
                index={index}
                onClick={() => {
                    if (activeIndex === index) setActiveIndex(-1);
                    else setActiveIndex(index);
                }}>
                <Icon name="dropdown" />
                <div style={{display: "flex", alignItems: "center"}}>
                    {t("Condition") + " " + (index + 1)}
                    {!(activeIndex === index) && numberOfConditions !== 1 && (
                        <>
                            {" "}
                            -
                            <p style={{marginLeft: "4px", fontWeight: "lighter", fontSize: "12px", color: "grey"}}>
                                {condition.ifCondition
                                    ? conditionPartListToString(condition.conditionPartList)
                                    : t("Else")}
                            </p>
                        </>
                    )}
                </div>
            </AccordionTitle>
            <AccordionContent active={activeIndex === index}>
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>

                {editCondition ? (
                    <ConditionHandling
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: ProductRegistryTaxJoin = {
                                ...condition,
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate,
                            };
                            updateProductRegistryTaxJoinMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateProductRegistryTaxJoinMutation}
                        versionId={versionId}
                    />
                ) : (
                    <>
                        <Grid>
                            {condition.conditionPartList.length === 0 ? (
                                <GridColumn width={2} textAlign="center" verticalAlign="middle">
                                    {t("Default value")}
                                </GridColumn>
                            ) : index !== condition.conditionPartList.length - 1 ? (
                                <>
                                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                        {t("If")} :
                                    </GridColumn>
                                    <GridColumn width={14} textAlign="center" className="condition">
                                        <ConditionView conditionPartList={condition.conditionPartList} />
                                    </GridColumn>
                                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                        <Button
                                            name={"editCondition" + Number(index + 1)}
                                            color="grey"
                                            icon="edit"
                                            compact
                                            basic
                                            onClick={() => {
                                                setEditCondition(true);
                                            }}
                                        />
                                    </GridColumn>
                                </>
                            ) : (
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("Else")}
                                </GridColumn>
                            )}
                        </Grid>
                        <Divider horizontal>
                            <Header as="h4">{t("Assignment")}</Header>
                        </Divider>
                        <BehaviourTaxesAssignement
                            condition={condition}
                            onValidateMutate={(categoryTaxe: string) => {
                                updateProductRegistryTaxJoinMutation.mutate({
                                    ...condition,
                                    taxCategoryCode: categoryTaxe,
                                });
                            }}
                            updateMutaion={updateProductRegistryTaxJoinMutation}
                        />

                        {index !== behaviour.productRegistryTaxJoins.length - 1 && (
                            <>
                                <Divider />
                                <Grid>
                                    <GridColumn floated={"right"} textAlign={"right"} style={{paddingRight: "15px"}}>
                                        <ModalDelete
                                            isSuccess={deleteProductRegistryTaxJoinMutation.isSuccess}
                                            isPending={deleteProductRegistryTaxJoinMutation.isPending}
                                            isError={deleteProductRegistryTaxJoinMutation.isError}
                                            error={deleteProductRegistryTaxJoinMutation.error}
                                            resetMutation={deleteProductRegistryTaxJoinMutation.reset}
                                            objectToDelete="Condition"
                                            objectType="female"
                                            openModalButtonName={"delete-condition-" + Number(index + 1)}
                                            onValidate={() => deleteProductRegistryTaxJoinMutation.mutate(condition.id)}
                                            onSuccess={() => setActiveIndex(-1)}
                                        />
                                    </GridColumn>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default BehaviourTaxesAccordion;
