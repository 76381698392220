import { useTranslation } from "react-i18next";
import { Button, Divider, Grid } from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import { useDeleteProductRetrocessionMutation } from "../../../../Services/ProductRetrocession/Queries";
import { ProductRetrocession, RetrocessionMode } from "../../../../Services/ProductRetrocession/Types";

type PropsType = {
    productId: string;
    retrocession: ProductRetrocession;
    setEdit: Function;
};

const ProductRetrocessionDetailsRead = ({productId, retrocession, setEdit}: PropsType) => {
    const {t} = useTranslation();

    const deleteProductRetrocessionMutation = useDeleteProductRetrocessionMutation(productId, retrocession.id);
    const handleDelete = () => {
        deleteProductRetrocessionMutation.mutate(retrocession.id);
    };

    return (
        <>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Business provider rank")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{retrocession.businessProviderRank}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Retrocession mode")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{t(`enums.RetrocessionMode.${retrocession.retrocessionMode}`)}</Grid.Column>
                </Grid.Row>
                {retrocession.retrocessionMode === RetrocessionMode.PRODUIT && (
                    <>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Base amount")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                {t(`enums.RetrocessionType.${retrocession.retrocessionType}`)}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Variable name base rate")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>{retrocession.variableNameBaseRate}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Variable name coverages base")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>{retrocession.variableNameCoveragesBase}</Grid.Column>
                        </Grid.Row>
                    </>
                )}
            </Grid>
            <Divider />
            <div className="bottom-button-card">
                <Button primary name="edit" onClick={() => setEdit(true)}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deleteProductRetrocession"
                    isSuccess={deleteProductRetrocessionMutation.isSuccess}
                    isPending={deleteProductRetrocessionMutation.isPending}
                    isError={deleteProductRetrocessionMutation.isError}
                    error={deleteProductRetrocessionMutation.error}
                    resetMutation={deleteProductRetrocessionMutation.reset}
                    onValidate={handleDelete}
                    objectToDelete="Product retrocession"
                    objectType="female"
                />
            </div>
        </>
    );
};

export default ProductRetrocessionDetailsRead;
