import {AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig} from "axios";
import {Cookies} from "react-cookie";

export type CustomError = {
    status: number;
    statusText: string | undefined;
    message: string;
    details: string[];
};

type ErrorMessageDTO = {
    field: string;
    message: string;
    details: string[];
};

export const interceptAxiosRequestAndInjectTokenFromCookie = (
    config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
    const cookies = new Cookies();
    const token = cookies.get("token");

    if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    if (process.env.NODE_ENV === "development") {
        config.headers["b27b31e5-4d97-4d01-b1ae-fafd9a9477ac"] = "e72dbd57-d834-44a1-b401-47533e26a683";
    }

    return config;
};

export const buildCustomError = (error: AxiosError<ErrorMessageDTO | ErrorMessageDTO[]>): CustomError | undefined => {
    if (error.response !== undefined) {
        if (Array.isArray(error.response.data)) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
                message: "error_in_entity_data_validation",
                details: error.response.data.map((error) => "request_errors." + error.message),
            };
        } else {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
                message: error.response.data.message,
                details: error.response.data.details || [],
            };
        }
    }
};

export const displayResponseError = (error: CustomError): string => {
    if (error.message) {
        if (error.message.length > 100 || error.message.trim() === "" || !error.message.match(/_/)) {
            switch (error.status) {
                case 403:
                    return error.message;
                case 404:
                    return "Client side error occurred please contact support";
                case 500:
                    return "Server side error occurred please try later";
            }
        } else {
            return `request_errors.${error.message}`;
        }
    }

    return "An error occurred please contact support";
};

