import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Container, Dropdown, Grid, Menu, Segment} from "semantic-ui-react";
import {AuthContext} from "../../../Components/AuthProvider/AuthProvider";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SimpleList, {DataTypeColumnEnum} from "../../../Components/SimpleList/SimpleList";
import useTransverseFeatureSidebarMenus from "../../../Hooks/useTransverseFeatureSidebarMenus";
import {useAddRegistryMutation, useGetRegistriesByTypeQuery} from "../../../Services/Registry/Queries";
import {RegistryEntryDTO, RegistryEntryType} from "../../../Services/Registry/Types";
import {FormRegistry} from "./Fragments/FormRegistry";

const registryTableColumns = [
    {
        text: "Code",
        dataField: "code",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Label",
        dataField: "label",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Type",
        dataField: "type",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "",
        dataField: "/transversal_settings/registry/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const RegistryListPage: React.FC = () => {
    const {t} = useTranslation();
    const [registryToDisplay, setRegistryToDisplay] = useState<RegistryEntryDTO[]>([]);

    const typeOtions = [
        {key: 0, text: t("All types"), value: undefined},
        ...Object.values(RegistryEntryType).map((registryEntryType: RegistryEntryType, index: number) => ({
            key: index + 1,
            text: t(`enums.RegistryEntryType.${registryEntryType}`),
            value: registryEntryType,
        })),
    ];

    const registryListQuery = useGetRegistriesByTypeQuery(null);

    const handleFilter = (type: any) => {
        if (registryListQuery != null && registryListQuery.data != null) {
            if (type === undefined) {
                setRegistryToDisplay(registryListQuery.data);
            } else {
                setRegistryToDisplay(
                    registryListQuery.data.filter((registryEntryDTO) => registryEntryDTO.type === type)
                );
            }
        }
    };

    useEffect(() => {
        if (registryListQuery.isSuccess && registryListQuery.data) {
            setRegistryToDisplay(registryListQuery.data);
        }
    }, [registryListQuery.isSuccess, registryListQuery.data]);

    const addRegistryForm = useForm<any>();
    const addRegistryMutation = useAddRegistryMutation();

    const submitNewRegistry = (form: any) => {
        let registry: RegistryEntryDTO = {
            code: form.code,
            label: form.label,
            type: form.selectedType,
        };

        addRegistryMutation.mutate(registry);
    };

    const [defaultValueCode, setDefaultValueCode] = useState<string>("");
    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");
    const [defaultValueType, setDefaultValueType] = useState<RegistryEntryType | null>(null);

    const {user: loggedUser} = React.useContext(AuthContext);

    const menus = useTransverseFeatureSidebarMenus(
        loggedUser?.authorities?.some((authority) => authority === "SUPER_ADMIN" || authority === "ADMINISTRATEUR") ||
            false
    );

    return (
        <Container fluid>
            <Sidebar title={t("transversal_settings")} menus={menus} />
            <div className="main-container">
                <Grid>
                    <Grid.Column key={"users"} width={16}>
                        <Segment className="card-container" textAlign="center">
                            <Menu borderless secondary>
                                <Menu.Item>
                                    <Dropdown
                                        icon="filter"
                                        labeled
                                        button
                                        className="icon"
                                        name="typeFilter"
                                        selection
                                        options={typeOtions}
                                        placeholder={t("Filter by type")}
                                        onChange={(_, {value}) => handleFilter(value)}
                                    />
                                </Menu.Item>
                                <Menu.Menu position="right">
                                    <Menu.Item>
                                        <ModalAdd
                                            isValidationDisabled={
                                                Object.keys(addRegistryForm.formState.errors).length !== 0
                                            }
                                            isPending={addRegistryMutation.isPending}
                                            isSuccess={addRegistryMutation.isSuccess}
                                            isError={addRegistryMutation.isError}
                                            resetMutation={addRegistryMutation.reset}
                                            error={addRegistryMutation.error}
                                            onValidate={addRegistryForm.handleSubmit(submitNewRegistry)}
                                            onCancel={() => {
                                                setDefaultValueCode("");
                                                setDefaultValueLabel("");
                                                setDefaultValueType(null);
                                            }}
                                            onSuccess={() => {
                                                setDefaultValueCode("");
                                                setDefaultValueLabel("");
                                                setDefaultValueType(null);
                                            }}
                                            iconOnOpenButton
                                            openButtonIcon="add"
                                            objectToAdd="Entry registry"
                                            objectToAddContext="male"
                                            renderModalContent={() => (
                                                <FormRegistry
                                                    errors={addRegistryForm.formState.errors}
                                                    control={addRegistryForm.control}
                                                    defaultValueCode={defaultValueCode}
                                                    setDefaultValueCode={setDefaultValueCode}
                                                    defaultValueLabel={defaultValueLabel}
                                                    setDefaultValueLabel={setDefaultValueLabel}
                                                    defaultValueType={defaultValueType}
                                                    setDefaultValueType={setDefaultValueType}
                                                />
                                            )}
                                        />
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                            <SimpleList columns={registryTableColumns} rows={registryToDisplay} globalData={[]} />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        </Container>
    );
};

export default RegistryListPage;
