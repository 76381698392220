import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid } from "semantic-ui-react";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import { codeValidation } from "../../../Global/Form/Validation";

const FormPricingGrid = ({
    control,
    errors,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
    updateMode,
}: any) => {
    const {t} = useTranslation();

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        {!updateMode && (
                            <Controller
                                control={control}
                                defaultValue={defaultValueCode}
                                rules={{required: true, pattern: codeValidation()}}
                                name={"code"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        autoFocus
                                        fluid
                                        required
                                        type={"text"}
                                        name={name}
                                        label={t("Code")}
                                        placeholder={t("Code")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueCode(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="30"
                                        error={getErrorMessage(t, errors, name)}
                                    />
                                )}
                            />
                        )}

                        <Controller
                            control={control}
                            defaultValue={defaultValueLabel}
                            rules={{required: true}}
                            name={"label"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength="100"
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormPricingGrid;
