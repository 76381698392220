import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Card} from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdateForm from "../../../../../Components/Modals/ModalUpdateForm";
import {
    useDeletePricingGridDimensionValueMutation,
    useUpdatePricingGridDimensionValueMutation,
} from "../../../../../Services/PricingGrid/Queries";
import {
    DimensionGridValueDTO,
    DimensionGridValueDTOWithId,
    DimensionType,
    DimensionValueType,
} from "../../../../../Services/PricingGrid/Types";
import {useGetVersionQuery} from "../../../../../Services/Version/Queries";
import UpdateFormDimensionValue from "./UpdateFormDimensionValue";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PropsType = {
    dimensionGridId: string;
    dimensionGridValue: DimensionGridValueDTOWithId;
    type: DimensionType;
    valueType: DimensionValueType;
};

const PricingGridDimensionValueCard = ({dimensionGridId, dimensionGridValue, type, valueType}: PropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<any>();

    const updatePricingGridDimensionMutation = useUpdatePricingGridDimensionValueMutation(
        params.versionId,
        params.gridId,
        dimensionGridId,
        dimensionGridValue.id.toString()
    );
    const deleteMutation = useDeletePricingGridDimensionValueMutation(
        params.versionId,
        params.gridId,
        dimensionGridId,
        dimensionGridValue.id.toString()
    );
    const {data: version} = useGetVersionQuery(params.id, params.versionId, true);

    const handleUpdate = (editedCell: any) => {
        if (dimensionGridId) {
            updatePricingGridDimensionMutation.mutate({
                dimensionGridId: dimensionGridId,
                dimensionGridValueId: editedCell.id,
                editedCell,
            });
        }
    };

    const handleDelete = () => {
        if (dimensionGridId) {
            deleteMutation.mutate({
                dimensionGridId: dimensionGridId,
                dimensionGridValueId: dimensionGridValue.id,
            });
        }
    };

    const submitForm = async (formData: any) => {
        const editedCell = {
            ...dimensionGridValue,
            label: formData.label,
            min:
                type === DimensionType.T && valueType !== DimensionValueType.DATE
                    ? formData.min
                    : dimensionGridValue.min,
            max:
                type === DimensionType.T && valueType !== DimensionValueType.DATE
                    ? formData.max
                    : dimensionGridValue.max,
            minDate:
                type === DimensionType.T && valueType === DimensionValueType.DATE
                    ? formData.minDate
                    : dimensionGridValue.minDate,
            maxDate:
                type === DimensionType.T && valueType === DimensionValueType.DATE
                    ? formData.maxDate
                    : dimensionGridValue.maxDate,
            value: type === DimensionType.F ? formData.value : dimensionGridValue.value,
        };

        handleUpdate(editedCell);
    };

    const renderContent = () => (
        <Card.Content>
            <Card.Header style={{fontSize: "small"}}>
                {dimensionGridValue.value !== undefined &&
                    dimensionGridValue.value !== null &&
                    dimensionGridValue.value}

                {dimensionGridValue.min !== undefined &&
                    dimensionGridValue.min !== null &&
                    dimensionGridValue.max !== undefined &&
                    dimensionGridValue.max !== null &&
                    `${dimensionGridValue.min} - ${dimensionGridValue.max}`}

                {dimensionGridValue.minDate !== undefined &&
                    dimensionGridValue.minDate !== null &&
                    dimensionGridValue.maxDate !== undefined &&
                    dimensionGridValue.maxDate !== null &&
                    `${new Date(dimensionGridValue.minDate).toLocaleDateString("fr")} - ${new Date(
                        dimensionGridValue.maxDate
                    ).toLocaleDateString("fr")}`}

                {version !== undefined && version.pipDate === null && (
                    <Button.Group basic floated="right">
                        <ModalUpdateForm
                            isValidationDisabled={Object.keys(errors).length !== 0}
                            isPending={updatePricingGridDimensionMutation.isPending}
                            isSuccess={updatePricingGridDimensionMutation.isSuccess}
                            isError={updatePricingGridDimensionMutation.isError}
                            resetMutation={updatePricingGridDimensionMutation.reset}
                            error={updatePricingGridDimensionMutation.error}
                            onValidate={handleSubmit(submitForm)}
                            iconOnOpenButton
                            openButtonIcon="edit"
                            objectToUpdate={t("Dimension_value")}
                            objectToUpdateContext="female"
                            basicButton
                            renderModalContent={() => {
                                const dimensiondimensionGridValue: DimensionGridValueDTO & {
                                    dimensionType: DimensionType;
                                    dimensionValueType: DimensionValueType;
                                } = {
                                    ...dimensionGridValue,
                                    dimensionType: type,
                                    dimensionValueType: valueType,
                                };
                                return (
                                    <UpdateFormDimensionValue
                                        control={control}
                                        errors={errors}
                                        dimensionItem={dimensiondimensionGridValue}
                                    />
                                );
                            }}
                        />

                        <ModalDelete
                            isSuccess={deleteMutation.isSuccess}
                            isPending={deleteMutation.isPending}
                            isError={deleteMutation.isError}
                            error={deleteMutation.error}
                            resetMutation={deleteMutation.reset}
                            onValidate={handleDelete}
                            objectToDelete="Dimension_value"
                            objectType="female"
                            openButtonIcon="trash alternate"
                            iconOnOpenButton
                            basicButton
                        />
                    </Button.Group>
                )}
            </Card.Header>
            <Card.Meta>{dimensionGridValue.label}</Card.Meta>
        </Card.Content>
    );

    return <Card key={dimensionGridValue.id}>{renderContent()}</Card>;
};

export default PricingGridDimensionValueCard;
