import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import {
    AccordionContent,
    AccordionTitle,
    Button,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon,
    Label,
    Message
} from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import { ConditionPart, OperandEnum } from "../../../../../Services/Condition/Types";
import { useDeleteVariableCondition, useUpdateVariableCondition } from "../../../../../Services/Variables/Queries";
import { Variable, VariableCondition, VariableType } from "../../../../../Services/Variables/Types";
import { VersionDTO } from "../../../../../Services/Version/Types";
import { conditionPartListToString } from "../../../../../Utils/ConditionPartUtils";
import ConditionHandling from "../../../Condition/ConditionHandling";
import ConditionView from "../../../Condition/Fragments/ConditionView";
import VariableConditionAssignement from "./VariableConditionAssignement";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

type VariableConditionAccordionPropsType = {
    condition: VariableCondition;
    version: VersionDTO | undefined;
    index: number;
    activeIndex: number;
    setActiveIndex: Function;
    variable: Variable;
    numberOfConditions: number;
};

const VariableConditionAccordion = ({
    condition,
    version,
    index,
    activeIndex,
    setActiveIndex,
    variable,
    numberOfConditions,
}: VariableConditionAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const conditionKindList =
        variable.variableType === VariableType.R
            ? [
                  {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
                  {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
                  {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
                  {
                      key: 4,
                      text: "Computing rule variables",
                      value: OperandEnum.VARIABLE_COMPUTING_RULE,
                      type: "female",
                  },
                  {key: 5, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
              ]
            : [
                  {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
                  {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
                  {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
                  {key: 4, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
              ];

    const updateVariableConditionMutation = useUpdateVariableCondition(
        params.versionId,
        params.variableId,
        String(condition.id),
        variable.variableType
    );
    const deleteVariableConditionMutation = useDeleteVariableCondition(
        params.versionId,
        params.variableId,
        String(condition.id),
        variable.variableType
    );

    useEffect(() => {
        if (activeIndex !== index) {
            setEditCondition(false);
        }
    }, [activeIndex, index]);

    return (
        <div key={index}>
            <AccordionTitle
                style={{display: "flex", alignItems: "center"}}
                active={activeIndex === index}
                index={index}
                onClick={() => {
                    if (activeIndex === index) setActiveIndex(-1);
                    else setActiveIndex(index);
                }}>
                <Icon name="dropdown" />
                <div style={{display: "flex", alignItems: "center"}}>
                    {t("Condition") + " " + (index + 1)}
                    {!(activeIndex === index) && numberOfConditions !== 1 && (
                        <>
                            {" "}
                            -
                            <p style={{marginLeft: "4px", fontWeight: "lighter", fontSize: "12px", color: "grey"}}>
                                {condition.ifCondition
                                    ? conditionPartListToString(condition.conditionPartList)
                                    : t("Else")}
                            </p>
                        </>
                    )}
                </div>
                {!condition.valid && (
                    <Label size="small" color="red" basic style={{marginLeft: "15px"}}>
                        <Icon name="warning sign" />
                        {t("Invalid").toUpperCase()}
                    </Label>
                )}
            </AccordionTitle>
            <AccordionContent active={activeIndex === index}>
                {condition.ifCondition === "( ( 1 == 1 ) )" && (
                    <Message warning>
                        {t(
                            "This condition contains default values, it would be convenient to update it before validating product version's"
                        )}
                    </Message>
                )}
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>

                {editCondition ? (
                    <ConditionHandling
                        versionId={params.versionId}
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: Partial<VariableCondition> = {
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate,
                                orderCondition: condition.orderCondition,
                                refGridCode: condition.refGridCode,
                                variableCriterias: condition.variableCriterias.map((variableCriteria) => {
                                    if (variableCriteria.computingRule) {
                                        variableCriteria.computingRule.id = null;
                                        variableCriteria.computingRule.computingRuleConditions[0].id = null;
                                    }
                                    variableCriteria.id = null;
                                    return variableCriteria;
                                }),
                                defaultValue: condition.defaultValue,
                            };
                            updateVariableConditionMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateVariableConditionMutation}
                    />
                ) : (
                    <>
                        <Grid columns={3}>
                            {variable.variableConditions.length === 1 ? (
                                <GridColumn width={2} textAlign="left" verticalAlign="middle">
                                    {t("Default value")}
                                </GridColumn>
                            ) : index !== variable.variableConditions.length - 1 ? (
                                <>
                                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                        {t("If")} :
                                    </GridColumn>
                                    <GridColumn width={14} textAlign="center" className="condition">
                                        <ConditionView conditionPartList={condition.conditionPartList} />
                                    </GridColumn>
                                    {version !== undefined && version.pipDate === null && (
                                        <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                            <Button
                                                name={"editCondition" + Number(index + 1)}
                                                color="grey"
                                                icon="edit"
                                                compact
                                                basic
                                                onClick={() => {
                                                    setEditCondition(true);
                                                }}
                                            />
                                        </GridColumn>
                                    )}
                                </>
                            ) : (
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("Else")}
                                </GridColumn>
                            )}
                        </Grid>
                        <Divider horizontal>
                            <Header as="h4">{t("Assignment")}</Header>
                        </Divider>

                        <VariableConditionAssignement
                            variable={variable}
                            variableCondition={condition}
                            version={version}
                            updateVariableConditionMutation={updateVariableConditionMutation}
                            activeIndex={activeIndex}
                            index={index}
                        />

                        {index !== variable.variableConditions.length - 1 &&
                            version !== undefined &&
                            version.pipDate === null && (
                                <>
                                    <Divider />
                                    <Grid>
                                        <GridRow>
                                            <GridColumn width={14} />
                                            <GridColumn width={2} textAlign={"right"}>
                                                <ModalDelete
                                                    isSuccess={deleteVariableConditionMutation.isSuccess}
                                                    isPending={deleteVariableConditionMutation.isPending}
                                                    isError={deleteVariableConditionMutation.isError}
                                                    error={deleteVariableConditionMutation.error}
                                                    resetMutation={deleteVariableConditionMutation.reset}
                                                    objectToDelete="Condition"
                                                    objectType="female"
                                                    openModalButtonName={"deleteCondition" + Number(index + 1)}
                                                    onValidate={() =>
                                                        deleteVariableConditionMutation.mutate(condition.id)
                                                    }
                                                    onSuccess={() => setActiveIndex(-1)}
                                                />
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </>
                            )}
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default VariableConditionAccordion;
