import {useState} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import {QuestionValueTypeEnum} from "../../../../Services/Question/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {codeValidation} from "../../../Global/Form/Validation";

const FormQuestion = ({
    control,
    errors,
    defaultValueName,
    setDefaultValueName,
    defaultValueType,
    setDefaultValueType,
    defaultValueMandatory,
    setDefaultValueMandatory,
    defaultValueDefaultValue,
    setDefaultValueDefaultValue,
}: any) => {
    const {t} = useTranslation();
    const [isMandatory, setIsMandatory] = useState<boolean | undefined>(defaultValueMandatory);
    const [valueTypeSelected, setValueTypeSelected] = useState<QuestionValueTypeEnum>(QuestionValueTypeEnum.STRING);

    const questionTypeOptions = [
        {key: 1, text: t("Date"), value: QuestionValueTypeEnum.DATE},
        {key: 2, text: t("Number"), value: QuestionValueTypeEnum.NOMBRE},
        {key: 3, text: t("List"), value: QuestionValueTypeEnum.LISTE},
        {key: 4, text: t("String"), value: QuestionValueTypeEnum.STRING},
    ];

    const handleValueTypeChange = (valueSelected: any) => {
        const optionSelected = questionTypeOptions.find((option) => option.value === valueSelected);
        if (optionSelected) setValueTypeSelected(optionSelected.value);
    };

    const inputTypeByValueType = (valueType: QuestionValueTypeEnum) => {
        switch (valueType) {
            case "STRING":
            case "LISTE":
                return "text";
            case "NOMBRE":
                return "number";
            case "DATE":
                return "date";
        }
    };

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueName}
                            rules={{required: true, maxLength: 50, pattern: codeValidation()}}
                            name={"name"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Name")}
                                    placeholder={t("Name of the question")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueName(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength="50"
                                    error={getErrorMessage(t, errors, name, undefined, 50)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            name="valueType"
                            defaultValue={defaultValueType}
                            render={({field: {name, value, onChange}}) => (
                                <Form.Select
                                    label={t("Type")}
                                    name={name}
                                    required
                                    placeholder={t("Choose a question type")}
                                    onChange={(e, {value}) => {
                                        handleValueTypeChange(value);
                                        onChange(value);
                                        setDefaultValueType(value);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name, undefined, undefined)}
                                    options={questionTypeOptions}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="mandatory"
                            defaultValue={defaultValueMandatory}
                            render={({field: {onChange}}) => (
                                <Form.Checkbox
                                    toggle
                                    label={t("Mandatory")}
                                    name="mandatory"
                                    onChange={(e, {checked}) => {
                                        setIsMandatory(checked);
                                        onChange(checked);
                                        setDefaultValueMandatory(checked);
                                    }}
                                    checked={defaultValueMandatory}
                                />
                            )}
                        />

                        {!isMandatory && (
                            <Controller
                                control={control}
                                name="defaultValue"
                                defaultValue={defaultValueDefaultValue}
                                rules={{
                                    required: true,
                                    maxLength: 100,
                                }}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        type={inputTypeByValueType(valueTypeSelected)}
                                        name={name}
                                        label={t("Default value")}
                                        placeholder={t("Default value")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueDefaultValue(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="100"
                                        error={getErrorMessage(t, errors, name, undefined, 100)}
                                    />
                                )}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormQuestion;
