import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid } from "semantic-ui-react";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";

const FormAddRejectionRule = ({
    control,
    errors,
    defaultValueLabel,
    setDefaultValueLabel,
    defaultValueErrorMessage,
    setDefaultValueErrorMessage,
}: any) => {
    const {t} = useTranslation();

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueLabel}
                            rules={{required: true}}
                            name={"label"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            name="error_message"
                            defaultValue={defaultValueErrorMessage}
                            render={({field: {name, onChange, value, onBlur}}) => (
                                <Form.Input
                                    label={t("Error message")}
                                    name={name}
                                    required
                                    type={"text"}
                                    onBlur={onBlur}
                                    placeholder={t("Error message")}
                                    onChange={(e, {value}) => {
                                        onChange(value);
                                        setDefaultValueErrorMessage(value);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormAddRejectionRule;
