import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Container, Grid, Input, Menu, Pagination, Segment, Table, TableRow} from "semantic-ui-react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import {DataTypeColumnEnum} from "../../Components/SimpleList/SimpleList";
import {useProductsSidebarMenu} from "../../Hooks/useProductsSidebarMenu";
import PremiumsPricingRightsBreadcrumb from "../PremiumsPricingRightsBreadcrumb";
import {useGetPremiumsPricingRights} from "../Services/PremiumsPricingRights/Queries";
import {PremiumsPricingRights} from "../Services/PremiumsPricingRights/Types";

const pricingRightColumns = [
    {text: "Product", dataField: "productCode", type: DataTypeColumnEnum.STRING},
    {text: "Source", dataField: "sourceName", type: DataTypeColumnEnum.STRING},
    {text: "Business provider name", dataField: "businessProviderName", type: DataTypeColumnEnum.STRING},
    {text: "Min age", dataField: "minimumAge", type: DataTypeColumnEnum.STRING},
    {text: "Max age", dataField: "maximumAge", type: DataTypeColumnEnum.STRING},
    {text: "Health plan", dataField: "healthPlan", type: DataTypeColumnEnum.STRING},
    {text: "Family composition", dataField: "familyComposition", type: DataTypeColumnEnum.ENUM},
    {text: "Min enfant", dataField: "minimumNumberOfChildren", type: DataTypeColumnEnum.STRING},
    {text: "Max enfant", dataField: "maximumNumberOfChildren", type: DataTypeColumnEnum.STRING},
    {text: "Départements", dataField: "departments", type: DataTypeColumnEnum.STRING},
    {text: "EvenWeek", dataField: "evenWeek", type: DataTypeColumnEnum.BOOLEAN},
    {text: "Start date", dataField: "startDateRights", type: DataTypeColumnEnum.DATE},
    {text: "End date", dataField: "endDateRights", type: DataTypeColumnEnum.DATE},
    {text: "Start day of week", dataField: "startDayOfWeek", type: DataTypeColumnEnum.ENUM},
    {text: "End day of week", dataField: "endDayOfWeek", type: DataTypeColumnEnum.ENUM},
    {text: "Start time", dataField: "startTime", type: DataTypeColumnEnum.STRING},
    {text: "End time", dataField: "endTime", type: DataTypeColumnEnum.STRING},
    {text: "Insurance package list", dataField: "insurancePackageCodeList", type: DataTypeColumnEnum.LIST},
];

type ConfigType = {
    column: null | string;
    direction: undefined | "descending" | "ascending";
    data: PremiumsPricingRights[];
};

const PremiumsPricingRightsPage = ({...props}) => {
    const {t} = useTranslation();
    const menus = useProductsSidebarMenu();

    const pricingRightsQueryResult = useGetPremiumsPricingRights();
    const [pricingRightsFilter, setpricingRightsFilter] = useState<PremiumsPricingRights[]>([]);

    const [activePage, setActivePage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(0);
    const pageLimit = 10;

    const applyPagination = (rows: PremiumsPricingRights[], activePage: number) => {
        const trimStart = (activePage - 1) * pageLimit;
        const trimEnd = trimStart + pageLimit;
        return rows.slice(trimStart, trimEnd);
    };

    const handleFilter = (searchValue: string) => {
        if (pricingRightsQueryResult.status === "success" && pricingRightsQueryResult.data.length > 0) {
            let rows = null;
            if (searchValue === undefined) {
                rows = pricingRightsQueryResult.data;
                if (config.column !== null) {
                    rows = sortByColumn(pricingRightsQueryResult.data, config.column);
                }
                setConfig({
                    ...config,
                    data: applyPagination(rows, activePage),
                });
            } else {
                rows = pricingRightsQueryResult.data.filter(
                    (right) =>
                        right.productCode.toLowerCase().includes(searchValue.toLowerCase()) ||
                        right.businessProviderName?.toLowerCase().includes(searchValue.toLowerCase()) ||
                        right.sourceName.toLowerCase().includes(searchValue.toLowerCase())
                );
                if (config.column !== null) {
                    rows = sortByColumn(rows, config.column);
                }

                setConfig({
                    ...config,
                    data: [...applyPagination(rows, activePage)],
                });
            }
            setpricingRightsFilter(applyPagination(rows, activePage));
        }
    };

    useEffect(() => {
        if (pricingRightsQueryResult.isSuccess && pricingRightsQueryResult.data) {
            let rows = pricingRightsQueryResult.data;
            if (rows.length > pageLimit) {
                setTotalPage(Math.ceil(rows.length / pageLimit));
            }

            setConfig({
                ...config,
                data: [...applyPagination(rows, activePage)],
            });
            setpricingRightsFilter(pricingRightsQueryResult.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingRightsQueryResult.isSuccess, pricingRightsQueryResult.data]);

    const formatCellValue = (type: DataTypeColumnEnum, value: any) => {
        switch (type) {
            case "date": {
                if (value === null) return "";
                return t("format_date", {value: value}) === "Invalid Date" ? value : t("format_date", {value: value});
            }
            case "enum": {
                return value !== null ? t(`coverlife:enums.${value}`) : "-";
            }
            case "list": {
                return value.join(", ");
            }
            case "boolean": {
                if (value === null || value === undefined || value === "") {
                    return "";
                } else {
                    return value ? "PAIRE" : "IMPAIRE";
                }
            }
            default: {
                return value;
            }
        }
    };

    const handlePageChange = (activePage: number) => {
        let rows = pricingRightsFilter;
        if (config.column !== null) {
            rows = sortByColumn(rows, config.column);
        }
        rows = applyPagination(rows, activePage);

        setConfig({
            ...config,
            data: [...rows],
        });
        setActivePage(activePage);
    };

    const [config, setConfig] = useState<ConfigType>({
        column: null,
        direction: undefined,
        data: [...pricingRightsFilter],
    });

    const handleClick = (columnName: string) => () => {
        let rows = pricingRightsFilter;
        if (columnName === config.column) {
            rows = rows.reverse();

            setConfig({
                ...config,
                data: applyPagination(rows, activePage),
                direction: config.direction === "ascending" ? "descending" : "ascending",
            });
        } else {
            rows = sortByColumn(rows, columnName);

            setConfig({
                ...config,
                column: columnName,
                data: applyPagination(rows, activePage),
                direction: "ascending",
            });
        }
    };

    const sortByColumn = (array: any[], columnName: string) => {
        return array.sort((a: any, b: any) => {
            return a[columnName].localeCompare(b[columnName]);
        });
    };

    return (
        <React.Fragment>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            <PremiumsPricingRightsBreadcrumb sections={props.sections} />
            <Container fluid>
                <div className="main-container">
                    <Grid centered>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                                        <Menu.Menu position="right">
                                            <Menu.Item style={{padding: 0}}>
                                                <Input
                                                    icon="search"
                                                    placeholder={t("Product, Source, Provider")}
                                                    onChange={(_, {value}) => handleFilter(value)}
                                                />
                                            </Menu.Item>
                                        </Menu.Menu>
                                    </Menu>
                                    <Segment
                                        id="pricing-right-table"
                                        style={{overflow: "auto", border: "none", boxShadow: "none", padding: 0}}>
                                        <Table
                                            name={"list"}
                                            size="small"
                                            sortable
                                            striped
                                            celled
                                            compact
                                            aria-rowcount={pricingRightsFilter}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell
                                                        sorted={
                                                            config.column === "productCode"
                                                                ? config.direction
                                                                : undefined
                                                        }
                                                        onClick={handleClick("productCode")}
                                                        key="productCode"
                                                        rowSpan="2">
                                                        {t("Product")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={
                                                            config.column === "sourceName"
                                                                ? config.direction
                                                                : undefined
                                                        }
                                                        onClick={handleClick("sourceName")}
                                                        key="sourceName"
                                                        rowSpan="2">
                                                        {t("Source")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell rowSpan="2">
                                                        {t("Business provider name")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="center" colSpan="2">
                                                        {t("Age")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell rowSpan="2">{t("Health plan")}</Table.HeaderCell>
                                                    <Table.HeaderCell rowSpan="2">
                                                        {t("Family composition")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="center" colSpan="2">
                                                        {t("Children")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell rowSpan="2">{t("Departments")}</Table.HeaderCell>
                                                    <Table.HeaderCell rowSpan="2">{t("EvenWeek")}</Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="center" colSpan="2">
                                                        {t("Dates")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="center" colSpan="2">
                                                        {t("Days of week")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="center" colSpan="2">
                                                        {t("Times")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell rowSpan="2">
                                                        {t("Insurance package list")}
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t("Min")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Max")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Min")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Max")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Start")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("End")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Start")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("End")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Start")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("End")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {config.data.length > 0 ? (
                                                    config.data.map((row: any, key: number) => (
                                                        <TableRow key={key} aria-rowindex={key}>
                                                            {pricingRightColumns.map(
                                                                (column, key) =>
                                                                    column.type !== DataTypeColumnEnum.LINK && (
                                                                        <Table.Cell
                                                                            key={key}
                                                                            collapsing={true}
                                                                            style={{wordWrap: "break-word"}}>
                                                                            {formatCellValue(
                                                                                column.type,
                                                                                row[column.dataField]
                                                                            )}
                                                                        </Table.Cell>
                                                                    )
                                                            )}
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <Table.Row colSpan={pricingRightColumns.length} textAlign="center">
                                                        <Table.Cell colSpan={pricingRightColumns.length}>
                                                            {t("No data")}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </Table.Body>

                                            {pricingRightsFilter.length > pageLimit && (
                                                <Table.Footer>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan={pricingRightColumns.length}>
                                                            <Pagination
                                                                activePage={activePage}
                                                                firstItem={null}
                                                                lastItem={null}
                                                                totalPages={totalPage}
                                                                onPageChange={(_, currentPage) => {
                                                                    handlePageChange(Number(currentPage.activePage));
                                                                }}
                                                            />
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Footer>
                                            )}
                                        </Table>
                                    </Segment>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default PremiumsPricingRightsPage;
