import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, Params, useParams} from "react-router-dom";
import {Card, Grid, Input, Label, Menu, Message, Segment, SemanticCOLORS, Tab} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import SaveQuoteLabel from "../../../../../Components/Labels/SaveQuoteLabel";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetVariablesList} from "../../../../../Services/Variables/Queries";
import {Variable, VariableType, VariableValueType} from "../../../../../Services/Variables/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import {
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsVariableType,
} from "../../../../../Utils/SortUtils";
import ModalAddVariable from "../../../Variables/Fragments/ModalAddVariable";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PropsType = {
    version: VersionDTO | undefined;
    type: VariableType;
};

const VariablesList = ({version, type}: PropsType) => {
    const {t} = useTranslation();
    const userContext = useContext(AuthContext);
    const {id: productId, versionId} = useParams() as IParams;

    const getVariablesList = useGetVariablesList(versionId, type);
    const [sortMethod, setSortMethod] = useState<(a: any, b: any) => number>(() => (a: any, b: any) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    //Ne pas trier sur le type de données pour les variables règles de calcul
    const sortOptions =
        type !== VariableType.R
            ? [...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate, ...sortOptionsVariableType]
            : [...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (name: string, valueType: VariableValueType) => {
        return (
            name.toLowerCase().includes(search.toLowerCase()) ||
            valueType.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const getColorOfValueTypeLabel = (variableValueType: VariableValueType): SemanticCOLORS | undefined => {
        switch (variableValueType) {
            case VariableValueType.STRING:
                return "blue";
            case VariableValueType.DATE:
                return "green";
            case VariableValueType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>
                                <Menu.Item>
                                    <Input
                                        name="searchVariables"
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "female",
                                            entity: t("Variable").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>
                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddVariable variableType={type}></ModalAddVariable>
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getVariablesList.isSuccess &&
                            getVariablesList.data !== undefined &&
                            getVariablesList.data.length === 0 &&
                            (type === VariableType.R ? (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No variable computing rule")}
                                        content={t("There is no variable computing rule for this version")}
                                    />
                                </Segment>
                            ) : (
                                <>
                                    <Card.Group itemsPerRow={4}>
                                        {getVariablesList.data
                                            .slice()
                                            .filter((variable: Variable) =>
                                                hiddenBySearch(variable.name, variable.valueType)
                                            )
                                            .sort(sortMethod)
                                            .map((variable: Variable, index: number) => (
                                                <Card
                                                    key={`variable-${index}`}
                                                    as={Link}
                                                    to={`/products/${productId}/versions/${versionId}/variables/${variable.id}`}>
                                                    <Card.Content>
                                                        <Card.Header>
                                                            <TextOverflow value={variable.name} />
                                                        </Card.Header>
                                                        <Card.Description style={{marginTop: "10px"}}>
                                                            <Label
                                                                color={getColorOfValueTypeLabel(variable.valueType)}
                                                                data-cy="variable-card-valueType">
                                                                {t("enums.VariableValueType." + variable.valueType)}
                                                                {t("enums.VariableValueType." + variable.valueType)}
                                                            </Label>
                                                            {variable.saveQuote && <SaveQuoteLabel />}
                                                        </Card.Description>

                                                        {!variable.valid && (
                                                            <Label
                                                                corner="right"
                                                                color="red"
                                                                size="tiny"
                                                                icon="warning sign"
                                                            />
                                                        )}
                                                    </Card.Content>

                                                    {userContext.user !== undefined && (
                                                        <Card.Content
                                                            extra
                                                            textAlign="right"
                                                            style={{fontStyle: "italic"}}>
                                                            <span>
                                                                {displayAuditLog(
                                                                    {
                                                                        entityContext: "female",
                                                                        createdDate: variable.createdDate,
                                                                        modifiedDate: variable.modifiedDate,
                                                                        createdBy: variable.createdBy,
                                                                        modifiedBy: variable.modifiedBy,
                                                                        createdOnVersionId: variable.createdOnVersionId,
                                                                    },
                                                                    userContext.user,
                                                                    versionId,
                                                                    t
                                                                )}
                                                            </span>
                                                        </Card.Content>
                                                    )}
                                                </Card>
                                            ))}
                                    </Card.Group>
                                    <Segment style={{border: "0", boxShadow: "none"}}>
                                        <Message
                                            warning
                                            header={t("No variable")}
                                            content={t("There is no variable for this version")}
                                        />
                                    </Segment>
                                </>
                            ))}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getVariablesList.isSuccess &&
                                getVariablesList.data !== undefined &&
                                getVariablesList.data.length !== 0 &&
                                getVariablesList.data
                                    .slice()
                                    .filter((variable: Variable) => hiddenBySearch(variable.name, variable.valueType))
                                    .sort(sortMethod)
                                    .map((variable: Variable, index: number) => (
                                        <Card
                                            key={`variable-${index}`}
                                            as={Link}
                                            to={`/products/${productId}/versions/${versionId}/variables/${variable.id}`}>
                                            <Card.Content>
                                                <Card.Header>
                                                    <TextOverflow value={variable.name} />
                                                </Card.Header>
                                                <Card.Description
                                                    style={{
                                                        marginTop: "10px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}>
                                                    <Label
                                                        color={getColorOfValueTypeLabel(variable.valueType)}
                                                        data-cy="variable-card-valueType"
                                                        image>
                                                        {t("enums.VariableValueType." + variable.valueType)}
                                                    </Label>
                                                    {variable.saveQuote && <SaveQuoteLabel />}
                                                </Card.Description>

                                                {!variable.valid && (
                                                    <Label corner="right" color="red" size="tiny" icon="warning sign" />
                                                )}
                                            </Card.Content>

                                            {userContext.user !== undefined && (
                                                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                                                    <span>
                                                        {displayAuditLog(
                                                            {
                                                                entityContext: "female",
                                                                createdDate: variable.createdDate,
                                                                modifiedDate: variable.modifiedDate,
                                                                createdBy: variable.createdBy,
                                                                modifiedBy: variable.modifiedBy,
                                                                createdOnVersionId: variable.createdOnVersionId,
                                                            },
                                                            userContext.user,
                                                            versionId,
                                                            t
                                                        )}
                                                    </span>
                                                </Card.Content>
                                            )}
                                        </Card>
                                    ))}
                        </div>

                        {getVariablesList.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default VariablesList;
