import {useState} from "react";
import {useTranslation} from "react-i18next";
import {UseQueryResult} from "@tanstack/react-query";
import {Button, Card, Input, Menu, Message, Modal} from "semantic-ui-react";
import useContainerDimensions from "../../Hooks/useContainerDimensions";
import {SortOption} from "../../Utils/SortUtils";
import Sort from "../Sort/Sort";

type EntityPickerPropsType = {
    object: string;
    objectContext: "male" | "female";
    entityListGetMethod: (...args: any[]) => UseQueryResult<any[], Error>;
    entityListGetParameters: any[];
    renderCardContent: (entity: any) => JSX.Element;
    filterEntity: (entity: any) => boolean;
    filterBySearch: (entity: any, search: string) => boolean;
    onClickOnEntity: (entity: any) => void;
    onCancel: () => void;
    sortOptions?: SortOption[];
    defaultSortMethod?: (a: any, b: any) => number;
};

const EntityPicker = ({
    object,
    objectContext,
    entityListGetMethod,
    entityListGetParameters,
    renderCardContent,
    filterEntity,
    filterBySearch,
    onClickOnEntity,
    onCancel,
    sortOptions,
    defaultSortMethod,
}: EntityPickerPropsType) => {
    const {t} = useTranslation();

    let isReturnFilterEmpty = 0;
    const renderCard = (entity: any, index: number) => {
        if (!filterBySearch(entity, search)) isReturnFilterEmpty++;
        return (
            <Card
                key={"card_" + object + "_" + index}
                onClick={() => onClickOnEntity(entity)}
                style={{display: filterBySearch(entity, search) ? "flex" : "none"}}
            >
                {renderCardContent(entity)}
            </Card>
        );
    };

    const {data: entityList, status} = entityListGetMethod(...entityListGetParameters);
    const [sortMethod, setSortMethod] = useState<(a: any, b: any) => number>(
        defaultSortMethod !== undefined ? () => defaultSortMethod : () => () => 1
    );
    const [search, setSearch] = useState("");
    const {columnNumber, columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Modal open size="large" dimmer="blurring" data-cy="entityPicker">
            <Modal.Header>
                {t("Choose", {
                    name: t(object, {count: 1}).toLowerCase(),
                    context: objectContext,
                })}
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                {sortOptions !== undefined && sortOptions.length !== 0 && (
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                <Input
                                    icon="search"
                                    placeholder={t("Find entity", {
                                        context: objectContext,
                                        entity: t("enums.ConditionKindEnum." + object.toUpperCase()).toLowerCase(),
                                    })}
                                    defaultValue={search}
                                    onChange={(_, data) => setSearch(data.value)}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <div className={"ui cards " + columnNumberAsText} ref={ref}>
                        {status === "success" &&
                            entityList !== undefined &&
                            entityList.length !== 0 &&
                            entityList
                                .slice()
                                .sort(sortMethod)
                                .filter(filterEntity)
                                .map((entity, index) => renderCard(entity, index))}
                    </div>
                    {status === "success" && entityList?.length === isReturnFilterEmpty && (
                        <Message warning>
                            {entityList.length === 0 ? (
                                <Message.Content>
                                    {t("enums.WarningEntityPickerEmptyTypeMessage." + object.toLowerCase())}
                                </Message.Content>
                            ) : (
                                <Message.Content>
                                    {t("enums.WarningEntityPickerEmptyFilterTypeMessage." + object.toLowerCase())}
                                </Message.Content>
                            )}
                        </Message>
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onCancel}>
                    {t("Cancel")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EntityPicker;
