import React from "react";
import {useTranslation} from "react-i18next";
import {UseQueryResult} from "@tanstack/react-query";
import {Link} from "react-router-dom";
import {Container, Dropdown, Icon, Label, Menu, SemanticCOLORS} from "semantic-ui-react";
import SuperAdminAuthModal from "../../Pages/Global/Login/Fragments/SuperAdminAuthModal";
import {useGetSelfCompanyQuery} from "../../Services/Company/Queries";
import {Company} from "../../Services/Company/Types";
import {useGetProductsByRiskTypeQuery} from "../../Services/Product/Queries";
import {ProductsByRiskType} from "../../Services/Product/Types";
import {AuthContext} from "../AuthProvider/AuthProvider";
import "./Navbar.css";

export const DefaultNavbar = () => {
    const {t} = useTranslation();
    return (
        <Menu fixed="top" size="large" inverted compact>
            <Container fluid textAlign="center">
                <Menu.Item header>
                    <Icon name="cogs" />
                    {t("product_settings_console")}
                </Menu.Item>
            </Container>
        </Menu>
    );
};

const iconStyle = {
    marginRight: "10px",
};

export const ProductsDropdown: React.FC<{
    productsQuery: UseQueryResult<ProductsByRiskType[], Error>;
}> = ({productsQuery}) => {
    const {t} = useTranslation();
    return (
        <Dropdown
            data-cy="navbar-dropdown-products"
            item
            trigger={
                <span>
                    <Icon name="boxes" size="large" style={iconStyle} /> {t("Product", {count: 2})}
                </span>
            }
            loading={productsQuery.isLoading}
            error={!!productsQuery.error}
            disabled={!!productsQuery.error}
        >
            {!productsQuery.isLoading && !productsQuery.error && productsQuery.isSuccess ? (
                <Dropdown.Menu className="submenu">
                    {productsQuery.data.map((data, key) => (
                        <React.Fragment key={data.riskType}>
                            <Dropdown.Header>{data.riskType}</Dropdown.Header>
                            {data.ranges
                                .flatMap((range) => range.products)
                                .map((product) => (
                                    <Dropdown.Item as={Link} key={product.id} to={`/products/${product.id}`}>
                                        {product.label}
                                    </Dropdown.Item>
                                ))}
                            {key !== productsQuery.data.length - 1 && <Dropdown.Divider />}
                        </React.Fragment>
                    ))}
                </Dropdown.Menu>
            ) : null}
        </Dropdown>
    );
};

export const Navbar: React.FC = () => {
    const {t} = useTranslation();
    const {...context} = React.useContext(AuthContext);

    const productsByRiskType = useGetProductsByRiskTypeQuery();
    const companyQueryResult = useGetSelfCompanyQuery();

    const colorEnvironment = (company: Company): SemanticCOLORS => {
        const environment = company.environmentName.toUpperCase();

        if (environment.startsWith("TEST")) {
            return "green";
        }
        if (environment.startsWith("RECETTE")) {
            return "red";
        }
        if (environment.startsWith("PREPROD")) {
            return "violet";
        }

        return "blue";
    };

    const backgroundColorEnvironment = (company: Company): string => {
        const environment = company.environmentName.toUpperCase();

        if (environment.startsWith("TEST")) {
            return "#d4edda";
        }
        if (environment.startsWith("RECETTE")) {
            return "#f8d7da";
        }
        if (environment.startsWith("PREPROD")) {
            return "#eee3ff";
        }
        if (environment.startsWith("PROD")) {
            return "#fff";
        }

        return "#f0f8ff";
    };

    return (
        <Menu
            pointing
            borderless
            className="top-menu"
            style={{
                backgroundColor: companyQueryResult.isSuccess
                    ? backgroundColorEnvironment(companyQueryResult.data)
                    : "#fff",
            }}
        >
            <Menu.Menu position="left">
                <Menu.Item data-cy="navbar-logo" style={{width: "200px", fontSize: "medium", fontWeight: "bold"}}>
                    <Icon name="cogs" style={iconStyle} />
                    {t("product_settings_console")}
                </Menu.Item>

                <Menu.Item data-cy="navbar-link-home" as={Link} to="/">
                    <Icon name="home" size="large" style={iconStyle} />
                    {t("sections.home")}
                </Menu.Item>

                <Dropdown
                    data-cy="navbar-dropdown-transversal-settings"
                    item
                    trigger={
                        <span>
                            <Icon name="cog" size="large" style={iconStyle} /> {t("transversal_functionnalities")}
                        </span>
                    }
                    icon="dropdown"
                >
                    <Dropdown.Menu>
                        {(context.user?.authorities?.includes("SUPER_ADMIN") ||
                            context.user?.authorities?.includes("ADMINISTRATEUR")) && (
                            <Dropdown.Item as={Link} to="/transversal_settings/users">
                                <span>{t("users")}</span>
                            </Dropdown.Item>
                        )}
                        {(context.user?.authorities?.includes("SUPER_ADMIN") ||
                            context.user?.authorities?.includes("ADMINISTRATEUR")) && (
                            <Dropdown.Item as={Link} to="/transversal_settings/rest_api_access">
                                <span>{t("rest_api_access")}</span>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item as={Link} to="/transversal_settings/registry">
                            <span>{t("Registry")}</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/transversal_settings/risk_types">
                            <span>{t("risk_types")}</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/transversal_settings/company_product_infos">
                            <span>{t("company product infos")}</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <ProductsDropdown productsQuery={productsByRiskType} />

                {context.user?.societeUser === "coverlife" && (
                    <Dropdown
                        data-cy="navbar-dropdown-specific-settings"
                        item
                        trigger={
                            <span>
                                <Icon name="cogs" size="large" style={iconStyle} /> {t("Specific settings")}
                            </span>
                        }
                        name="specific_settings"
                        icon="dropdown"
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={Link}
                                to="/specific_settings/pricing_rights"
                                data-cy="premium_pricing_rights_navbar_submenu"
                            >
                                {t("Premium pricing rights")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}

                {context.user?.societeUser === "generation" && (
                    <Dropdown
                        item
                        trigger={
                            <span>
                                <Icon name="cogs" size="large" style={iconStyle} /> {t("Specific settings")}
                            </span>
                        }
                        name="specific_settings"
                        data-cy="navbar-dropdown-specific-settings"
                        icon="dropdown"
                    >
                        <Dropdown.Menu className="submenu">
                            <Dropdown.Item
                                as={Link}
                                to="/specific_settings/commission_packages"
                                data-cy="commission_packages_navbar_submenu"
                            >
                                {t("commission_packages")}
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to="/specific_settings/e_selection"
                                data-cy="eSelection_navbar_submenu"
                            >
                                {t("eSelection")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </Menu.Menu>

            <Menu.Menu position="right">
                {companyQueryResult.isSuccess && (
                    <>
                        {!companyQueryResult.data.environmentName.toUpperCase().startsWith("PROD") && (
                            <Menu.Item style={{padding: "0px"}}>
                                <Label
                                    data-cy="navbar-environment-label"
                                    color={colorEnvironment(companyQueryResult.data)}
                                    size="large"
                                    style={{
                                        paddingTop: "0.75em",
                                        paddingRight: "1em",
                                        paddingLeft: "1em",
                                        paddingBottom: "0.75em",
                                    }}
                                >
                                    {t("Environment de name", {name: companyQueryResult.data.environmentName})}
                                </Label>
                            </Menu.Item>
                        )}

                        {context.user?.authorities?.includes("SUPER_ADMIN") && (
                            <>
                                <SuperAdminAuthModal></SuperAdminAuthModal>
                                <Menu.Item icon as={Link} to={`/companies/${companyQueryResult.data.id}`}>
                                    <Icon name="users" size="large" />
                                </Menu.Item>
                            </>
                        )}
                    </>
                )}

                <Dropdown
                    data-cy="navbar-dropwdown-user"
                    item
                    trigger={
                        <span>
                            <Icon name="user" size="large" style={iconStyle} /> {context.user?.sub}
                        </span>
                    }
                    icon="dropdown"
                >
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/account">
                            <Icon name="user" style={iconStyle} /> {t("My account")}
                        </Dropdown.Item>
                        <Dropdown.Item disabled as={Link} to="/">
                            <Icon name="wrench" style={iconStyle} /> {t("settings")}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={() => {
                                if (context.logout !== undefined) context.logout();
                            }}
                        >
                            <Icon name="log out" style={iconStyle} />
                            <span>{t("logout")}</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
    );
};
