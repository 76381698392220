import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomError} from "../RequestUtils";
import {
    addComputingRuleCondition,
    checkSyntax,
    deleteComputingRuleCondition,
    updateComputingRuleCondition,
} from "./AxiosRequest";
import {ComputingRuleCondition, RulePart} from "./Types";

export const useCheckSyntax = (): UseMutationResult<boolean, CustomError, RulePart[], Error> => {
    return useMutation<boolean, CustomError, RulePart[], Error>({
        mutationFn: (rulePartList: RulePart[]) => checkSyntax(rulePartList),
    });
};

export const useUpdateComputingRuleCondition = (
    computingRuleId: number,
    conditionId: number
): UseMutationResult<ComputingRuleCondition, CustomError, ComputingRuleCondition, Error> => {
    return useMutation<ComputingRuleCondition, CustomError, ComputingRuleCondition, Error>({
        mutationFn: (condition: ComputingRuleCondition) =>
            updateComputingRuleCondition(computingRuleId, conditionId, condition),
    });
};

export const useDeleteComputingRRuleCondition = (
    computingRuleId: number,
    conditionId: number
): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteComputingRuleCondition(computingRuleId, conditionId),
    });
};

export const useAddComputingRuleCondition = (
    computingRuleId: number
): UseMutationResult<ComputingRuleCondition, CustomError, ComputingRuleCondition, Error> => {
    return useMutation<ComputingRuleCondition, CustomError, ComputingRuleCondition, Error>({
        mutationFn: (condition: ComputingRuleCondition) => addComputingRuleCondition(computingRuleId, condition),
    });
};
