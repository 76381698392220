import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Grid, Header, Icon, Menu} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import ModalUpdateForm from "../../../../../Components/Modals/ModalUpdateForm";
import {
    useAddPricingGridDimensionMutation,
    useDeletePricingGridDimensionMutation,
    useReorderPricingGridDimensionMutation,
    useUpdatePricingGridDimensionMutation,
} from "../../../../../Services/PricingGrid/Queries";
import {Axe, DimensionGridDTO, DimensionType, DimensionValueType} from "../../../../../Services/PricingGrid/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {FormDimension} from "./FormDimension";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PricingGridDimensionMenuPropsType = {
    version: VersionDTO;

    dimensionsAxeColumn: DimensionGridDTO[];
    dimensionsAxeLine: DimensionGridDTO[];

    activeItem: DimensionGridDTO | undefined;
    setActiveItem: Function;
};

const PricingGridDimensionMenu = ({
    version,
    dimensionsAxeColumn,
    dimensionsAxeLine,
    activeItem,
    setActiveItem,
}: PricingGridDimensionMenuPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    // Update dimension
    const updateDimensionMutation = useUpdatePricingGridDimensionMutation(params.versionId, params.gridId);
    const updateDimensionForm = useForm<any>();
    const submitUpdateDimension = (form: any) => {
        const dimension = {
            id: activeItem?.id,
            code: form.code,
            label: form.label,
            dimensionType: form.dimensionType,
            dimensionValueType: form.dimensionValueType,
            visualizationGridDTO: {
                axe: form.dimensionAxe,
            },
        };
        updateDimensionMutation.mutate(dimension);
    };

    // Delete dimension
    const deleteDimensionMutation = useDeletePricingGridDimensionMutation(params.versionId, params.gridId);
    const handleDeleteDimension = () => {
        if (activeItem) {
            deleteDimensionMutation.mutate(activeItem.id);
            setActiveItem(null);
        }
    };

    // Add dimension
    const addDimensionMutation = useAddPricingGridDimensionMutation(params.versionId, params.gridId);
    const addDimensionForm = useForm<any>();
    const submitDimension = (form: any) => {
        const dimension = {
            code: form.code,
            label: form.label,
            dimensionType: form.dimensionType,
            dimensionValueType: form.dimensionValueType,
            visualizationGridDTO: {
                axe: form.dimensionAxe,
            },
        };
        addDimensionMutation.mutate(dimension);
    };

    const [defaultValueDimensionCode, setDefaultValueDimensionCode] = useState<string>("");
    const [defaultValueDimensionLabel, setDefaultValueDimensionLabel] = useState<string>("");
    const [defaultValueDimensionType, setDefaultValueDimensionType] = useState<DimensionType | null>(null);
    const [defaultValueDimensionValueType, setDefaultValueDimensionValueType] = useState<DimensionValueType | null>(
        null
    );
    const [defaultValueDimensionAxeType, setDefaultValueDimensionAxeType] = useState<Axe | null>(null);

    // Reorder dimension
    const [draggedItemIndex, setDraggedItemIndex] = useState<{index: number; axe: Axe} | null>(null);
    const [draggedOverItemIndex, setDraggedOverItemIndex] = useState<{index: number; axe: Axe} | null>(null);

    const onDragStartHandler = (event: React.DragEvent<HTMLDivElement>, indexInfos: {index: number; axe: Axe}) => {
        setDraggedItemIndex(indexInfos);
    };

    const onDragOverHandler = (event: React.DragEvent<HTMLDivElement>, indexInfos: {index: number; axe: Axe}) => {
        if (draggedItemIndex !== null && draggedItemIndex.axe === indexInfos.axe) {
            if (draggedItemIndex.index !== indexInfos.index) {
                setDraggedOverItemIndex(indexInfos);
            } else {
                setDraggedOverItemIndex(null);
            }
        }
    };

    const onDragEndHandler = () => {
        if (
            draggedItemIndex !== null &&
            draggedOverItemIndex !== null &&
            draggedItemIndex.index !== draggedOverItemIndex.index &&
            draggedItemIndex.axe === draggedOverItemIndex.axe
        ) {
            setReorderValidationModalIsOpen(true);
        } else {
            reorderFinishedOrCancelledHandler();
        }
    };

    const [reorderValidationModalIsOpen, setReorderValidationModalIsOpen] = useState<boolean>(false);
    const reorderPricingGridDimensionMutation = useReorderPricingGridDimensionMutation(params.versionId, params.gridId);
    const mutateReorderPricingGridDimensionMutation = () => {
        if (draggedItemIndex !== null && draggedOverItemIndex !== null && draggedItemIndex !== draggedOverItemIndex) {
            const axe = draggedOverItemIndex.axe;
            const basedData = axe === "C" ? dimensionsAxeColumn : dimensionsAxeLine;

            const sortedDimensions = basedData.sort(
                (d1: DimensionGridDTO, d2: DimensionGridDTO) =>
                    d1.visualizationGridDTO.rank - d2.visualizationGridDTO.rank
            );

            if (draggedItemIndex.index < draggedOverItemIndex.index) {
                reorderPricingGridDimensionMutation.mutate({
                    dimensionId: sortedDimensions[draggedItemIndex.index].id,
                    rank: sortedDimensions[draggedOverItemIndex.index].visualizationGridDTO.rank + 1,
                });
            } else {
                reorderPricingGridDimensionMutation.mutate({
                    dimensionId: sortedDimensions[draggedItemIndex.index].id,
                    rank: sortedDimensions[draggedOverItemIndex.index].visualizationGridDTO.rank,
                });
            }
        }
    };

    const reorderFinishedOrCancelledHandler = () => {
        setReorderValidationModalIsOpen(false);
        setDraggedItemIndex(null);
        setDraggedOverItemIndex(null);
    };

    useEffect(() => {
        if (deleteDimensionMutation.isSuccess) {
            setActiveItem(undefined);
        }
    }, [deleteDimensionMutation.isSuccess, setActiveItem]);

    return (
        <>
            {dimensionsAxeColumn.length !== 0 && (
                <Menu fluid vertical>
                    <Menu.Item>
                        <Header size="medium">
                            {t("Dimensions")} - {t("enums.DimensionAxeType.C")}
                        </Header>
                    </Menu.Item>

                    {dimensionsAxeColumn
                        .sort(
                            (d1: DimensionGridDTO, d2: DimensionGridDTO) =>
                                d1.visualizationGridDTO.rank - d2.visualizationGridDTO.rank
                        )
                        .map((dimensionGrid: DimensionGridDTO, index: number) => (
                            <Menu.Item
                                key={`dimension-C-${index}`}
                                data-cy={`dimension-C-${index}`}
                                active={activeItem?.code === dimensionGrid.code}
                                onClick={() => setActiveItem(dimensionGrid)}
                                draggable={true}
                                onDragStart={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragStartHandler(event, {index, axe: Axe.C})
                                }
                                onDragEnter={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragOverHandler(event, {index, axe: Axe.C})
                                }
                                onDragEnd={onDragEndHandler}
                                onDragOver={(event: React.DragEvent<HTMLDivElement>) => event.preventDefault()}
                                style={
                                    draggedItemIndex !== null &&
                                    draggedOverItemIndex !== null &&
                                    draggedItemIndex !== draggedOverItemIndex &&
                                    draggedOverItemIndex.index === index &&
                                    draggedOverItemIndex.axe === Axe.C
                                        ? draggedOverItemIndex.index > draggedItemIndex.index
                                            ? {borderBottom: "1px solid blue"}
                                            : {borderTop: "1px solid blue"}
                                        : {}
                                }
                            >
                                <Grid>
                                    <Grid.Column width={8} verticalAlign={"middle"}>
                                        <Header as="h5">
                                            {dimensionGrid.code}
                                            <Header.Subheader>{dimensionGrid.label}</Header.Subheader>
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width={8} verticalAlign={"middle"}>
                                        {version !== undefined && version.pipDate === null && (
                                            <Button.Group basic floated="right">
                                                <ModalUpdateForm
                                                    isValidationDisabled={
                                                        Object.keys(updateDimensionForm.formState.errors).length !== 0
                                                    }
                                                    isPending={updateDimensionMutation.isPending}
                                                    isSuccess={updateDimensionMutation.isSuccess}
                                                    isError={updateDimensionMutation.isError}
                                                    resetMutation={updateDimensionMutation.reset}
                                                    error={updateDimensionMutation.error}
                                                    onValidate={updateDimensionForm.handleSubmit(submitUpdateDimension)}
                                                    iconOnOpenButton
                                                    openButtonIcon="edit"
                                                    objectToUpdate="Dimension"
                                                    objectToUpdateContext="female"
                                                    openModalButtonName={`pricing-grid-structure-edit-dimension-C-${index}`}
                                                    basicButton
                                                    renderModalContent={() => {
                                                        return (
                                                            <FormDimension
                                                                errors={updateDimensionForm.formState.errors}
                                                                control={updateDimensionForm.control}
                                                                dimension={activeItem}
                                                            />
                                                        );
                                                    }}
                                                />

                                                <ModalDelete
                                                    isSuccess={deleteDimensionMutation.isSuccess}
                                                    isPending={deleteDimensionMutation.isPending}
                                                    isError={deleteDimensionMutation.isError}
                                                    error={deleteDimensionMutation.error}
                                                    resetMutation={deleteDimensionMutation.reset}
                                                    onValidate={handleDeleteDimension}
                                                    onSuccess={() => setActiveItem(null)}
                                                    objectToDelete="Dimension"
                                                    objectType="female"
                                                    openButtonIcon="trash alternate"
                                                    openModalButtonName={`pricing-grid-structure-delete-dimension-C-${index}`}
                                                    iconOnOpenButton
                                                    basicButton
                                                />
                                            </Button.Group>
                                        )}
                                    </Grid.Column>
                                </Grid>
                            </Menu.Item>
                        ))}
                </Menu>
            )}

            {dimensionsAxeLine.length !== 0 && (
                <Menu fluid vertical>
                    <Menu.Item>
                        <Header size="medium">
                            {t("Dimensions")} - {t("enums.DimensionAxeType.L")}
                        </Header>
                    </Menu.Item>

                    {dimensionsAxeLine
                        .sort(
                            (d1: DimensionGridDTO, d2: DimensionGridDTO) =>
                                d1.visualizationGridDTO.rank - d2.visualizationGridDTO.rank
                        )
                        .map((dimensionGrid: DimensionGridDTO, index: number) => (
                            <Menu.Item
                                key={`dimension-L-${index}`}
                                data-cy={`dimension-L-${index}`}
                                active={activeItem?.code === dimensionGrid.code}
                                onClick={() => setActiveItem(dimensionGrid)}
                                draggable={true}
                                onDragStart={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragStartHandler(event, {index, axe: Axe.L})
                                }
                                onDragEnd={onDragEndHandler}
                                onDragOver={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragOverHandler(event, {index, axe: Axe.L})
                                }
                                style={
                                    draggedItemIndex !== null &&
                                    draggedOverItemIndex !== null &&
                                    draggedItemIndex !== draggedOverItemIndex &&
                                    draggedOverItemIndex.index === index &&
                                    draggedOverItemIndex.axe === Axe.L
                                        ? draggedOverItemIndex.index > draggedItemIndex.index
                                            ? {borderBottom: "1px solid blue"}
                                            : {borderTop: "1px solid blue"}
                                        : {}
                                }
                            >
                                <Grid>
                                    <Grid.Column width={8} verticalAlign={"middle"}>
                                        <Header as="h5">
                                            {dimensionGrid.code}
                                            <Header.Subheader>{dimensionGrid.label}</Header.Subheader>
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width={8} verticalAlign={"middle"}>
                                        {version !== undefined && version.pipDate === null && (
                                            <Button.Group basic floated="right">
                                                <ModalUpdateForm
                                                    isValidationDisabled={
                                                        Object.keys(updateDimensionForm.formState.errors).length !== 0
                                                    }
                                                    isPending={updateDimensionMutation.isPending}
                                                    isSuccess={updateDimensionMutation.isSuccess}
                                                    isError={updateDimensionMutation.isError}
                                                    resetMutation={updateDimensionMutation.reset}
                                                    error={updateDimensionMutation.error}
                                                    onValidate={updateDimensionForm.handleSubmit(submitUpdateDimension)}
                                                    iconOnOpenButton
                                                    openButtonIcon="edit"
                                                    objectToUpdate="Dimension"
                                                    objectToUpdateContext="female"
                                                    openModalButtonName={`pricing-grid-structure-edit-dimension-L-${index}`}
                                                    basicButton
                                                    renderModalContent={() => {
                                                        return (
                                                            <FormDimension
                                                                errors={updateDimensionForm.formState.errors}
                                                                control={updateDimensionForm.control}
                                                                dimension={activeItem}
                                                            />
                                                        );
                                                    }}
                                                />

                                                <ModalDelete
                                                    isSuccess={deleteDimensionMutation.isSuccess}
                                                    isPending={deleteDimensionMutation.isPending}
                                                    isError={deleteDimensionMutation.isError}
                                                    error={deleteDimensionMutation.error}
                                                    resetMutation={deleteDimensionMutation.reset}
                                                    onValidate={handleDeleteDimension}
                                                    onSuccess={() => setActiveItem(null)}
                                                    objectToDelete="Dimension"
                                                    objectType="female"
                                                    openButtonIcon="trash alternate"
                                                    openModalButtonName={`pricing-grid-structure-delete-dimension-L-${index}`}
                                                    iconOnOpenButton
                                                    basicButton
                                                />
                                            </Button.Group>
                                        )}
                                    </Grid.Column>
                                </Grid>
                            </Menu.Item>
                        ))}
                </Menu>
            )}
            {version !== undefined && version.pipDate === null && (
                <Menu fluid vertical name="pricing-grid-structure-add-dimension">
                    <ModalAdd
                        isValidationDisabled={Object.keys(addDimensionForm.formState.errors).length !== 0}
                        isPending={addDimensionMutation.isPending}
                        isSuccess={addDimensionMutation.isSuccess}
                        isError={addDimensionMutation.isError}
                        resetMutation={addDimensionMutation.reset}
                        error={addDimensionMutation.error}
                        onValidate={addDimensionForm.handleSubmit(submitDimension)}
                        onSuccess={() => {
                            setDefaultValueDimensionCode("");
                            setDefaultValueDimensionLabel("");
                            setDefaultValueDimensionType(null);
                            setDefaultValueDimensionValueType(null);
                            setDefaultValueDimensionAxeType(null);
                        }}
                        onCancel={() => {
                            setDefaultValueDimensionCode("");
                            setDefaultValueDimensionLabel("");
                            setDefaultValueDimensionType(null);
                            setDefaultValueDimensionValueType(null);
                            setDefaultValueDimensionAxeType(null);
                        }}
                        objectToAdd="Dimension"
                        objectToAddContext="female"
                        renderModalContent={() => {
                            return (
                                <FormDimension
                                    errors={addDimensionForm.formState.errors}
                                    control={addDimensionForm.control}
                                    dimension={undefined}
                                    defaultValueCode={defaultValueDimensionCode}
                                    setDefaultValueCode={setDefaultValueDimensionCode}
                                    defaultValueLabel={defaultValueDimensionLabel}
                                    setDefaultValueLabel={setDefaultValueDimensionLabel}
                                    defaultValueDimensionType={defaultValueDimensionType}
                                    setDefaultValueDimensionType={setDefaultValueDimensionType}
                                    defaultValueDimensionValueType={defaultValueDimensionValueType}
                                    setDefaultValueDimensionValueType={setDefaultValueDimensionValueType}
                                    defaultValueDimensionAxeType={defaultValueDimensionAxeType}
                                    setDefaultValueDimensionAxeType={setDefaultValueDimensionAxeType}
                                />
                            );
                        }}
                        renderOpenButton={() => {
                            return (
                                <Menu.Item>
                                    <Icon name="add" color="blue" />
                                    {t("Add_dimension")}
                                </Menu.Item>
                            );
                        }}
                    />
                </Menu>
            )}

            {reorderValidationModalIsOpen && (
                <ModalUpdate
                    hidden
                    isModalOpenAtStart
                    isPending={reorderPricingGridDimensionMutation.isPending}
                    isSuccess={reorderPricingGridDimensionMutation.isSuccess}
                    isError={reorderPricingGridDimensionMutation.isError}
                    error={reorderPricingGridDimensionMutation.error}
                    resetMutation={reorderPricingGridDimensionMutation.reset}
                    onSuccess={reorderFinishedOrCancelledHandler}
                    onCancel={reorderFinishedOrCancelledHandler}
                    onClose={reorderFinishedOrCancelledHandler}
                    onValidate={mutateReorderPricingGridDimensionMutation}
                    objectToUpdate="Dimension"
                    objectType="female"
                />
            )}
        </>
    );
};

export default PricingGridDimensionMenu;
