import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Button, Card, Divider, Form, Grid, Label} from "semantic-ui-react";
import EntityPicker from "../../../../Components/Modals/EntityPicker";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {useUpdateProductRetrocessionMutation} from "../../../../Services/ProductRetrocession/Queries";
import {ProductRetrocession, RetrocessionMode, RetrocessionType} from "../../../../Services/ProductRetrocession/Types";
import {useGetVariablesList} from "../../../../Services/Variables/Queries";
import {Variable, VariableType} from "../../../../Services/Variables/Types";
import {useGetVersionsQuery} from "../../../../Services/Version/Queries";
import {sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../../../Utils/SortUtils";
import getErrorMessage from "../../../Global/Form/ErrorMessage";

type ProductRetrocessionDetailsEditProps = {
    productId: string;
    retrocessionId: string;
    productRetrocession: ProductRetrocession;
    setEdit: Function;
};

const ProductRetrocessionDetailsEdit: React.FC<ProductRetrocessionDetailsEditProps> = ({
    productId,
    retrocessionId,
    productRetrocession,
    setEdit,
}) => {
    const {t} = useTranslation();
    const params = useParams() as {id: string};

    // Update
    const updateProductRetrocessionMutation = useUpdateProductRetrocessionMutation(productId, retrocessionId);
    const {
        control,
        setValue,
        handleSubmit,
        formState: {errors},
        trigger,
    } = useForm<ProductRetrocession>({mode: "onBlur"});
    const updateProductRetrocession = (formData: ProductRetrocession) => {
        updateProductRetrocessionMutation.mutate(formData);
    };

    // Options RetrocessionMode
    const retrocessionModeOptions = Object.values(RetrocessionMode).map((retrocessionMode: RetrocessionMode) => {
        return {
            key: retrocessionMode.toString(),
            text: t("enums.RetrocessionMode." + retrocessionMode),
            value: retrocessionMode,
        };
    });

    // Options RetrocessionType
    const retrocessionTypeOptions = Object.values(RetrocessionType).map((retrocessionType: RetrocessionType) => {
        return {
            key: retrocessionType.toString(),
            text: t("enums.RetrocessionType." + retrocessionType),
            value: retrocessionType,
        };
    });

    // Entity Picker
    const [variableNameBaseRateEntityPickerIsOpen, setVariableNameBaseRateEntityPickerIsOpen] = useState<boolean>(
        false
    );
    const [
        variableNameCoveragesBaseEntityPickerIsOpen,
        setVariableNameCoveragesBaseEntityPickerIsOpen,
    ] = useState<boolean>(false);

    // Version
    const [versionId, setVersionId] = useState("");
    const versionList = useGetVersionsQuery(params.id);
    useEffect(() => {
        if (versionList.isSuccess) {
            const versionListSorted = versionList.data.sort(
                (version1, version2) =>
                    new Date(version2.effectiveDate).getTime() - new Date(version1.effectiveDate).getTime()
            );
            setVersionId(String(versionListSorted[0].id));
        }
    }, [versionList]);

    const [pickedRetrocessionMode, setPickedRetrocessionMode] = useState<RetrocessionMode>(
        productRetrocession.retrocessionMode
    );

    return (
        <Form>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Business provider rank")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"businessProviderRank"}
                            defaultValue={productRetrocession.businessProviderRank}
                            rules={{required: true, min: 1, max: 5}}
                            render={({field: {name, value, onBlur, onChange, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    required
                                    name={name}
                                    fluid
                                    type="number"
                                    min="1"
                                    max="5"
                                    placeholder={t("Business provider rank")}
                                    onChange={onChange}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                        var newValue: number = Number(e.target.value);
                                        if (newValue > 5) newValue = 5;
                                        else if (newValue < 1) newValue = 1;
                                        setValue("businessProviderRank", newValue);
                                        onBlur();
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name, undefined, undefined, undefined, 1, 5)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Retrocession mode")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"retrocessionMode"}
                            defaultValue={productRetrocession.retrocessionMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur, ref}}) => (
                                <Form.Select
                                    inputref={ref}
                                    required
                                    name={name}
                                    fluid
                                    placeholder={t("Retrocession mode")}
                                    onChange={(e, {value}) => {
                                        onChange(value);
                                        setPickedRetrocessionMode(value as RetrocessionMode);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={retrocessionModeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
                {pickedRetrocessionMode !== undefined && pickedRetrocessionMode === RetrocessionMode.PRODUIT && (
                    <>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Base amount")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name={"retrocessionType"}
                                    defaultValue={productRetrocession.retrocessionType}
                                    rules={{required: true}}
                                    render={({field: {name, value, onChange, onBlur, ref}}) => (
                                        <Form.Select
                                            inputref={ref}
                                            required
                                            name={name}
                                            fluid
                                            placeholder={t("Base amount")}
                                            onChange={(e, {value}) => {
                                                onChange(value);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            error={getErrorMessage(t, errors, name)}
                                            options={retrocessionTypeOptions}
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Variable name base rate")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name={"variableNameBaseRate"}
                                    rules={{required: true}}
                                    defaultValue={productRetrocession.variableNameBaseRate}
                                    render={({field: {onChange, value, name}}) => (
                                        <>
                                            {variableNameBaseRateEntityPickerIsOpen ? (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <Card.Content>
                                                            <Card.Header>{variable.name}</Card.Header>
                                                            <Card.Description style={{marginTop: "10px"}}>
                                                                <Label name="valueTypeCard">
                                                                    {t("enums.VariableValueType." + variable.valueType)}
                                                                </Label>
                                                                <p style={{marginTop: "12px"}}>
                                                                    {variable.description}
                                                                </p>
                                                            </Card.Description>
                                                        </Card.Content>
                                                    )}
                                                    filterEntity={(variableToFilter: Variable) => {
                                                        return true;
                                                    }}
                                                    filterBySearch={(
                                                        variableToFilter: Variable,
                                                        search: string
                                                    ): boolean => {
                                                        return (
                                                            variableToFilter.name
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            variableToFilter.valueType
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            search === ""
                                                        );
                                                    }}
                                                    onClickOnEntity={(variable: Variable) => {
                                                        onChange(variable.name);
                                                        setVariableNameBaseRateEntityPickerIsOpen(false);
                                                    }}
                                                    onCancel={() => {
                                                        setVariableNameBaseRateEntityPickerIsOpen(false);
                                                    }}
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: any, b: any) =>
                                                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                                                    }
                                                />
                                            ) : (
                                                <div className="required field" data-cy="variableNameBaseRate">
                                                    <div className="ui fluid action input" style={{height: "38px"}}>
                                                        <button
                                                            className="ui icon right labeled button fluid"
                                                            style={
                                                                errors["variableNameBaseRate"]
                                                                    ? {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff6f6",
                                                                          border: "1px solid #e0b4b4",
                                                                          color: "#9f3a38",
                                                                      }
                                                                    : {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff",
                                                                          border: "1px solid rgba(34,36,38,.15)",
                                                                      }
                                                            }
                                                            onClick={() => {
                                                                setVariableNameBaseRateEntityPickerIsOpen(true);
                                                            }}>
                                                            {value !== undefined && value !== null && value !== "" ? (
                                                                <p style={{color: "#000000C0", fontWeight: 400}}>
                                                                    {value}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    style={
                                                                        errors["variableNameBaseRate"]
                                                                            ? {
                                                                                  color: "#9f3a38",
                                                                                  fontWeight: 400,
                                                                              }
                                                                            : {
                                                                                  color: "rgba(191,191,191,.87)",
                                                                                  fontWeight: 400,
                                                                              }
                                                                    }>
                                                                    {t("Choose", {
                                                                        name: "variable",
                                                                        context: "female",
                                                                    })}
                                                                    ...
                                                                </p>
                                                            )}
                                                        </button>
                                                    </div>
                                                    {errors["variableNameBaseRate"] && (
                                                        <div
                                                            className="ui pointing above prompt label"
                                                            role="alert"
                                                            aria-atomic="true">
                                                            {t("this field is required")}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Variable name coverages base")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name={"variableNameCoveragesBase"}
                                    rules={{required: true}}
                                    defaultValue={productRetrocession.variableNameCoveragesBase}
                                    render={({field: {onChange, value}}) => (
                                        <>
                                            {variableNameCoveragesBaseEntityPickerIsOpen ? (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <Card.Content>
                                                            <Card.Header>{variable.name}</Card.Header>
                                                            <Card.Description style={{marginTop: "10px"}}>
                                                                <Label name="valueTypeCard">
                                                                    {t("enums.VariableValueType." + variable.valueType)}
                                                                </Label>
                                                                <p style={{marginTop: "12px"}}>
                                                                    {variable.description}
                                                                </p>
                                                            </Card.Description>
                                                        </Card.Content>
                                                    )}
                                                    filterEntity={(variableToFilter: Variable) => {
                                                        return true;
                                                    }}
                                                    filterBySearch={(
                                                        variableToFilter: Variable,
                                                        search: string
                                                    ): boolean => {
                                                        return (
                                                            variableToFilter.name
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            variableToFilter.valueType
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            search === ""
                                                        );
                                                    }}
                                                    onClickOnEntity={(variable: Variable) => {
                                                        onChange(variable.name);
                                                        setVariableNameCoveragesBaseEntityPickerIsOpen(false);
                                                    }}
                                                    onCancel={() => {
                                                        setVariableNameCoveragesBaseEntityPickerIsOpen(false);
                                                    }}
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: any, b: any) =>
                                                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                                                    }
                                                />
                                            ) : (
                                                <div className="required field" data-cy="variableNameCoveragesBase">
                                                    <div className="ui fluid action input" style={{height: "38px"}}>
                                                        <button
                                                            className="ui icon right labeled button fluid"
                                                            style={
                                                                errors["variableNameCoveragesBase"]
                                                                    ? {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff6f6",
                                                                          border: "1px solid #e0b4b4",
                                                                          color: "#9f3a38",
                                                                      }
                                                                    : {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff",
                                                                          border: "1px solid rgba(34,36,38,.15)",
                                                                      }
                                                            }
                                                            onClick={() => {
                                                                setVariableNameCoveragesBaseEntityPickerIsOpen(true);
                                                            }}>
                                                            {value !== undefined && value !== null && value !== "" ? (
                                                                <p style={{color: "#000000C0", fontWeight: 400}}>
                                                                    {value}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    style={
                                                                        errors["variableNameCoveragesBase"]
                                                                            ? {
                                                                                  color: "#9f3a38",
                                                                                  fontWeight: 400,
                                                                              }
                                                                            : {
                                                                                  color: "rgba(191,191,191,.87)",
                                                                                  fontWeight: 400,
                                                                              }
                                                                    }>
                                                                    {t("Choose", {
                                                                        name: "variable",
                                                                        context: "female",
                                                                    })}
                                                                    ...
                                                                </p>
                                                            )}
                                                        </button>
                                                    </div>
                                                    {errors["variableNameCoveragesBase"] && (
                                                        <div
                                                            className="ui pointing above prompt label"
                                                            role="alert"
                                                            aria-atomic="true">
                                                            {t("this field is required")}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )}
                <Grid.Column>
                    <Grid.Row>
                        <Divider />
                    </Grid.Row>
                    <Grid.Row>
                        <div className={"bottom-button-card"} style={{bottom: 0}}>
                            <Button secondary name="cancel-edit" onClick={() => setEdit(false)}>
                                {t("Cancel")}
                            </Button>

                            <ModalUpdate
                                isModalDisabled={Object.keys(errors).length !== 0}
                                isPending={updateProductRetrocessionMutation.isPending}
                                isSuccess={updateProductRetrocessionMutation.isSuccess}
                                isError={updateProductRetrocessionMutation.isError}
                                resetMutation={updateProductRetrocessionMutation.reset}
                                error={updateProductRetrocessionMutation.error}
                                onValidate={handleSubmit(updateProductRetrocession)}
                                objectToUpdate="Retrocession"
                                objectType="female"
                            />
                        </div>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default ProductRetrocessionDetailsEdit;
