import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Accordion, Grid, Message, Segment } from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import { ConditionKindEnum } from "../../../../../Services/Condition/Types";
import { ComputingRuleRoundingMode } from "../../../../../Services/InsurancePackage/Types";
import { useAddVariableCondition } from "../../../../../Services/Variables/Queries";
import { Variable, VariableType } from "../../../../../Services/Variables/Types";
import { VersionDTO } from "../../../../../Services/Version/Types";
import VariableConditionAccordion from "./VariableConditionAccordion";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

type VariableConditionsListPropsType = {
    variable: Variable;
    version: VersionDTO | undefined;
};

const VariableConditionsList = ({variable, version}: VariableConditionsListPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [activeConditionIndex, setActiveConditionIndex] = useState<number>(0);

    const addVariableCondition = useAddVariableCondition(params.versionId, params.variableId, variable.variableType);
    const onAddConditionValidation = () => {
        let defaultValue;
        switch (variable.valueType) {
            case "STRING":
                defaultValue = "default";
                break;
            case "NUMERIC":
                defaultValue = "0";
                break;
            case "DATE":
                defaultValue = new Date().toISOString().split("T")[0];
        }

        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            variableCriterias:
                variable.variableType !== VariableType.R
                    ? [
                          {
                              value: defaultValue,
                          },
                      ]
                    : [
                          {
                              computingRule: {
                                  id: null,
                                  computingRuleConditions: [
                                      {
                                          id: null,
                                          ifCondition: null,
                                          conditionPartList: [],
                                          orderCondition: 1,
                                          calculFormule: null,
                                          rulePartList: [
                                              {value: "1", kind: ConditionKindEnum.NUMBER, contentType: null},
                                          ],
                                          roundingMode: ComputingRuleRoundingMode.ROUND_HALF_UP,
                                          decimalCount: 2,
                                      },
                                  ],
                              },
                          },
                      ],
        };
        addVariableCondition.mutate(condition);
    };
    const onAddConditionSuccess = () => {
        if (variable !== undefined) {
            setActiveConditionIndex(variable.variableConditions.length - 2);
        }
    };

    return (
        <Segment className="card-container">
            <Accordion styled fluid>
                {variable.variableConditions
                    .sort((a, b) => a.orderCondition - b.orderCondition)
                    .map((condition, index) => (
                        <VariableConditionAccordion
                            key={index}
                            condition={condition}
                            numberOfConditions={variable.variableConditions.length}
                            version={version}
                            index={index}
                            activeIndex={activeConditionIndex}
                            setActiveIndex={setActiveConditionIndex}
                            variable={variable}
                        />
                    ))}
            </Accordion>

            {version !== undefined && version.pipDate === null && (
                <Grid style={{marginTop: 0}}>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <ModalAdd
                                isPending={addVariableCondition.isPending}
                                isSuccess={addVariableCondition.isSuccess}
                                isError={addVariableCondition.isError}
                                resetMutation={addVariableCondition.reset}
                                error={addVariableCondition.error}
                                onValidate={() => onAddConditionValidation()}
                                onSuccess={() => onAddConditionSuccess()}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="addCondition"
                                renderModalContent={() => (
                                    <Message info>
                                        {t(
                                            "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                        )}
                                    </Message>
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    );
};

export default VariableConditionsList;
