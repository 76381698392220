import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import {Container, Divider, Grid, Header, Segment, Tab} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import FormProductRetrocession from "../../../Components/Forms/FormProductRetrocession";
import FormPromotionalCampaign from "../../../Components/Forms/FormPromotionalCampaign";
import FormSplitting from "../../../Components/Forms/FormSplitting";
import FormVersion from "../../../Components/Forms/FormVersions";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SimpleList, {DataTypeColumnEnum} from "../../../Components/SimpleList/SimpleList";
import {useProductsSidebarMenu} from "../../../Hooks/useProductsSidebarMenu";
import {useGetProductDetailsQuery} from "../../../Services/Product/Queries";
import {useGetProductBehavioursQuery} from "../../../Services/ProductBehaviour/Queries";
import {
    useAddProductRetrocessionsMutation,
    useGetProductRetrocessionsQuery,
} from "../../../Services/ProductRetrocession/Queries";
import {ProductRetrocession} from "../../../Services/ProductRetrocession/Types";
import {
    useAddPromotionalCampaignMutation,
    useGetPromotionalCampaignsQuery,
} from "../../../Services/PromotionalCampagins/Queries";
import {PromotionalCampaign} from "../../../Services/PromotionalCampagins/Types";
import {useAddSplittingMutation, useGetSplittings} from "../../../Services/Splitting/Queries";
import {useAddVersionMutation, useGetVersionsQuery} from "../../../Services/Version/Queries";
import {VersionDTO} from "../../../Services/Version/Types";
import ProductDetails from "./Fragments/ProductDetails/ProductDetails";
import "./Fragments/ProductDetails/Products.css";
import VersionCard from "./Fragments/Version/VersionCard";

const ProductPage = ({...props}) => {
    const {t} = useTranslation();
    const params = useParams() as {id: string};
    const location = useLocation();

    const [activeTabIndex, setActiveIndex] = useState(location.state ? location.state.activeTabIndex : 0);

    const {data: versions, status} = useGetVersionsQuery(params.id);
    const {data: registries} = useGetProductBehavioursQuery(params.id);
    const {data: splittings} = useGetSplittings(params.id);
    const {data: productData, isSuccess, isLoading} = useGetProductDetailsQuery(params.id);

    const [workingVersion, setWorkingVersion] = useState<VersionDTO | undefined>();
    const [validVersions, setValidVersions] = useState<VersionDTO[] | undefined>();

    const menus = useProductsSidebarMenu();

    const addVersionMutation = useAddVersionMutation(params.id);
    const addVersionForm = useForm<any>();

    const [defaultValueVersionLabel, setDefaultValueVersionLabel] = useState<string>("");
    const [defaultValueEffectiveDate, setDefaultValueEffectiveDate] = useState<string>("");

    const addSplittingMutation = useAddSplittingMutation(params.id);
    const addSplittingForm = useForm<any>();

    const [defaultValueSplittingCode, setDefaultValueSplittingCode] = useState<string>("");
    const [defaultValueSplittingLabel, setDefaultValueSplittingLabel] = useState<string>("");
    const [defaultValueSplittingType, setDefaultValueSplittingType] = useState<string>("");
    const [defaultValueUnity, setDefaultValueUnity] = useState<string>("");
    const [defaultValueDuration, setDefaultValueDuration] = useState<string>("");
    const [defaultValueCoeffIncrease, setDefaultValueCoeffIncrease] = useState<number>(1);

    // Product retrocessions
    const getProductRetrocessionsQuery = useGetProductRetrocessionsQuery(params.id);
    const addProductRetrocessionMutation = useAddProductRetrocessionsMutation(params.id);
    const addProductRetrocessionForm = useForm<ProductRetrocession>();
    const [defaultValueAddProductRetrocessionForm, setDefaultValueAddProductRetrocessionForm] =
        useState<ProductRetrocession>({} as ProductRetrocession);
    const submitAddProductRetrocessionForm = (formData: ProductRetrocession) => {
        if (Object.keys(addProductRetrocessionForm.formState.errors).length === 0) {
            addProductRetrocessionMutation.mutate(formData);
        }
    };

    // Promotional campaigns
    const getPromotionalCampaignsQuery = useGetPromotionalCampaignsQuery(params.id);
    const addPromotionalCampaignMutation = useAddPromotionalCampaignMutation(params.id);
    const addPromotionalCampaignForm = useForm<PromotionalCampaign>();
    const [defaultValueAddPromotionalCampaignForm, setDefaultValueAddPromotionalCampaignForm] =
        useState<PromotionalCampaign>({} as PromotionalCampaign);
    const submitAddPromotionalCampaignForm = (formData: PromotionalCampaign) => {
        if (Object.keys(addPromotionalCampaignForm.formState.errors).length === 0) {
            addPromotionalCampaignMutation.mutate({
                ...formData,
                label: formData.label && formData.label.length > 0 ? formData.label : null,
                description: formData.description && formData.description?.length > 0 ? formData.description : null,
            });
        }
    };

    useEffect(() => {
        const findWorkVersion = () => {
            if (versions !== undefined) {
                const version = versions.find((version) => version.pipDate === null || version.pipDate === "");
                setWorkingVersion(version);
            }
        };

        const findValidVersions = () => {
            if (versions !== undefined) {
                setValidVersions(versions.filter((version) => version.pipDate !== null && version.pipDate !== ""));
            }
        };

        findValidVersions();
        findWorkVersion();
    }, [versions]);

    const haveWorkingVersion = () => {
        return workingVersion !== undefined && status !== "pending";
    };

    const isRemovableVersion = () => {
        return validVersions !== undefined && validVersions.length !== 0 && status !== "pending";
    };

    const isOnlyInvalidVersion = () => versions?.length === 1 && versions[0].pipDate === null;

    const haveWorkingAndValidatedVersion = () =>
        status === "success" &&
        versions !== undefined &&
        versions?.length > 1 &&
        versions.some((version) => version.pipDate === null);

    const submitAddVersionForm = (formData: any) => {
        if (Object.keys(addVersionForm.formState.errors).length === 0) {
            addVersionMutation.mutate(formData);
        }
    };

    const submitAddSplittingForm = (formData: any) => {
        if (Object.keys(addSplittingForm.formState.errors).length === 0) {
            addSplittingMutation.mutate(formData);
        }
    };

    const panes = [
        {
            menuItem: {key: "behaviours", content: t("behaviours")},
            render: () => (
                <Tab.Pane style={{overflowX: "auto"}}>
                    <SimpleList
                        tableName="productBehaviours"
                        columns={registryTableColumns}
                        globalData={[{key: "productId", value: params.id}]}
                        rows={registries !== undefined ? registries : []}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {key: "splittings", content: t("Splittings")},
            render: () => (
                <Tab.Pane style={{overflowX: "auto"}}>
                    <SimpleList
                        tableName="splittings"
                        columns={splittingTableColumns}
                        globalData={[{key: "productId", value: params.id}]}
                        rows={splittings !== undefined ? splittings : []}
                    />
                    <div className="bottom-button-card">
                        <ModalAdd
                            isValidationDisabled={Object.keys(addSplittingForm.formState.errors).length !== 0}
                            isPending={addSplittingMutation.isPending}
                            isSuccess={addSplittingMutation.isSuccess}
                            isError={addSplittingMutation.isError}
                            resetMutation={addSplittingMutation.reset}
                            error={addSplittingMutation.error}
                            onValidate={addSplittingForm.handleSubmit(submitAddSplittingForm)}
                            onCancel={() => {
                                setDefaultValueSplittingCode("");
                                setDefaultValueSplittingLabel("");
                                setDefaultValueSplittingType("");
                                setDefaultValueUnity("");
                                setDefaultValueDuration("");
                                setDefaultValueCoeffIncrease(1);
                            }}
                            onSuccess={() => {
                                setDefaultValueSplittingCode("");
                                setDefaultValueSplittingLabel("");
                                setDefaultValueSplittingType("");
                                setDefaultValueUnity("");
                                setDefaultValueDuration("");
                                setDefaultValueCoeffIncrease(1);
                            }}
                            objectToAdd="Splitting"
                            objectToAddContext="male"
                            openModalButtonName="add-splitting"
                            renderModalContent={() => (
                                <FormSplitting
                                    errors={addSplittingForm.formState.errors}
                                    control={addSplittingForm.control}
                                    setValue={addSplittingForm.setValue}
                                    defaultValueSplittingCode={defaultValueSplittingCode}
                                    setDefaultValueSplittingCode={setDefaultValueSplittingCode}
                                    defaultValueSplittingLabel={defaultValueSplittingLabel}
                                    setDefaultValueSplittingLabel={setDefaultValueSplittingLabel}
                                    defaultValueSplittingType={defaultValueSplittingType}
                                    setDefaultValueSplittingType={setDefaultValueSplittingType}
                                    defaultValueUnity={defaultValueUnity}
                                    setDefaultValueUnity={setDefaultValueUnity}
                                    defaultValueDuration={defaultValueDuration}
                                    setDefaultValueDuration={setDefaultValueDuration}
                                    defaultValueCoeffIncrease={defaultValueCoeffIncrease}
                                    setDefaultValueCoeffIncrease={setDefaultValueCoeffIncrease}
                                />
                            )}
                        />
                    </div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: {key: "retrocession", content: t("Retrocession")},
            render: () => (
                <Tab.Pane style={{overflowX: "auto"}}>
                    <SimpleList
                        tableName="productRetrocession"
                        columns={productRetrocessionTableColumns}
                        globalData={[{key: "productId", value: params.id}]}
                        rows={
                            getProductRetrocessionsQuery.status === "success" && getProductRetrocessionsQuery.data
                                ? getProductRetrocessionsQuery.data
                                : []
                        }
                    />
                    <div className="bottom-button-card">
                        <ModalAdd
                            isValidationDisabled={Object.keys(addProductRetrocessionForm.formState.errors).length !== 0}
                            isPending={addProductRetrocessionMutation.isPending}
                            isSuccess={addProductRetrocessionMutation.isSuccess}
                            isError={addProductRetrocessionMutation.isError}
                            resetMutation={addProductRetrocessionMutation.reset}
                            error={addProductRetrocessionMutation.error}
                            onValidate={addProductRetrocessionForm.handleSubmit(submitAddProductRetrocessionForm)}
                            onCancel={() => setDefaultValueAddProductRetrocessionForm({} as ProductRetrocession)}
                            objectToAdd="Retrocession"
                            objectToAddContext="female"
                            openModalButtonName="add-product-retrocession"
                            renderModalContent={() => (
                                <FormProductRetrocession
                                    control={addProductRetrocessionForm.control}
                                    errors={addProductRetrocessionForm.formState.errors}
                                    setValue={addProductRetrocessionForm.setValue}
                                    defaultValueAddProductRetrocessionForm={defaultValueAddProductRetrocessionForm}
                                    setDefaultValueAddProductRetrocessionForm={
                                        setDefaultValueAddProductRetrocessionForm
                                    }
                                />
                            )}
                        />
                    </div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: {key: "promotional_campaigns", content: t("Promotional campaigns")},
            render: () => (
                <Tab.Pane style={{overflowX: "auto"}}>
                    <SimpleList
                        tableName="promotional_campaigns"
                        columns={promotionalCampaignsTableColumns}
                        globalData={[{key: "productId", value: params.id}]}
                        rows={
                            getPromotionalCampaignsQuery.status === "success" ? getPromotionalCampaignsQuery.data : []
                        }
                    />
                    <div className="bottom-button-card">
                        <ModalAdd
                            isValidationDisabled={Object.keys(addPromotionalCampaignForm.formState.errors).length !== 0}
                            isPending={addPromotionalCampaignMutation.isPending}
                            isSuccess={addPromotionalCampaignMutation.isSuccess}
                            isError={addPromotionalCampaignMutation.isError}
                            resetMutation={addPromotionalCampaignMutation.reset}
                            error={addPromotionalCampaignMutation.error}
                            onValidate={addPromotionalCampaignForm.handleSubmit(submitAddPromotionalCampaignForm)}
                            onClose={() => {
                                addPromotionalCampaignForm.reset();
                                setDefaultValueAddPromotionalCampaignForm({} as PromotionalCampaign);
                            }}
                            onCancel={() => {
                                addPromotionalCampaignForm.reset();
                                setDefaultValueAddPromotionalCampaignForm({} as PromotionalCampaign);
                            }}
                            onSuccess={() => {
                                addPromotionalCampaignForm.reset();
                                setDefaultValueAddPromotionalCampaignForm({} as PromotionalCampaign);
                            }}
                            objectToAdd="Promotional campaign"
                            objectToAddContext="female"
                            openModalButtonName="add-promotional-campaign"
                            renderModalContent={() => (
                                <FormPromotionalCampaign
                                    control={addPromotionalCampaignForm.control}
                                    errors={addPromotionalCampaignForm.formState.errors}
                                    setValue={addPromotionalCampaignForm.setValue}
                                    defaultValueAddPromotionalCampaignForm={defaultValueAddPromotionalCampaignForm}
                                    setDefaultValueAddPromotionalCampaignForm={
                                        setDefaultValueAddPromotionalCampaignForm
                                    }
                                />
                            )}
                        />
                    </div>
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Container fluid>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            {isLoading && <CustomBreadcrumb sections={props.sections} />}
            {productData !== undefined && isSuccess && (
                <CustomBreadcrumb sections={props.sections} title={productData.label} />
            )}
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <ProductDetails
                                isOnlyInvalidVersion={isOnlyInvalidVersion()}
                                haveWorkingAndValidatedVersion={haveWorkingAndValidatedVersion()}
                            />
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Segment className="card-container">
                                <Tab
                                    panes={panes}
                                    activeIndex={activeTabIndex}
                                    onTabChange={(e, data) => setActiveIndex(data.activeIndex as number)}
                                />
                            </Segment>

                            <Grid columns={2} stackable>
                                <Grid.Row>
                                    <Grid.Column>
                                        {workingVersion !== undefined && (
                                            <VersionCard
                                                cardTitle={t("Working version")}
                                                version={workingVersion}
                                                isRemovableVersion={isRemovableVersion()}
                                            />
                                        )}
                                    </Grid.Column>
                                    <Grid.Column>
                                        {validVersions !== undefined && validVersions.length !== 0 && (
                                            <Segment className="card-container">
                                                <Header as="h2" dividing>
                                                    {t("Valid versions list")}
                                                </Header>
                                                <SimpleList
                                                    tableName="validatedVersions"
                                                    columns={versionTableColumns}
                                                    globalData={[]}
                                                    rows={validVersions}
                                                />
                                                {!haveWorkingVersion() && (
                                                    <div className="bottom-button-card">
                                                        <Divider />
                                                        <ModalAdd
                                                            isValidationDisabled={
                                                                Object.keys(addVersionForm.formState.errors).length !==
                                                                0
                                                            }
                                                            isPending={addVersionMutation.isPending}
                                                            isSuccess={addVersionMutation.isSuccess}
                                                            isError={addVersionMutation.isError}
                                                            resetMutation={addVersionMutation.reset}
                                                            error={addVersionMutation.error}
                                                            onValidate={addVersionForm.handleSubmit(
                                                                submitAddVersionForm
                                                            )}
                                                            onSuccess={() => {
                                                                setDefaultValueVersionLabel("");
                                                                setDefaultValueEffectiveDate("");
                                                            }}
                                                            onCancel={() => {
                                                                setDefaultValueVersionLabel("");
                                                                setDefaultValueEffectiveDate("");
                                                            }}
                                                            objectToAdd="version"
                                                            objectToAddContext="female"
                                                            openModalButtonName="add-version"
                                                            renderModalContent={() => (
                                                                <FormVersion
                                                                    errors={addVersionForm.formState.errors}
                                                                    control={addVersionForm.control}
                                                                    defaultValueVersionLabel={defaultValueVersionLabel}
                                                                    setDefaultValueVersionLabel={
                                                                        setDefaultValueVersionLabel
                                                                    }
                                                                    defaultValueEffectiveDate={
                                                                        defaultValueEffectiveDate
                                                                    }
                                                                    setDefaultValueEffectiveDate={
                                                                        setDefaultValueEffectiveDate
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                            </Segment>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default ProductPage;

const versionTableColumns = [
    {
        text: "Effective date",
        dataField: "effectiveDate",
        type: DataTypeColumnEnum.DATE,
    },
    {
        text: "Label",
        dataField: "label",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "",
        dataField: "/products/:productId/versions/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const registryTableColumns = [
    {
        text: "Code",
        dataField: "registryCode",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Name",
        dataField: "registryLabel",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Company code",
        dataField: "companyCode",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Type",
        dataField: "registryType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "Charging mode",
        dataField: "chargingMode",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "renew on deadline",
        dataField: "renewOnDeadline",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "Prorata",
        dataField: "applyProrata",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "",
        dataField: "/products/:productId/behaviours/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const splittingTableColumns = [
    {
        text: "Code",
        dataField: "code",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Label",
        dataField: "label",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Type",
        dataField: "splittingType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "By default",
        dataField: "defaultSplitting",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "",
        dataField: "/products/:productId/splittings/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const productRetrocessionTableColumns = [
    {
        text: "Business provider rank",
        dataField: "businessProviderRank",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Retrocession mode",
        dataField: "retrocessionMode",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "Base amount",
        dataField: "retrocessionType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "",
        dataField: "/products/:productId/retrocessions/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const promotionalCampaignsTableColumns = [
    {
        text: "Code",
        dataField: "code",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Start date",
        dataField: "startDate",
        type: DataTypeColumnEnum.DATETIME,
    },
    {
        text: "End date",
        dataField: "endDate",
        type: DataTypeColumnEnum.DATETIME,
    },
    {
        text: "Offer type",
        dataField: "offerType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "Application mode",
        dataField: "applicationMode",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "",
        dataField: "/products/:productId/promotional_campaigns/:id",
        type: DataTypeColumnEnum.LINK,
    },
];
