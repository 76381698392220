export type QuestionDTO = {
    id: number;
    name: string;
    valueType: QuestionValueTypeEnum;
    mandatory: boolean;
    defaultValue: string;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
    questionValues: Array<QuestionValue>;
};

export type QuestionValue = {
    id?: number;
    value: string;
    label: string | null;
}

export type QuestionAddDTO = {
    name: string;
    valueType: QuestionValueTypeEnum;
    mandatory: boolean;
    defaultValue: string;
};

export enum QuestionValueTypeEnum {
    DATE = "DATE",
    NOMBRE = "NOMBRE",
    LISTE = "LISTE",
    STRING = "STRING",
}
