import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../Components/Sidebar/Sidebar";
import { useGetSplittings } from "../../../Services/Splitting/Queries";
import SplittingDetails from "./Fragements/SplittingDetails";

export interface IParams extends Params {
    id: string;
    splittingId: string;
}

const SplittingPage = ({...props}) => {
    const {t} = useTranslation();
    const routeParams = useParams() as IParams;

    const {data: splittings, isSuccess} = useGetSplittings(routeParams.id);
    const [menus, setMenus] = useState<any>([]);

    useEffect(() => {
        if (isSuccess && splittings !== undefined) {
            const sideBarItems = new SidebarMenu(
                "",
                splittings.map(
                    (splitting) =>
                        new SidebarItem(
                            `${splitting.code}-${splitting.label}`,
                            splitting.label,
                            `/products/${routeParams.id}/splittings/${splitting.id}`
                        )
                )
            );
            setMenus(sideBarItems);
        }
    }, [props.sections, isSuccess, splittings, routeParams]);

    return (
        <Container fluid>
            <Sidebar title={t("Splittings")} menus={[menus]} />
            <CustomBreadcrumb sections={props.sections} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <SplittingDetails productId={routeParams.id} splittingId={routeParams.splittingId} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default SplittingPage;
