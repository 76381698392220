import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider, Grid, Header, Radio } from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import { useDeleteSplittingMutation } from "../../../../Services/Splitting/Queries";
import { Splitting } from "../../../../Services/Splitting/Types";

const SplittingDetailsRead: React.FC<{productId: string; splitting: Splitting; setEdit: Function}> = ({
    productId,
    splitting,
    setEdit,
}) => {
    const {t} = useTranslation();
    const navigate= useNavigate();
    const [displayUnityAndDuration, setDisplayUnityAndDuration] = useState("none");
    const deleteSplittingMutation = useDeleteSplittingMutation(productId, String(splitting.id));

    const handleDelete = () => {
        deleteSplittingMutation.mutate(splitting.id);
    };

    const onSplittingDeleteSuccess = () => {
        navigate(`/products/{productId}`);
    };

    const determineUnity = (splittingType: string) => {
        switch (splittingType) {
            case "J":
                return t("enums.unit.J");
            case "M":
                return t("enums.unit.M")
            case "A":
                return t("enums.unit.A")
        }
    }

    useEffect(() => {
        if(splitting.splittingType === "X"){
            setDisplayUnityAndDuration("block")
        }else{
            setDisplayUnityAndDuration("none")
        }
    }, [splitting]);

    return (
        <>
            <Grid columns="equal" verticalAlign="middle">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Code")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{splitting.code}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Label")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{splitting.label}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Splitting type")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{t(`enums.${splitting.splittingType}`)}</Grid.Column>
                </Grid.Row>
            </Grid>
            <div style={{marginTop:"1rem", display:displayUnityAndDuration}}>
                <Header>
                    {t("Duration")}
                </Header>

                <Grid columns="equal" verticalAlign="middle">
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <strong>{t("Unity")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={8}>{determineUnity(splitting.unit)}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <strong>{t("Duration")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={8}>{splitting.duration}</Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            
            <Grid columns="equal" verticalAlign="middle" style={{marginTop:"1rem"}}>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Coefficient increase")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{splitting.increaseCoef}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <strong>{t("Default splitting")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Radio aria-label={"defaultSplitting"} toggle readOnly checked={splitting.defaultSplitting} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className={"bottom-button-card"} style={{bottom: 0}}>
                <Divider />
                <Button secondary name="return" as={Link} to={`/products/${productId}`}>
                    {t("return")}
                </Button>
                <Button primary name="edit-splitting" onClick={() => setEdit(true)}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deleteSplitting"
                    isSuccess={deleteSplittingMutation.isSuccess}
                    isPending={deleteSplittingMutation.isPending}
                    isError={deleteSplittingMutation.isError}
                    error={deleteSplittingMutation.error}
                    resetMutation={deleteSplittingMutation.reset}
                    onSuccess={onSplittingDeleteSuccess}
                    onValidate={handleDelete}
                    objectToDelete="Splitting"
                    objectType="male"
                />

            </div>
        </>
    );
};

export default SplittingDetailsRead;
