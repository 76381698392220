import React, {useState} from "react";
import {useCookies} from "react-cookie";
import queryClient from "../../Config/queryClient";

type AuthState = {
    loading: boolean;
    token: string | undefined;
    user: UserPayload | undefined;
};

export type UserContextProps = AuthState & {
    updateAuth: (token: string) => Promise<void>;
    logout: () => Promise<void>;
};

export type UserPayload = {
    sub: string;
    exp: number;
    societeUser: string;
    username: string;
    authorities: string[];
};

export const AuthContext = React.createContext<Partial<UserContextProps>>({});

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [, setCookie, removeCookie] = useCookies(["token"]);
    const [state, setState] = useState<AuthState>({
        loading: true,
        token: undefined,
        user: undefined,
    });

    const memoizedValue = React.useMemo(() => {
        const updateAuth = async (token: string) => {
            if (token !== state.token) {
                const parsedJWT = parseJwt(token);
                const environnementName = parsedJWT.environmentName;

                setCookie("token", token, {
                    sameSite: true,
                    secure: environnementName !== undefined && environnementName.toLocaleLowerCase().includes("prod"),
                    path: "/",
                    maxAge: 60 * 60 * 24,
                });

                setState({
                    token,
                    loading: false,
                    user: parsedJWT,
                });
            }
        };

        const logout = async () => {
            removeCookie("token");
            setState({
                loading: true,
                token: undefined,
                user: undefined,
            });
            await queryClient.invalidateQueries();
        };

        return {...state, updateAuth, logout};
    }, [state, setCookie, removeCookie]);

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

const parseJwt = (
    token: string
): {
    sub: string;
    environmentName: string;
    scope: string;
    societeUser: string;
    exp: number;
    authorities: string[];
    username: string;
} => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
    );

    return JSON.parse(jsonPayload);
};
