import axios, { AxiosError } from "axios";
import axiosInstance from "../ProductEngine.instance";
import { buildCustomError } from "../RequestUtils";
import {
    ProductBehaviorDTO,
    ProductBehaviorWithRegistryDTO,
    ProductRegistryJoinDTO, ProductRegistryTaxJoin
} from "./Types";

export const getProductBehaviour = async (
    productId: string,
    registryJoinId: string
): Promise<ProductBehaviorWithRegistryDTO> => {
    try {
        const response = await axiosInstance.get<ProductBehaviorWithRegistryDTO>(
            `/products/${productId}/registries/${registryJoinId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getProductBehaviourByRegistryId = async (productId: string, registryId: string) => {
    try {
        const response = await axiosInstance.get<ProductBehaviorDTO>(
            `/products/${productId}/registries/_byRegistryId`,
            {
                params: {registryId: registryId},
            }
        );

        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getProductBehaviours = async (productId: string): Promise<Array<ProductRegistryJoinDTO>> => {
    try {
        const response = await axiosInstance.get<Array<ProductRegistryJoinDTO>>(`/products/${productId}/registries`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateProductBehaviour = async (
    productId: string,
    registryId: string,
    editedBehaviour: ProductBehaviorDTO
): Promise<ProductBehaviorDTO> => {
    try {
        const response = await axiosInstance.put<ProductBehaviorDTO>(
            `/products/${productId}/registries/${registryId}`,
            editedBehaviour
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

/* ----------- ProductRegistryTaxJoin ----------- */
export const updateProductRegistryTax = async (
    productId: string,
    registryJoinId: string,
    registryTaxJoinId: string,
    productRegistryTaxJoin: ProductRegistryTaxJoin
): Promise<ProductRegistryTaxJoin> => {
    try {
        const response = await axiosInstance.put<ProductRegistryTaxJoin>(
            `/products/${productId}/registries/${registryJoinId}/taxes/${registryTaxJoinId}`,
            productRegistryTaxJoin
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addProductRegistryTax = async (
    productId: string,
    registryJoinId: string,
    productRegistryTaxJoin: ProductRegistryTaxJoin
): Promise<ProductRegistryTaxJoin> => {
    try {
        const response = await axiosInstance.post<ProductRegistryTaxJoin>(
            `/products/${productId}/registries/${registryJoinId}/taxes`,
            productRegistryTaxJoin
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteProductRegistryTax = async (
    productId: string,
    registryJoinId: string,
    registryTaxJoinId: string
): Promise<void> => {
    try {
        const response = await axiosInstance.delete(
            `/products/${productId}/registries/${registryJoinId}/taxes/${registryTaxJoinId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
