import {UseQueryResult, useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addPricingCriteria,
    addPricingCriteriaCondition,
    deletePricingCriteria,
    deletePricingCriteriaCondition,
    duplicatePricingCriteria,
    getPricingCriteria,
    getPricingCriterias,
    updateGlobalInfosPricingCriteria,
    updatePricingCriteria,
    updatePricingCriteriaCondition,
} from "./AxiosRequests";
import {
    NewPricingCriteriaConditionDTO,
    NewPricingCriteriaDTO,
    PricingCriteriaConditionDTO,
    PricingCriteriaItem,
    PricingCriterias,
    UpdateAssignmentPricingCriteriaConditionDTO,
} from "./Types";

export const useGetPricingCriteriasList = (
    versionId: string,
    enabled: boolean
): UseQueryResult<Array<PricingCriterias>, Error> => {
    return useQuery({
        queryKey: ["getPricingCriterias", versionId],
        queryFn: () => getPricingCriterias(versionId),
        enabled,
    });
};

export const useAddPricingCriteriaMutation = (
    productId: string,
    versionId: string
): UseMutationResult<PricingCriteriaItem, CustomError, NewPricingCriteriaDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<PricingCriteriaItem, CustomError, NewPricingCriteriaDTO, Error>({
        mutationFn: (pricingCriteria: NewPricingCriteriaDTO) => addPricingCriteria(versionId, pricingCriteria),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
            navigate(`/products/${productId}/versions/${versionId}/pricing-criterias/${result.id}`);
        },
    });
};

export const useGetPricingCriteria = (versionId: string, pricingCriteriaId: string): UseQueryResult<PricingCriteriaItem, Error> => {
    return useQuery({
        queryKey: ["getPricingCriteria", {versionId, pricingCriteriaId}],
        queryFn: () => getPricingCriteria(versionId, pricingCriteriaId),
    });
};

export const useDeletePricingCriteria = (
    productId: string,
    versionId: string,
    pricingCriteriaId: number
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deletePricingCriteria(versionId, pricingCriteriaId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
            queryClient.removeQueries({queryKey: ["getPricingCriteria", {versionId, pricingCriteriaId}]});
            navigate(`/products/${productId}/versions/${versionId}`, {state: {activeTabIndex: 2}});
        },
    });
};

export const useUpdateGlobalInfosPricingCriteriaMutation = (
    versionId: string,
    pricingCriteriaId: string
): UseMutationResult<PricingCriteriaItem, CustomError, PricingCriteriaItem, Error> => {
    return useMutation<PricingCriteriaItem, CustomError, PricingCriteriaItem, Error>({
        mutationFn: (pricingCriteriaItem: PricingCriteriaItem) =>
            updateGlobalInfosPricingCriteria(versionId, pricingCriteriaId, pricingCriteriaItem),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId, pricingCriteriaId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
        },
    });
};

export const useUpdatePricingCriteriaMutation = (
    versionId: string,
    pricingCriteriaId: string
): UseMutationResult<PricingCriteriaItem, CustomError, PricingCriteriaItem, Error> => {
    return useMutation<PricingCriteriaItem, CustomError, PricingCriteriaItem, Error>({
        mutationFn: (pricingCriteriaItem: PricingCriteriaItem) =>
            updatePricingCriteria(versionId, pricingCriteriaId, pricingCriteriaItem),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId, pricingCriteriaId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
        },
    });
};

export const useUpdatePricingCriteriaConditionMutation = (
    versionId: string,
    pricingCriteriaId: string,
    conditionId: number
): UseMutationResult<PricingCriteriaConditionDTO, CustomError, UpdateAssignmentPricingCriteriaConditionDTO, Error> => {
    return useMutation<PricingCriteriaConditionDTO, CustomError, UpdateAssignmentPricingCriteriaConditionDTO, Error>({
        mutationFn: (condition: UpdateAssignmentPricingCriteriaConditionDTO) =>
            updatePricingCriteriaCondition(pricingCriteriaId, conditionId, condition),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId, pricingCriteriaId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
        },
    });
};

export const useDeletePricingCriteriaCondition = (
    versionId: string,
    pricingCriteriaId: string
): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (conditionId: number) => deletePricingCriteriaCondition(pricingCriteriaId, conditionId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId, pricingCriteriaId}]});
        },
    });
};

export const useAddPricingCriteriaConditionMutation = (
    versionId: string,
    pricingCriteriaId: string
): UseMutationResult<PricingCriteriaConditionDTO, CustomError, NewPricingCriteriaConditionDTO, Error> => {
    return useMutation<PricingCriteriaConditionDTO, CustomError, NewPricingCriteriaConditionDTO, Error>({
        mutationFn: (pricingCriteriaCondition: NewPricingCriteriaConditionDTO) =>
            addPricingCriteriaCondition(pricingCriteriaId, pricingCriteriaCondition),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId, pricingCriteriaId}]});
        },
    });
};

export const useDuplicatePricingCriteriaMutation = (
    productId: string,
    versionId: string,
    pricingCriteriaId: string
): UseMutationResult<PricingCriteriaItem, CustomError, {name: string; description: string}, Error> => {
    const navigate = useNavigate();
    return useMutation<PricingCriteriaItem, CustomError, {name: string; description: string}, Error>({
        mutationFn: (data: {name: string; description: string}) =>
            duplicatePricingCriteria(versionId, pricingCriteriaId, data.name, data.description),
        onSuccess: async (result) => {
            navigate(`/products/${productId}/versions/${versionId}/pricing-criterias/${result.id}`);
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
        },
    });
};

