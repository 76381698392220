import {useEffect, useState} from "react";
import {Control, Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Card, Form, Grid, Label} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {ProductRetrocession, RetrocessionMode, RetrocessionType} from "../../Services/ProductRetrocession/Types";
import {useGetVariablesList} from "../../Services/Variables/Queries";
import {Variable, VariableType} from "../../Services/Variables/Types";
import {useGetVersionsQuery} from "../../Services/Version/Queries";
import {sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../Utils/SortUtils";
import EntityPicker from "../Modals/EntityPicker";

type FormProductRetrocessionPropsType = {
    control: Control<ProductRetrocession>;
    errors: any;
    setValue: any;
    defaultValueAddProductRetrocessionForm: ProductRetrocession;
    setDefaultValueAddProductRetrocessionForm: React.Dispatch<React.SetStateAction<ProductRetrocession>>;
};

const FormProductRetrocession = ({
    control,
    errors,
    setValue,
    defaultValueAddProductRetrocessionForm,
    setDefaultValueAddProductRetrocessionForm,
}: FormProductRetrocessionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as {id: string};

    // Options
    const retrocessionModeOptions = Object.values(RetrocessionMode).map((retrocessionMode: RetrocessionMode) => {
        return {
            key: retrocessionMode.toString(),
            text: t("enums.RetrocessionMode." + retrocessionMode),
            value: retrocessionMode,
        };
    });

    const retrocessionTypeOptions = Object.values(RetrocessionType).map((retrocessionType: RetrocessionType) => {
        return {
            key: retrocessionType.toString(),
            text: t("enums.RetrocessionType." + retrocessionType),
            value: retrocessionType,
        };
    });

    // Entity Picker
    const [variableNameBaseRateEntityPickerIsOpen, setVariableNameBaseRateEntityPickerIsOpen] = useState<boolean>(
        false
    );
    const [
        variableNameCoveragesBaseEntityPickerIsOpen,
        setVariableNameCoveragesBaseEntityPickerIsOpen,
    ] = useState<boolean>(false);

    // Version
    const [versionId, setVersionId] = useState("");
    const versionList = useGetVersionsQuery(params.id);
    useEffect(() => {
        if (versionList.isSuccess) {
            const versionListSorted = versionList.data.sort(
                (version1, version2) =>
                    new Date(version2.effectiveDate).getTime() - new Date(version1.effectiveDate).getTime()
            );
            setVersionId(String(versionListSorted[0].id));
        }
    }, [versionList]);

    const [pickedRetrocessionMode, setPickedRetrocessionMode] = useState<RetrocessionMode>(
        defaultValueAddProductRetrocessionForm.retrocessionMode
    );

    return (
        <Form>
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            name={"businessProviderRank"}
                            defaultValue={defaultValueAddProductRetrocessionForm?.businessProviderRank}
                            rules={{required: true, min: 1, max: 5}}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    autoFocus
                                    required
                                    fluid
                                    name={name}
                                    type="number"
                                    min="1"
                                    max="5"
                                    label={t("Business provider rank")}
                                    placeholder={t("Business provider rank")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddProductRetrocessionForm({
                                            ...defaultValueAddProductRetrocessionForm,
                                            businessProviderRank: Number(value),
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                        var newValue: number = Number(e.target.value);
                                        if (newValue > 5) newValue = 5;
                                        else if (newValue < 1) newValue = 1;
                                        setValue(name, newValue);
                                        setDefaultValueAddProductRetrocessionForm({
                                            ...defaultValueAddProductRetrocessionForm,
                                            businessProviderRank: newValue,
                                        });
                                        onBlur();
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name, undefined, undefined, undefined, 1, 5)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"retrocessionMode"}
                            rules={{required: true}}
                            defaultValue={defaultValueAddProductRetrocessionForm?.retrocessionMode}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Select
                                    required
                                    fluid
                                    name={name}
                                    label={t("Retrocession mode")}
                                    placeholder={t("Retrocession mode")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddProductRetrocessionForm({
                                            ...defaultValueAddProductRetrocessionForm,
                                            retrocessionMode: value as RetrocessionMode,
                                        });
                                        onChange(value);
                                        setPickedRetrocessionMode(value as RetrocessionMode);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={retrocessionModeOptions}
                                />
                            )}
                        />

                        {pickedRetrocessionMode !== undefined && pickedRetrocessionMode === RetrocessionMode.PRODUIT && (
                            <>
                                <Controller
                                    control={control}
                                    name={"retrocessionType"}
                                    rules={{required: true}}
                                    defaultValue={defaultValueAddProductRetrocessionForm?.retrocessionType}
                                    render={({field: {onChange, value, name}}) => (
                                        <Form.Select
                                            required
                                            fluid
                                            name={name}
                                            label={t("Base amount")}
                                            placeholder={t("Base amount")}
                                            onChange={(_, {value}) => {
                                                setDefaultValueAddProductRetrocessionForm({
                                                    ...defaultValueAddProductRetrocessionForm,
                                                    retrocessionType: value as RetrocessionType,
                                                });
                                                onChange(value);
                                            }}
                                            value={value}
                                            error={getErrorMessage(t, errors, name)}
                                            options={retrocessionTypeOptions}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name={"variableNameBaseRate"}
                                    rules={{required: true}}
                                    defaultValue={defaultValueAddProductRetrocessionForm?.variableNameBaseRate}
                                    render={({field: {onChange, value, name}}) => (
                                        <>
                                            {variableNameBaseRateEntityPickerIsOpen ? (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <Card.Content>
                                                            <Card.Header>{variable.name}</Card.Header>
                                                            <Card.Description style={{marginTop: "10px"}}>
                                                                <Label name="valueTypeCard">
                                                                    {t("enums.VariableValueType." + variable.valueType)}
                                                                </Label>
                                                                <p style={{marginTop: "12px"}}>
                                                                    {variable.description}
                                                                </p>
                                                            </Card.Description>
                                                        </Card.Content>
                                                    )}
                                                    filterEntity={() => true}
                                                    filterBySearch={(
                                                        variableToFilter: Variable,
                                                        search: string
                                                    ): boolean => {
                                                        return (
                                                            variableToFilter.name
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            variableToFilter.valueType
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            search === ""
                                                        );
                                                    }}
                                                    onClickOnEntity={(variable: Variable) => {
                                                        setDefaultValueAddProductRetrocessionForm({
                                                            ...defaultValueAddProductRetrocessionForm,
                                                            variableNameBaseRate: variable.name,
                                                        });
                                                        onChange(variable.name);
                                                        setVariableNameBaseRateEntityPickerIsOpen(false);
                                                    }}
                                                    onCancel={() => {
                                                        setVariableNameBaseRateEntityPickerIsOpen(false);
                                                    }}
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: any, b: any) =>
                                                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                                                    }
                                                />
                                            ) : (
                                                <div className="required field" data-cy="variableNameBaseRate">
                                                    <label>{t("Variable name base rate")}</label>
                                                    <div className="ui fluid action input" style={{height: "38px"}}>
                                                        <button
                                                            className="ui icon right labeled button fluid"
                                                            style={
                                                                errors[name]
                                                                    ? {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff6f6",
                                                                          border: "1px solid #e0b4b4",
                                                                          color: "#9f3a38",
                                                                      }
                                                                    : {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff",
                                                                          border: "1px solid rgba(34,36,38,.15)",
                                                                      }
                                                            }
                                                            onClick={() => {
                                                                setVariableNameBaseRateEntityPickerIsOpen(true);
                                                            }}>
                                                            {value !== undefined && value !== "" ? (
                                                                <p style={{color: "#000000C0", fontWeight: 400}}>
                                                                    {value}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    style={
                                                                        errors[name]
                                                                            ? {
                                                                                  color: "#9f3a38",
                                                                                  fontWeight: 400,
                                                                              }
                                                                            : {
                                                                                  color: "rgba(191,191,191,.87)",
                                                                                  fontWeight: 400,
                                                                              }
                                                                    }>
                                                                    {t("Choose", {
                                                                        name: "variable",
                                                                        context: "female",
                                                                    })}
                                                                    ...
                                                                </p>
                                                            )}
                                                        </button>
                                                    </div>
                                                    {errors[name] && (
                                                        <div
                                                            className="ui pointing above prompt label"
                                                            role="alert"
                                                            aria-atomic="true">
                                                            {t("this field is required")}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name={"variableNameCoveragesBase"}
                                    rules={{required: true}}
                                    defaultValue={defaultValueAddProductRetrocessionForm?.variableNameCoveragesBase}
                                    render={({field: {onChange, value, name}}) => (
                                        <>
                                            {variableNameCoveragesBaseEntityPickerIsOpen ? (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <Card.Content>
                                                            <Card.Header>{variable.name}</Card.Header>
                                                            <Card.Description style={{marginTop: "10px"}}>
                                                                <Label name="valueTypeCard">
                                                                    {t("enums.VariableValueType." + variable.valueType)}
                                                                </Label>
                                                                <p style={{marginTop: "12px"}}>
                                                                    {variable.description}
                                                                </p>
                                                            </Card.Description>
                                                        </Card.Content>
                                                    )}
                                                    filterEntity={() => true}
                                                    filterBySearch={(
                                                        variableToFilter: Variable,
                                                        search: string
                                                    ): boolean => {
                                                        return (
                                                            variableToFilter.name
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            variableToFilter.valueType
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            search === ""
                                                        );
                                                    }}
                                                    onClickOnEntity={(variable: Variable) => {
                                                        setDefaultValueAddProductRetrocessionForm({
                                                            ...defaultValueAddProductRetrocessionForm,
                                                            variableNameCoveragesBase: variable.name,
                                                        });
                                                        onChange(variable.name);
                                                        setVariableNameCoveragesBaseEntityPickerIsOpen(false);
                                                    }}
                                                    onCancel={() => {
                                                        setVariableNameCoveragesBaseEntityPickerIsOpen(false);
                                                    }}
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: any, b: any) =>
                                                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                                                    }
                                                />
                                            ) : (
                                                <div className="required field" data-cy="variableNameCoveragesBase">
                                                    <label>{t("Variable name coverages base")}</label>
                                                    <div className="ui fluid action input" style={{height: "38px"}}>
                                                        <button
                                                            className="ui icon right labeled button fluid"
                                                            style={
                                                                errors[name]
                                                                    ? {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff6f6",
                                                                          border: "1px solid #e0b4b4",
                                                                          color: "#9f3a38",
                                                                      }
                                                                    : {
                                                                          borderRadius: ".28571429rem",
                                                                          background: "#fff",
                                                                          border: "1px solid rgba(34,36,38,.15)",
                                                                      }
                                                            }
                                                            onClick={() => {
                                                                setVariableNameCoveragesBaseEntityPickerIsOpen(true);
                                                            }}>
                                                            {value !== undefined && value !== "" ? (
                                                                <p style={{color: "#000000C0", fontWeight: 400}}>
                                                                    {value}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    style={
                                                                        errors[name]
                                                                            ? {
                                                                                  color: "#9f3a38",
                                                                                  fontWeight: 400,
                                                                              }
                                                                            : {
                                                                                  color: "rgba(191,191,191,.87)",
                                                                                  fontWeight: 400,
                                                                              }
                                                                    }>
                                                                    {t("Choose", {
                                                                        name: "variable",
                                                                        context: "female",
                                                                    })}
                                                                    ...
                                                                </p>
                                                            )}
                                                        </button>
                                                    </div>
                                                    {errors[name] && (
                                                        <div
                                                            className="ui pointing above prompt label"
                                                            role="alert"
                                                            aria-atomic="true">
                                                            {t("this field is required")}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormProductRetrocession;
