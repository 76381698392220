import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, Params, useParams} from "react-router-dom";
import {Card, Grid, Input, Label, Menu, Message, Segment, SemanticCOLORS, Tab} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import SaveQuoteLabel from "../../../../../Components/Labels/SaveQuoteLabel";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetPricingCriteriasList} from "../../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItemType, PricingCriterias} from "../../../../../Services/PricingCriterias/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import {
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsType,
} from "../../../../../Utils/SortUtils";
import ModalAddPricingCriteria from "../../../PricingCriterias/Fragments/ModalAddPricingCriteria";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PricingCriteriasListPropsType = {
    version: VersionDTO | undefined;
};

const PricingCriteriasList = ({version}: PricingCriteriasListPropsType) => {
    const {t} = useTranslation();
    const userContext = useContext(AuthContext);
    const {id: productId, versionId} = useParams() as IParams;

    const getPricingCriteriasList = useGetPricingCriteriasList(versionId, true);
    const [sortMethod, setSortMethod] = useState<(a: any, b: any) => number>(() => (a: any, b: any) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    const sortOptions = [...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate, ...sortOptionsType];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (name: string, type: PricingCriteriaItemType) => {
        return (
            name.toLowerCase().includes(search.toLowerCase()) ||
            type.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const getColorOfValueTypeLabel = (questionValueType: PricingCriteriaItemType): SemanticCOLORS | undefined => {
        switch (questionValueType) {
            case PricingCriteriaItemType.STRING:
                return "blue";
            case PricingCriteriaItemType.DATE:
                return "green";
            case PricingCriteriaItemType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane style={{overflow: "auto"}}>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>
                                <Menu.Item>
                                    <Input
                                        name="searchPricingCriteria"
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "male",
                                            entity: t("Criteria").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>
                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddPricingCriteria />
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getPricingCriteriasList.isSuccess &&
                            getPricingCriteriasList.data !== undefined &&
                            getPricingCriteriasList.data.length === 0 && (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No pricing criteria")}
                                        content={t("There is no pricing criteria for this version")}
                                    />
                                </Segment>
                            )}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getPricingCriteriasList.isSuccess &&
                                getPricingCriteriasList.data !== undefined &&
                                getPricingCriteriasList.data.length !== 0 &&
                                getPricingCriteriasList.data
                                    .slice()
                                    .filter((pricingCriteria: PricingCriterias) =>
                                        hiddenBySearch(pricingCriteria.name, pricingCriteria.type)
                                    )
                                    .sort(sortMethod)
                                    .map((pricingCriteria: PricingCriterias, index: number) => (
                                        <Card
                                            key={`pricingCriteria-${index}`}
                                            as={Link}
                                            to={`/products/${productId}/versions/${versionId}/pricing-criterias/${pricingCriteria.id}`}>
                                            <Card.Content>
                                                <Card.Header>
                                                    <TextOverflow value={pricingCriteria.name} />
                                                </Card.Header>
                                                <Card.Description style={{marginTop: "10px", display: "flex", justifyContent: "space-between"}}>
                                                    <Label
                                                        color={getColorOfValueTypeLabel(pricingCriteria.type)}
                                                        data-cy="pricingCriteria-card-type">
                                                        {t("enums.PricingCriteriaType." + pricingCriteria.type)}
                                                    </Label>
                                                    {pricingCriteria.saveQuote && (
                                                        <SaveQuoteLabel />
                                                    )}
                                                </Card.Description>

                                                {!pricingCriteria.valid && (
                                                    <Label corner="right" color="red" size="tiny" icon="warning sign" />
                                                )}
                                            </Card.Content>

                                            {userContext.user !== undefined && (
                                                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                                                    <span>
                                                        {displayAuditLog(
                                                            {
                                                                entityContext: "male",
                                                                createdDate: pricingCriteria.createdDate,
                                                                modifiedDate: pricingCriteria.modifiedDate,
                                                                createdBy: pricingCriteria.createdBy,
                                                                modifiedBy: pricingCriteria.modifiedBy,
                                                                createdOnVersionId: pricingCriteria.createdOnVersionId,
                                                            },
                                                            userContext.user,
                                                            versionId,
                                                            t
                                                        )}
                                                    </span>
                                                </Card.Content>
                                            )}
                                        </Card>
                                    ))}
                        </div>

                        {getPricingCriteriasList.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default PricingCriteriasList;
