import {Button, Checkbox, Divider, Grid} from "semantic-ui-react";
import {Company} from "../../../Services/Company/Types";
import {useTranslation} from "react-i18next";
import {handleNullableProperty} from "../../../Utils/TextUtils";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import {useDeleteCompanyMutation} from "../../../Services/Company/Queries";

type CompanyDetailsReadProps = {
    companyId: string;
    company: Company;
    setEdit: Function;
};

const CompanyDetailsRead: React.FC<CompanyDetailsReadProps> = ({companyId, company, setEdit}) => {
    const {t} = useTranslation();

    // Delete company
    const deleteCompanyMutation = useDeleteCompanyMutation(companyId);

    return (
        <>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="label">
                        <strong>{t("Label")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="label_value">{company.label}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="prorateMode">
                        <strong>{t("prorateMode")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="prorateMode_value">{company.prorateMode}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="prorateDaysCountingMode">
                        <strong>{t("prorateDaysCountingMode")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="prorateDaysCountingMode_value">{company.prorateDaysCountingMode}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="internalNovanetUrl">
                        <strong>{t("internalNovanetUrl")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="internalNovanetUrl_value">{handleNullableProperty(company.internalNovanetUrl, t)}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="webserviceLogin">
                        <strong>{t("webserviceLogin")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="webserviceLogin_value">********</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="webservicePassword">
                        <strong>{t("webservicePassword")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="webservicePassword_value">********</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="commissionMode">
                        <strong>{t("commissionMode")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="commissionMode_value">{company.commissionMode}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="consoleURL">
                        <strong>{t("consoleURL")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="consoleURL_value">{handleNullableProperty(company.consoleURL, t)}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="environmentName">
                        <strong>{t("environmentName")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="environmentName_value">{handleNullableProperty(company.environmentName, t)}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="retrocessionSettingsActive">
                        <strong>{t("retrocessionSettingsActive")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy="retrocessionSettingsActive_value">
                        <Checkbox toggle checked={company.retrocessionSettingsActive} disabled />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider />
            <div className="bottom-button-card">
                <Button primary name="edit" onClick={() => setEdit(true)}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    isSuccess={deleteCompanyMutation.isSuccess}
                    isPending={deleteCompanyMutation.isPending}
                    isError={deleteCompanyMutation.isError}
                    error={deleteCompanyMutation.error}
                    resetMutation={deleteCompanyMutation.reset}
                    objectToDelete="Company"
                    objectType="female"
                    openModalButtonName="delete-company"
                    onValidate={() => deleteCompanyMutation.mutate()}
                />
            </div>
        </>
    );
};

export default CompanyDetailsRead;
