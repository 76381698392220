import React from "react";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {Segment} from "semantic-ui-react";
import Validator from 'validatorjs';
import fr from 'validatorjs/src/lang/fr';
import CustomBreadcrumb from "./Components/CustomBreadcrumb/CustomBreadcrumb";
import Footer from "./Components/Footer/Footer";
import {DefaultNavbar, Navbar} from "./Components/Navbar/Navbar";
import {BreadcrumbsInjectorWrapper} from "./Components/Routes/BreadcrumbsInjectorWrapper";
import ProtectedRoutes from "./Components/Routes/ProtectedRoutes";
import i18n from "./Config/i18n";
import {useGetFilteredRoutes} from "./Hooks/useGetFilteredRoutes";
import ActivateAccountPage from "./Pages/Global/ActivateAccount/ActivateAccountPage";
import ResetPasswordPage from "./Pages/Global/ResetPassword/ResetPasswordPage";
import LoginPage from "./Pages/Global/Login/LoginPage";

function App() {
    Validator.useLang(i18n.language);
    Validator.setMessages('fr', fr);

    return (
        <Router>
            <Segment secondary style={{minHeight: "100vh", margin: 0, padding: 0}}>
                <Routes>
                    <Route element={<ProtectedRoutes />}>
                        {useGetFilteredRoutes().map((route, _, filteredRoutes) => {
                            return (
                                <Route
                                    key={route.name}
                                    path={route.path}
                                    element={
                                        <React.Fragment>
                                            <Navbar />
                                            <React.Suspense fallback={"Loading..."}>
                                                <BreadcrumbsInjectorWrapper routes={filteredRoutes}>
                                                    {route.customBreadcrumb ? (
                                                        <route.element />
                                                    ) : (
                                                        <CustomBreadcrumb>
                                                            <route.element />
                                                        </CustomBreadcrumb>
                                                    )}
                                                </BreadcrumbsInjectorWrapper>
                                            </React.Suspense>
                                        </React.Fragment>
                                    }
                                />
                            );
                        })}
                    </Route>
                    <Route
                        path="/activate_account"
                        element={
                            <React.Fragment>
                                <DefaultNavbar />
                                <ActivateAccountPage />
                                <Footer />
                            </React.Fragment>
                        }
                    />
                    <Route
                        path="/reset_password"
                        element={
                            <React.Fragment>
                                <DefaultNavbar />
                                <ResetPasswordPage />
                                <Footer />
                            </React.Fragment>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <React.Fragment>
                                <DefaultNavbar />
                                <LoginPage />
                                <Footer />
                            </React.Fragment>
                        }
                    />
                </Routes>
            </Segment>
        </Router>
    );
}

export default App;
