import {Outlet, Navigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {useCookies} from "react-cookie";
import {AuthContext} from "../AuthProvider/AuthProvider";

const ProtectedRoutes = () => {
    const [cookies] = useCookies(["token"]);
    const {user, updateAuth} = useContext(AuthContext);

    useEffect(() => {
        const refreshUserObject = () => {
            if (updateAuth !== undefined && user === undefined && cookies.token !== undefined) {
                updateAuth(cookies.token);
            }
        };
        refreshUserObject();
    }, [cookies.token, updateAuth, user]);

    return cookies.token !== undefined && cookies.token.length > 1 ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
