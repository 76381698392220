import React, {useEffect, useState} from "react";
import {Icon, Pagination, Table} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

type SimpleListProps = {
    tableName?: string;
    columns: Array<ColumnSimpleList>;
    rows: Array<any>;
    globalData: Array<{key: string; value: any}>;
    translationNameSpace?: string;
};

export type ColumnSimpleList = {
    dataField: string;
    text: string;
    type: DataTypeColumnEnum;
};

export enum DataTypeColumnEnum {
    STRING = "string",
    DATE = "date",
    DATETIME = "datetime",
    LINK = "link",
    BOOLEAN = "boolean",
    ENUM = "enum",
    LIST = "list",
}

type ConfigType = {
    column: null | string;
    direction: undefined | "descending" | "ascending";
    data: any;
};

const SimpleList: React.FC<SimpleListProps> = ({...props}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    let [activePage, setActivePage] = useState<number>(1);
    let [totalPage, setTotalPage] = useState<number>(0);
    const pageLimit = 10;

    const originalColumns = props.columns;

    const [config, setConfig] = useState<ConfigType>({
        column: null,
        direction: undefined,
        data: [...props.rows],
    });

    useEffect(() => {
        let rows = props.rows;
        if (rows.length > pageLimit) {
            const trimStart = (activePage - 1) * pageLimit;
            const trimEnd = trimStart + pageLimit;
            rows = rows.slice(trimStart, trimEnd);
            setTotalPage(Math.ceil(props.rows.length / pageLimit));
        }
        setConfig({
            ...config,
            data: [...rows],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rows]);

    const formatCellValue = (type: DataTypeColumnEnum, value: any) => {
        switch (type) {
            case "date": {
                if (value === null || value === undefined) return t("Not specified");

                return t("format_date", {value: value}) === "Invalid Date" ? value : t("format_date", {value: value});
            }
            case "datetime": {
                if (value === null || value === undefined) return t("Not specified");

                return t("format_datetime", {value: value}) === "Invalid Date"
                    ? value
                    : t("format_datetime", {value: value});
            }
            case "boolean": {
                return (
                    <div style={{textAlign: "center"}}>
                        {value ? (
                            <Icon name="check" data-cy="boolean-icon" />
                        ) : (
                            <Icon name="close" data-cy="boolean-icon" />
                        )}
                    </div>
                );
            }
            case "enum": {
                return value !== null ? handleTranslationNamespace(`enums.${value}`) : t("Not specified");
            }
            case "list": {
                return value.join(", ");
            }
            default: {
                return value;
            }
        }
    };

    const handleTranslationNamespace = (key: string) =>
        t(`${props.translationNameSpace !== undefined ? `${props.translationNameSpace}:` : ""}${key}`);

    const buildLink = (row: any) => {
        const linkColumn = props.columns.find((column) => column.type === DataTypeColumnEnum.LINK);
        if (linkColumn !== undefined) {
            let link = linkColumn.dataField;
            linkColumn.dataField
                .split("/")
                .filter((placeholder) => placeholder.startsWith(":"))
                .map((placeholder) => ({
                    key: placeholder,
                    value:
                        row[placeholder.substring(1)] ||
                        props.globalData.find((data) => data.key === placeholder.substring(1))?.value,
                }))
                .map((placeholder) => (link = link?.replace(placeholder.key, placeholder.value)));
            return link;
        }
        return "";
    };

    const handleClick = (column: ColumnSimpleList) => () => {
        if (column.dataField === config.column) {
            setConfig({
                ...config,
                data: config.data.reverse(),
                direction: config.direction === "ascending" ? "descending" : "ascending",
            });
        } else {
            props.columns = originalColumns;
            setConfig({
                ...config,
                column: column.dataField,
                data: sortByColumn(config.data, column),
                direction: "ascending",
            });
        }
    };

    const sortByColumn = (array: any[], column: ColumnSimpleList) => {
        return array.sort((a: any, b: any) => {
            if (a[column.dataField] === null || b[column.dataField] === null) {
                return a[column.dataField] === null ? (b[column.dataField] === null ? 0 : -1) : 1;
            }
            if (a[column.dataField] !== null && b[column.dataField] !== null) {
                if (column.type === DataTypeColumnEnum.BOOLEAN)
                    return a[column.dataField] === b[column.dataField] ? 0 : a[column.dataField] ? -1 : 1;
                if (column.type === DataTypeColumnEnum.LIST)
                    return (
                        a[column.dataField].length === b[column.dataField].length &&
                        JSON.stringify(a[column.dataField]) === JSON.stringify(b[column.dataField])
                    );
                if (column.type === DataTypeColumnEnum.STRING) {
                    const dataFieldA = Number(a[column.dataField]);
                    const dataFieldB = Number(b[column.dataField]);
                    if (!isNaN(dataFieldA) && !isNaN(dataFieldB)) return dataFieldA - dataFieldB;
                }
            }
            return a[column.dataField].localeCompare(b[column.dataField]);
        });
    };

    const hasLinkDataType = () => props.columns.find((column) => column.type === DataTypeColumnEnum.LINK) !== undefined;

    const handlePageChange = (activePage: number) => {
        setActivePage(activePage);

        let rows = props.rows;
        const trimStart = (activePage - 1) * pageLimit;
        const trimEnd = trimStart + pageLimit;
        rows = rows.slice(trimStart, trimEnd);
        setConfig({
            ...config,
            data: [...rows],
        });
    };

    return (
        <Table data-cy="simple-list" name={props.tableName || "list"} sortable striped celled aria-rowcount={props.rows.length}>
            <Table.Header>
                <Table.Row>
                    {props.columns.map((column) => {
                        const isEmptyColumn = config.data.filter((item: any) => item[column.dataField]).length === 0;
                        if (column.type !== DataTypeColumnEnum.LINK) {
                            return !isEmptyColumn ? (
                                <Table.HeaderCell
                                    sorted={config.column === column.dataField ? config.direction : undefined}
                                    onClick={handleClick(column)}
                                    key={column.dataField}>
                                    {handleTranslationNamespace(column.text)}
                                </Table.HeaderCell>
                            ) : (
                                <Table.HeaderCell key={column.dataField}>
                                    {handleTranslationNamespace(column.text)}
                                </Table.HeaderCell>
                            );
                        }

                        return null;
                    })}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {config.data.length > 0 ? (
                    config.data.map((row: any, key: number) => (
                        <Table.Row
                            style={hasLinkDataType() ? {cursor: "pointer"} : {}}
                            key={key}
                            aria-rowindex={key}
                            onClick={() => hasLinkDataType() && navigate(buildLink(row))}>
                            {props.columns.map(
                                (column, key) =>
                                    column.type !== DataTypeColumnEnum.LINK && (
                                        <Table.Cell key={key} collapsing={true}>
                                            {formatCellValue(column.type, row[column.dataField])}
                                        </Table.Cell>
                                    )
                            )}
                        </Table.Row>
                    ))
                ) : (
                    <Table.Row colSpan={props.columns.length} textAlign="center">
                        <Table.Cell colSpan={props.columns.length}>{handleTranslationNamespace("No data")}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
            {props.rows.length > pageLimit && (
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={props.columns.length}>
                            <Pagination
                                data-cy="simple-list-pagination"
                                activePage={activePage}
                                firstItem={null}
                                lastItem={null}
                                totalPages={totalPage}
                                onPageChange={(_, currentPage) => {
                                    handlePageChange(Number(currentPage.activePage));
                                }}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            )}
        </Table>
    );
};

export default SimpleList;
