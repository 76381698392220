import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Grid, Message, Tab } from "semantic-ui-react";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import { useAddComputingRuleCondition } from "../../../Services/ComputingRule/Queries";
import { ComputingRule } from "../../../Services/ComputingRule/Types";
import { ComputingRuleRoundingMode } from "../../../Services/InsurancePackage/Types";
import { VersionDTO } from "../../../Services/Version/Types";
import ComputingRuleAccordion from "./ComputingRuleAccordion";

interface ComputingRulePropsType {
    computingRule: ComputingRule;
    version: VersionDTO;
    refetch: any;
}

const ComputingRuleList = ({computingRule, version, refetch}: ComputingRulePropsType) => {
    const {t} = useTranslation();
    const [activeComputingRuleConditionIndex, setActiveComputingRuleConditionIndex] = useState<number>(0);

    const addComputingRuleConditionMutation = useAddComputingRuleCondition(
        computingRule.id !== null ? computingRule.id : 0
    );

    const onAddConditionValidation = () => {
        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            conditionPartList: [],
            orderCondition: null,
            calculFormule: null,
            rulePartList: [],
            roundingMode: ComputingRuleRoundingMode.ROUND_HALF_UP,
            decimalCount: 2,
        };
        addComputingRuleConditionMutation.mutate(condition);
    };
    const onAddConditionSuccess = () => {
        refetch();
        if (computingRule !== undefined) {
            setActiveComputingRuleConditionIndex(computingRule.computingRuleConditions.length - 1);
        }
    };

    return (
        <Tab.Pane style={{padding: "20px"}}>
            <Accordion styled fluid>
                {computingRule.computingRuleConditions
                    .sort((a, b) => a.orderCondition - b.orderCondition)
                    .map((computingRuleCondition, index) => (
                        <ComputingRuleAccordion
                            computingRule={computingRule}
                            version={version}
                            computingRuleCondition={computingRuleCondition}
                            numberOfConditions={computingRule.computingRuleConditions.length}
                            index={index}
                            activeIndex={activeComputingRuleConditionIndex}
                            setActiveIndex={setActiveComputingRuleConditionIndex}
                            refetch={refetch}
                            key={index}
                        />
                    ))}
            </Accordion>

            {version !== undefined && version.pipDate === null && (
                <Grid style={{marginTop: 0}}>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <ModalAdd
                                isPending={addComputingRuleConditionMutation.isPending}
                                isSuccess={addComputingRuleConditionMutation.isSuccess}
                                isError={addComputingRuleConditionMutation.isError}
                                resetMutation={addComputingRuleConditionMutation.reset}
                                error={addComputingRuleConditionMutation.error}
                                onValidate={() => onAddConditionValidation()}
                                onSuccess={() => onAddConditionSuccess()}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="addCondition"
                                renderModalContent={() => (
                                    <Message info>
                                        {t(
                                            "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                        )}
                                    </Message>
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Tab.Pane>
    );
};

export default ComputingRuleList;
