import axios, {AxiosError} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {SystemVariableList, Variable, VariableCondition} from "./Types";

/* ------ VARIABLES ------ */

export const getVariables = async (versionId: string, type: string): Promise<Array<Variable>> => {
    try {
        const response = await axiosInstance.get<Array<Variable>>(`/versions/${versionId}/variables?type=${type}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getVariable = async (versionId: string, variableId: string): Promise<Variable> => {
    try {
        const response = await axiosInstance.get<Variable>(`/versions/${versionId}/variables/${variableId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getSystemVariables = async (versionId: string): Promise<Array<SystemVariableList>> => {
    try {
        const response = await axiosInstance.get<Array<SystemVariableList>>(
            `/versions/${versionId}/variables/systemVariables`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addVariable = async (versionId: string, variable: Variable): Promise<Variable> => {
    try {
        const response = await axiosInstance.post<any>(`/versions/${versionId}/variables`, variable);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateVariable = async (versionId: string, variableId: string, variable: Variable): Promise<Variable> => {
    try {
        const response = await axiosInstance.patch<any>(`/versions/${versionId}/variables/${variableId}`, variable);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteVariable = async (versionId: string, variableId: number): Promise<void> => {
    try {
        const response = await axiosInstance.delete(`/versions/${versionId}/variables/${variableId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const duplicateVariable = async (versionId: string, variableId: number, name: string, description: string) => {
    try {
        const response = await axiosInstance.post<Variable>(
            `/versions/${versionId}/variables/${variableId}/_duplicate?name=${name}&description=${description}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

/* ------ VARIABLES CONDITIONS ------ */

export const addVariableCondition = async (
    variableId: string,
    variableCondition: VariableCondition
): Promise<VariableCondition> => {
    try {
        const response = await axiosInstance.post<any>(`/variables/${variableId}/conditions`, variableCondition);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateVariableCondition = async (
    variableId: string,
    variableCondtionId: string,
    variableCondition: VariableCondition
): Promise<VariableCondition> => {
    try {
        const response = await axiosInstance.put<any>(
            `/variables/${variableId}/conditions/${variableCondtionId}`,
            variableCondition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteVariableCondition = async (variableId: string, variableCondtionId: string): Promise<void> => {
    try {
        const response = await axiosInstance.delete(`/variables/${variableId}/conditions/${variableCondtionId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

