import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import {
    AccordionContent,
    AccordionTitle,
    Button,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon
} from "semantic-ui-react";
import ModalDelete from "../../../../../../Components/Modals/ModalDelete";
import { ConditionKindEnum, ConditionPart, OperandEnum } from "../../../../../../Services/Condition/Types";
import {
    useDeleteDeductibleCapitalConditions,
    useUpdateDeductibleCapitalConditions
} from "../../../../../../Services/DeductibleCapital/Queries";
import {
    DeductibleCapital,
    DeductibleCapitalCondition,
    DeductibleCapitalConditionDTO
} from "../../../../../../Services/DeductibleCapital/Types";
import { VersionDTO } from "../../../../../../Services/Version/Types";
import { conditionPartListToString } from "../../../../../../Utils/ConditionPartUtils";
import ConditionHandling from "../../../../Condition/ConditionHandling";
import ConditionView from "../../../../Condition/Fragments/ConditionView";
import DeductibleCapitalConditionAssignement from "./DeductibleCapitalConditionAssignement";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

type DeductibleCapitalConditionAccordionPropsType = {
    index: number;
    condition: DeductibleCapitalCondition;
    numberOfConditions?: number;
    version: VersionDTO | undefined;
    activeIndex: number;
    setActiveIndex: Function;
    deductibleCapital: DeductibleCapital;
};

const DeductibleCapitalConditionAccordion = ({
    index,
    condition,
    numberOfConditions,
    version,
    activeIndex,
    setActiveIndex,
    deductibleCapital,
}: DeductibleCapitalConditionAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const updateDeductibleCapitalConditionMutation = useUpdateDeductibleCapitalConditions(
        params.insurancePackageId,
        params.deductibleCapitalId,
        String(condition.id)
    );
    const deleteDeductibleCapitalConditionMutation = useDeleteDeductibleCapitalConditions(
        params.insurancePackageId,
        params.deductibleCapitalId,
        String(condition.id)
    );

    const conditionKindList = [
        {key: 1, text: "Question", value: OperandEnum.QUESTION, type: "female"},
        {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
        {key: 4, text: "Computing rule variables", value: OperandEnum.VARIABLE_COMPUTING_RULE, type: "female"},
        {key: 5, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
    ];

    return (
        <div key={index}>
            <AccordionTitle
                style={{display: "flex", alignItems: "center"}}
                active={activeIndex === index}
                index={index}
                onClick={() => {
                    if (activeIndex === index) setActiveIndex(-1);
                    else setActiveIndex(index);
                }}>
                <Icon name="dropdown" />
                <div style={{display: "flex", alignItems: "center"}}>
                    {t("Condition") + " " + (index + 1)}
                    {!(activeIndex === index) && numberOfConditions !== 1 && condition !== null && (
                        <>
                            {" "}
                            -
                            <p style={{marginLeft: "4px", fontWeight: "lighter", fontSize: "12px", color: "grey"}}>
                                {condition.ifCondition
                                    ? conditionPartListToString(condition.conditionPartList)
                                    : t("Else")}
                            </p>
                        </>
                    )}
                </div>
            </AccordionTitle>
            <AccordionContent active={activeIndex === index}>
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>

                {editCondition ? (
                    <ConditionHandling
                        versionId={params.versionId}
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: Partial<DeductibleCapitalConditionDTO> = {
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate.map((conditionPart) => {
                                    if (conditionPart.conditionKind === ConditionKindEnum.VARIABLE_COMPUTING_RULE) {
                                        conditionPart.conditionKind = ConditionKindEnum.VARIABLE;
                                    }
                                    return conditionPart;
                                }),
                                conditionOrder: condition.conditionOrder,
                                deductibleCapitalConditionElementDTOs: condition.deductibleCapitalConditionElements,
                            };

                            updateDeductibleCapitalConditionMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateDeductibleCapitalConditionMutation}
                    />
                ) : (
                    <>
                        <Grid columns={3}>
                            {deductibleCapital.deductibleCapitalConditions.length === 1 ? (
                                <GridColumn width={2} textAlign="left" verticalAlign="middle">
                                    {t("Default value")}
                                </GridColumn>
                            ) : condition.conditionPartList &&
                              condition.conditionPartList !== null &&
                              index !== deductibleCapital.deductibleCapitalConditions.length - 1 ? (
                                <>
                                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                        {t("If")} :
                                    </GridColumn>
                                    <GridColumn width={14} textAlign="center" className="condition">
                                        <ConditionView conditionPartList={condition.conditionPartList} />
                                    </GridColumn>
                                    {version !== undefined && version.pipDate === null && (
                                        <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                            <Button
                                                name={"editCondition" + Number(index + 1)}
                                                color="grey"
                                                icon="edit"
                                                compact
                                                basic
                                                onClick={() => {
                                                    setEditCondition(true);
                                                }}
                                            />
                                        </GridColumn>
                                    )}
                                </>
                            ) : (
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("Else")}
                                </GridColumn>
                            )}
                        </Grid>
                        <Divider horizontal>
                            <Header as="h4">{t("Text")}</Header>
                        </Divider>

                        <DeductibleCapitalConditionAssignement
                            deductibleCapital={deductibleCapital}
                            deductibleCapitalCondition={condition}
                            version={version}
                            updateDeductibleCapitalConditionMutation={updateDeductibleCapitalConditionMutation}
                            activeIndex={activeIndex}
                            index={index}
                            numberOfConditions={numberOfConditions}
                        />

                        {index !== deductibleCapital.deductibleCapitalConditions.length - 1 &&
                            version !== undefined &&
                            version.pipDate === null && (
                                <>
                                    <Divider />
                                    <Grid>
                                        <GridRow>
                                            <GridColumn width={14} />
                                            <GridColumn width={2} textAlign={"right"}>
                                                <ModalDelete
                                                    isSuccess={deleteDeductibleCapitalConditionMutation.isSuccess}
                                                    isPending={deleteDeductibleCapitalConditionMutation.isPending}
                                                    isError={deleteDeductibleCapitalConditionMutation.isError}
                                                    error={deleteDeductibleCapitalConditionMutation.error}
                                                    resetMutation={deleteDeductibleCapitalConditionMutation.reset}
                                                    objectToDelete="Condition"
                                                    objectType="female"
                                                    openModalButtonName={"deleteCondition" + Number(index + 1)}
                                                    onValidate={() =>
                                                        deleteDeductibleCapitalConditionMutation.mutate()
                                                    }
                                                    onSuccess={() => setActiveIndex(-1)}
                                                />
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </>
                            )}
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default DeductibleCapitalConditionAccordion;
