import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {
    Button,
    Card,
    Dropdown,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Input,
    Label,
    Segment,
    SemanticCOLORS,
} from "semantic-ui-react";
import EntityPicker from "../../../../Components/Modals/EntityPicker";
import SystemVariablePicker from "../../../../Components/Modals/SystemVariablePickModal";
import TextOverflow from "../../../../Components/Text/TextOverflow";
import {
    ConditionExpressionView,
    ConditionKindEnum,
    ConditionPart,
    ContentTypeEnum,
    LogicalOperatorEnum,
    OperandEnum,
    OperatorEnum,
} from "../../../../Services/Condition/Types";
import {useGetPricingCriteriasList} from "../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItem, PricingCriteriaItemType} from "../../../../Services/PricingCriterias/Types";
import {useGetQuestionsQuery} from "../../../../Services/Question/Queries";
import {QuestionDTO, QuestionValueTypeEnum} from "../../../../Services/Question/Types";
import {useGetSystemVariableList, useGetVariablesList} from "../../../../Services/Variables/Queries";
import {SystemVariableDTO, Variable, VariableType, VariableValueType} from "../../../../Services/Variables/Types";
import {
    sortOptionsDataType,
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsVariableName,
} from "../../../../Utils/SortUtils";

type ConditionExpressionPropsTypes = {
    index: number;
    condition: ConditionExpressionView;
    conditionExpressionList: ConditionExpressionView[];
    dispatchConditionExpressionList: Function;
    conditionKindList: {key: number; text: string; value: OperandEnum; type: string}[];
    versionId?: string;
};

const ConditionExpression = ({
    index,
    condition,
    conditionExpressionList,
    dispatchConditionExpressionList,
    conditionKindList,
    versionId,
}: ConditionExpressionPropsTypes) => {
    const {t} = useTranslation();
    const operatorOptionsList = [
        {key: 1, text: t("enums.OperatorEnum." + OperatorEnum["=="]), value: "=="},
        {key: 2, text: t("enums.OperatorEnum." + OperatorEnum["!="]), value: "!="},
        {key: 3, text: t("enums.OperatorEnum." + OperatorEnum["<"]), value: "<"},
        {key: 4, text: t("enums.OperatorEnum." + OperatorEnum[">"]), value: ">"},
        {key: 5, text: t("enums.OperatorEnum." + OperatorEnum["<="]), value: "<="},
        {key: 6, text: t("enums.OperatorEnum." + OperatorEnum[">="]), value: ">="},
    ];
    const operatorOptionsListString = [
        {key: 1, text: t("enums.OperatorEnum." + OperatorEnum["=="]), value: "=="},
        {key: 2, text: t("enums.OperatorEnum." + OperatorEnum["!="]), value: "!="},
    ];
    const logicalOperatorOptions = [
        {key: 1, text: t("enums.LogicalOperatorEnum." + LogicalOperatorEnum.AND), value: LogicalOperatorEnum.AND},
        {key: 2, text: t("enums.LogicalOperatorEnum." + LogicalOperatorEnum.OR), value: LogicalOperatorEnum.OR},
    ];

    return (
        <GridRow>
            <Segment key={index} secondary>
                <Grid columns={4}>
                    <ConditionExpressionOperand
                        side={"left"}
                        conditionPart={condition.leftOperand}
                        otherSideConditionPart={condition.rightOperand}
                        conditionKindList={conditionKindList}
                        index={index}
                        dispatchConditionExpressionList={dispatchConditionExpressionList}
                        versionId={versionId}
                    />

                    <GridColumn key={"operator"} width={3} textAlign="center">
                        <Dropdown
                            data-cy="operator"
                            disabled={condition.leftOperand.conditionValue === ""}
                            fluid
                            placeholder={t("Operator")}
                            selection
                            value={condition.operator.conditionValue}
                            options={
                                condition.leftOperand.contentType === "STRING"
                                    ? operatorOptionsListString
                                    : operatorOptionsList
                            }
                            onChange={(e, value) => {
                                dispatchConditionExpressionList({
                                    type: "edit",
                                    payload: {
                                        index: index,
                                        field: "operator",
                                        value: {
                                            conditionValue: value.value,
                                            conditionKind: ConditionKindEnum.COMPARISON_OPERATOR,
                                            contentType: null,
                                        },
                                    },
                                });
                            }}
                        />
                    </GridColumn>

                    <ConditionExpressionOperand
                        side={"right"}
                        conditionPart={condition.rightOperand}
                        otherSideConditionPart={condition.leftOperand}
                        conditionKindList={conditionKindList}
                        index={index}
                        dispatchConditionExpressionList={dispatchConditionExpressionList}
                        versionId={versionId}
                    />

                    <GridColumn width={3}>
                        <Grid columns="equal">
                            {index < conditionExpressionList.length - 1 ? (
                                <GridColumn key={"logicalOperator"} verticalAlign="middle" textAlign="right">
                                    <Dropdown
                                        data-cy="logical_operator"
                                        compact
                                        selection
                                        options={logicalOperatorOptions}
                                        value={
                                            condition.logicalOperator === undefined
                                                ? ""
                                                : condition.logicalOperator.conditionValue
                                        }
                                        disabled={index > 0 && index < conditionExpressionList.length}
                                        style={{border: "dashed grey"}}
                                        onChange={(e, value) => {
                                            conditionExpressionList.forEach((condition, index) => {
                                                if (index < conditionExpressionList.length - 1) {
                                                    dispatchConditionExpressionList({
                                                        type: "edit",
                                                        payload: {
                                                            index: index,
                                                            field: "logicalOperator",
                                                            value: {
                                                                conditionValue: value.value,
                                                                conditionKind: ConditionKindEnum.LOGICAL_OPERATOR,
                                                                contentType: null,
                                                            },
                                                        },
                                                    });
                                                }
                                            });
                                        }}
                                    />
                                </GridColumn>
                            ) : (
                                <GridColumn key={"plus"} verticalAlign="middle" textAlign="right">
                                    <Button
                                        className="addConditionExpression"
                                        color="blue"
                                        icon="plus"
                                        compact
                                        basic
                                        onClick={() => {
                                            dispatchConditionExpressionList({type: "add", payload: index});
                                        }}></Button>
                                </GridColumn>
                            )}
                            <GridColumn key={"close"} verticalAlign="middle" textAlign="left">
                                {conditionExpressionList.length > 1 && (
                                    <Button
                                        className="deleteConditionExpression"
                                        color="grey"
                                        icon="close"
                                        compact
                                        basic
                                        onClick={() => {
                                            dispatchConditionExpressionList({type: "remove", payload: index});
                                        }}></Button>
                                )}
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                </Grid>
            </Segment>
        </GridRow>
    );
};

export default ConditionExpression;

interface IParams extends Params {
    id: string;
    versionId: string;
    pricingCriteriaId: string;
    variableId: string;
}

type ConditionExpressionOperandPropsType = {
    side: "left" | "right";
    conditionPart: ConditionPart;
    otherSideConditionPart: ConditionPart;
    index: number;
    conditionKindList: {key: number; text: string; value: OperandEnum; type: string}[];
    dispatchConditionExpressionList: Function;
    versionId?: string;
};

const ConditionExpressionOperand = ({
    side,
    conditionPart,
    otherSideConditionPart,
    index,
    conditionKindList,
    dispatchConditionExpressionList,
    versionId,
}: ConditionExpressionOperandPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [operandSelectedType, setOperandSelectedType] = useState<OperandEnum>(OperandEnum.NOTHING);
    const [openQuestionModal, setOpenQuestionModal] = useState(false);
    const [openPricingCriteriaModal, setOpenPricingCriteriaModal] = useState(false);
    const [openVariableVModal, setOpenVariableVModal] = useState(false);
    const [openVariableRModal, setOpenVariableRModal] = useState(false);
    const [openSoftwareCriteriaModal, setOpenSoftwareCriteriaModal] = useState(false);

    const openPickerModal = (selectedType: OperandEnum) => {
        switch (selectedType) {
            case OperandEnum.CRITERE:
                setOpenPricingCriteriaModal(true);
                break;
            case OperandEnum.QUESTION:
                setOpenQuestionModal(true);
                break;
            case OperandEnum.VARIABLE:
                setOpenVariableVModal(true);
                break;
            case OperandEnum.VARIABLE_COMPUTING_RULE:
                setOpenVariableRModal(true);
                break;
            case OperandEnum.SOFTWARE_CRITERIA:
                setOpenSoftwareCriteriaModal(true);
                break;
        }
    };

    const resetOperand = () => {
        setOperandSelectedType(OperandEnum.NOTHING);
        dispatchConditionExpressionList({
            type: "edit",
            payload: {
                index: index,
                field: side + "Operand",
                value: {
                    conditionValue: "",
                    conditionKind: null,
                    contentType: null,
                },
            },
        });

        if (side === "left") {
            dispatchConditionExpressionList({
                type: "edit",
                payload: {
                    index: index,
                    field: "operator",
                    value: {
                        conditionValue: "",
                        conditionKind: null,
                        contentType: null,
                    },
                },
            });
            dispatchConditionExpressionList({
                type: "edit",
                payload: {
                    index: index,
                    field: "rightOperand",
                    value: {
                        conditionValue: "",
                        conditionKind: null,
                        contentType: null,
                    },
                },
            });
        }
    };

    const isTranslated = (variableName: string) => {
        return variableName.includes("option") ? variableName : t("enums.SystemVariable." + variableName);
    };

    useEffect(() => {
        setOperandSelectedType(OperandEnum.NOTHING);
        switch (conditionPart.conditionKind) {
            case ConditionKindEnum.STRING:
            case ConditionKindEnum.NUMBER:
            case ConditionKindEnum.DATE:
                setOperandSelectedType(OperandEnum.FIXED_VALUE);
                break;
            case ConditionKindEnum.VARIABLE:
                setOperandSelectedType(OperandEnum.VARIABLE);
                break;
            case ConditionKindEnum.VARIABLE_COMPUTING_RULE:
                setOperandSelectedType(OperandEnum.VARIABLE_COMPUTING_RULE);
                break;
            case ConditionKindEnum.CRITERE:
                setOperandSelectedType(OperandEnum.CRITERE);
                break;
            case ConditionKindEnum.QUESTION:
                setOperandSelectedType(OperandEnum.QUESTION);
                break;
            case ConditionKindEnum.SOFTWARE_CRITERIA:
                setOperandSelectedType(OperandEnum.SOFTWARE_CRITERIA);
                break;
        }
    }, [conditionPart]);

    const getColorOfQuestionValueType = (questionValueType: QuestionValueTypeEnum): SemanticCOLORS | undefined => {
        switch (questionValueType) {
            case QuestionValueTypeEnum.STRING:
                return "blue";
            case QuestionValueTypeEnum.DATE:
                return "green";
            case QuestionValueTypeEnum.NOMBRE:
                return "purple";
            case QuestionValueTypeEnum.LISTE:
                return "brown";
            default:
                return undefined;
        }
    };

    const getColorOfPricingCriteriaItemType = (
        variableValueType: PricingCriteriaItemType
    ): SemanticCOLORS | undefined => {
        switch (variableValueType) {
            case PricingCriteriaItemType.STRING:
                return "blue";
            case PricingCriteriaItemType.DATE:
                return "green";
            case PricingCriteriaItemType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    const getColorOfVariableValueType = (variableValueType: VariableValueType): SemanticCOLORS | undefined => {
        switch (variableValueType) {
            case VariableValueType.STRING:
                return "blue";
            case VariableValueType.DATE:
                return "green";
            case VariableValueType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    useEffect(() => {
        if (side === "left") {
            if (
                conditionPart.conditionValue === "1" &&
                conditionPart.conditionKind === ConditionKindEnum.NUMBER &&
                otherSideConditionPart.conditionValue === "1" &&
                otherSideConditionPart.conditionKind === ConditionKindEnum.NUMBER
            ) {
                resetOperand();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [side, conditionPart, otherSideConditionPart]);

    return (
        <GridColumn key={side + "_operand"} width={5} textAlign="center">
            {/* VALEUR FIXE */}
            {operandSelectedType === OperandEnum.FIXED_VALUE && (
                <Input
                    data-cy="fixed_value"
                    fluid
                    name={side + "_operand" + "_" + index}
                    placeholder={t("Fixed value")}
                    maxLength="100"
                    defaultValue={conditionPart.conditionValue}
                    type={
                        otherSideConditionPart.contentType !== null
                            ? otherSideConditionPart.contentType === "NUMERIC"
                                ? "number"
                                : otherSideConditionPart.contentType === "STRING"
                                ? "text"
                                : otherSideConditionPart.contentType === "DATE"
                                ? "date"
                                : "text"
                            : "text"
                    }
                    action={
                        <Button
                            icon
                            style={{
                                background: "#fff",
                                border: "1px solid rgba(34,36,38,.15)",
                                borderLeft: "none",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                resetOperand();
                            }}>
                            <Icon
                                name="remove"
                                style={{color: "#000", fontSize: ".85714286em", opacity: ".8", lineHeight: "1em"}}
                            />
                        </Button>
                    }
                    onChange={(e, value) => {
                        dispatchConditionExpressionList({
                            type: "edit",
                            payload: {
                                index: index,
                                field: side + "Operand",
                                value: {
                                    conditionValue: value.value,
                                    conditionKind:
                                        otherSideConditionPart.contentType === ContentTypeEnum.NUMERIC
                                            ? ConditionKindEnum.NUMBER
                                            : otherSideConditionPart.contentType,
                                    contentType: null,
                                },
                            },
                        });
                    }}
                />
            )}

            {/* PRICING CRITERIA */}
            {openPricingCriteriaModal && (
                <EntityPicker
                    object={"Pricing criteria"}
                    objectContext={"male"}
                    entityListGetMethod={useGetPricingCriteriasList}
                    entityListGetParameters={[versionId, versionId !== undefined]}
                    renderCardContent={(pricingCriteria: PricingCriteriaItem) => (
                        <Card.Content
                            style={{
                                border:
                                    pricingCriteria.name === conditionPart.conditionValue
                                        ? "1px solid #2185d0"
                                        : "none",
                            }}>
                            <Card.Header>
                                <TextOverflow value={pricingCriteria.name} />
                            </Card.Header>
                            <Card.Description style={{marginTop: "10px"}}>
                                <Label
                                    name="valueTypeCard"
                                    color={getColorOfPricingCriteriaItemType(pricingCriteria.type)}>
                                    {t("enums.PricingCriteriaType." + pricingCriteria.type)}
                                </Label>
                                <p style={{marginTop: "12px"}}>{pricingCriteria.description}</p>
                            </Card.Description>
                        </Card.Content>
                    )}
                    filterEntity={(pricingCriteria: PricingCriteriaItem) => {
                        return (
                            (side === "left" ||
                                otherSideConditionPart.contentType === ContentTypeEnum.STRING ||
                                (otherSideConditionPart.contentType === ContentTypeEnum.NUMERIC &&
                                    (pricingCriteria.type === PricingCriteriaItemType.NUMERIC ||
                                        pricingCriteria.type === PricingCriteriaItemType.STRING)) ||
                                (otherSideConditionPart.contentType === ContentTypeEnum.DATE &&
                                    pricingCriteria.type === PricingCriteriaItemType.DATE)) &&
                            (params.pricingCriteriaId === undefined ||
                                (params.pricingCriteriaId !== undefined &&
                                    String(pricingCriteria.id) !== params.pricingCriteriaId))
                        );
                    }}
                    filterBySearch={(pricingCriteria: PricingCriteriaItem, search: string): boolean => {
                        return (
                            pricingCriteria.name.toLowerCase().includes(search.toLowerCase()) ||
                            pricingCriteria.type.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onClickOnEntity={(pricingCriteria: PricingCriteriaItem) => {
                        setOpenPricingCriteriaModal(false);
                        setOperandSelectedType(OperandEnum.CRITERE);
                        dispatchConditionExpressionList({
                            type: "edit",
                            payload: {
                                index: index,
                                field: side + "Operand",
                                value: {
                                    conditionValue: pricingCriteria.name,
                                    conditionKind: OperandEnum.CRITERE,
                                    contentType: ContentTypeEnum[pricingCriteria.type],
                                },
                            },
                        });
                    }}
                    onCancel={() => {
                        setOpenPricingCriteriaModal(false);
                    }}
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                    defaultSortMethod={(a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)}
                />
            )}

            {operandSelectedType === OperandEnum.CRITERE && (
                <div className="ui fluid action input">
                    <button
                        data-cy={side + "_operand"}
                        className="ui icon right labeled button fluid"
                        style={{
                            borderRadius: ".28571429rem",
                            background: "#fff",
                            border: "1px solid rgba(34,36,38,.15)",
                        }}
                        onClick={() => {
                            setOpenPricingCriteriaModal(true);
                        }}>
                        {conditionPart.conditionValue}
                        <i
                            aria-hidden="true"
                            className="remove icon"
                            style={{
                                background: "#fff",
                                color: "#000",
                                fontSize: ".85714286em",
                                opacity: ".8",
                                width: "36px",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                resetOperand();
                            }}></i>
                    </button>
                </div>
            )}

            {/* QUESTION */}
            {openQuestionModal && (
                <EntityPicker
                    object={"Question"}
                    objectContext={"female"}
                    entityListGetMethod={useGetQuestionsQuery}
                    entityListGetParameters={[versionId, versionId !== undefined]}
                    renderCardContent={(question: QuestionDTO) => (
                        <Card.Content
                            style={{
                                border: question.name === conditionPart.conditionValue ? "1px solid #2185d0" : "none",
                            }}>
                            <Card.Header>
                                <TextOverflow value={question.name} />
                            </Card.Header>
                            <Card.Description style={{marginTop: "10px"}}>
                                <Label name="valueTypeCard" color={getColorOfQuestionValueType(question.valueType)}>
                                    {t("enums.QuestionValueType." + question.valueType)}
                                </Label>
                            </Card.Description>
                        </Card.Content>
                    )}
                    filterEntity={(question: QuestionDTO) => {
                        return (
                            side === "left" ||
                            otherSideConditionPart.contentType === ContentTypeEnum.STRING ||
                            (otherSideConditionPart.contentType === ContentTypeEnum.NUMERIC &&
                                (question.valueType === QuestionValueTypeEnum.NOMBRE ||
                                    question.valueType === QuestionValueTypeEnum.STRING ||
                                    question.valueType === QuestionValueTypeEnum.LISTE)) ||
                            (otherSideConditionPart.contentType === ContentTypeEnum.DATE &&
                                question.valueType === QuestionValueTypeEnum.DATE)
                        );
                    }}
                    filterBySearch={(question: QuestionDTO, search: string): boolean => {
                        return (
                            question.name.toLowerCase().includes(search.toLowerCase()) ||
                            question.valueType.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onClickOnEntity={(question: QuestionDTO) => {
                        setOpenQuestionModal(false);
                        setOperandSelectedType(OperandEnum.QUESTION);
                        dispatchConditionExpressionList({
                            type: "edit",
                            payload: {
                                index: index,
                                field: side + "Operand",
                                value: {
                                    conditionValue: question.name,
                                    conditionKind: OperandEnum.QUESTION,
                                    contentType:
                                        ContentTypeEnum[
                                            question.valueType === QuestionValueTypeEnum.LISTE
                                                ? ContentTypeEnum.STRING
                                                : question.valueType === QuestionValueTypeEnum.NOMBRE
                                                ? ContentTypeEnum.NUMERIC
                                                : question.valueType
                                        ],
                                },
                            },
                        });
                    }}
                    onCancel={() => {
                        setOpenQuestionModal(false);
                    }}
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                    defaultSortMethod={(a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)}
                />
            )}

            {operandSelectedType === OperandEnum.QUESTION && (
                <div className="ui fluid action input">
                    <button
                        data-cy={side + "_operand"}
                        className="ui icon right labeled button fluid"
                        style={{
                            borderRadius: ".28571429rem",
                            background: "#fff",
                            border: "1px solid rgba(34,36,38,.15)",
                        }}
                        onClick={() => {
                            setOpenQuestionModal(true);
                        }}>
                        {<TextOverflow value={conditionPart.conditionValue} />}
                        <i
                            aria-hidden="true"
                            className="remove icon"
                            style={{
                                background: "#fff",
                                color: "#000",
                                fontSize: ".85714286em",
                                opacity: ".8",
                                width: "36px",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                resetOperand();
                            }}></i>
                    </button>
                </div>
            )}

            {/* VARIABLE V */}
            {openVariableVModal && (
                <EntityPicker
                    object={"Variable"}
                    objectContext={"female"}
                    entityListGetMethod={useGetVariablesList}
                    entityListGetParameters={[versionId, VariableType.V, versionId !== undefined]}
                    renderCardContent={(variable: Variable) => (
                        <Card.Content
                            style={{
                                border: variable.name === conditionPart.conditionValue ? "1px solid #2185d0" : "none",
                            }}>
                            <Card.Header>
                                <TextOverflow value={variable.name} />
                            </Card.Header>
                            <Card.Description style={{marginTop: "10px"}}>
                                <Label name="valueTypeCard" color={getColorOfVariableValueType(variable.valueType)}>
                                    {t("enums.VariableValueType." + variable.valueType)}
                                </Label>
                                <p style={{marginTop: "12px"}}>{variable.description}</p>
                            </Card.Description>
                        </Card.Content>
                    )}
                    filterEntity={(variableToFilter: Variable) => {
                        return (
                            (side === "left" ||
                                otherSideConditionPart.contentType === ContentTypeEnum.STRING ||
                                (otherSideConditionPart.contentType === ContentTypeEnum.NUMERIC &&
                                    (variableToFilter.valueType === VariableValueType.NUMERIC ||
                                        variableToFilter.valueType === VariableValueType.STRING)) ||
                                (otherSideConditionPart.contentType === ContentTypeEnum.DATE &&
                                    variableToFilter.valueType === VariableValueType.DATE)) &&
                            (params.variableId === undefined ||
                                (params.variableId !== undefined && String(variableToFilter.id) !== params.variableId))
                        );
                    }}
                    filterBySearch={(variableToFilter: Variable, search: string): boolean => {
                        return (
                            variableToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                            variableToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onClickOnEntity={(variable: Variable) => {
                        setOpenVariableVModal(false);
                        setOperandSelectedType(OperandEnum.VARIABLE);
                        dispatchConditionExpressionList({
                            type: "edit",
                            payload: {
                                index: index,
                                field: side + "Operand",
                                value: {
                                    conditionValue: variable.name,
                                    conditionKind: OperandEnum.VARIABLE,
                                    contentType: ContentTypeEnum[variable.valueType],
                                },
                            },
                        });
                    }}
                    onCancel={() => {
                        setOpenVariableVModal(false);
                    }}
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                    defaultSortMethod={(a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)}
                />
            )}

            {operandSelectedType === OperandEnum.VARIABLE && (
                <div className="ui fluid action input">
                    <button
                        data-cy={side + "_operand"}
                        className="ui icon right labeled button fluid"
                        style={{
                            borderRadius: ".28571429rem",
                            background: "#fff",
                            border: "1px solid rgba(34,36,38,.15)",
                        }}
                        onClick={() => {
                            setOpenVariableVModal(true);
                        }}>
                        {<TextOverflow value={conditionPart.conditionValue} />}
                        <i
                            aria-hidden="true"
                            className="remove icon"
                            style={{
                                background: "#fff",
                                color: "#000",
                                fontSize: ".85714286em",
                                opacity: ".8",
                                width: "36px",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                resetOperand();
                            }}></i>
                    </button>
                </div>
            )}

            {/* VARIABLE R */}
            {openVariableRModal && (
                <EntityPicker
                    object={"Computing rule variables"}
                    objectContext={"female"}
                    entityListGetMethod={useGetVariablesList}
                    entityListGetParameters={[versionId, VariableType.R, versionId !== undefined]}
                    renderCardContent={(variable: Variable) => (
                        <Card.Content
                            style={{
                                border: variable.name === conditionPart.conditionValue ? "1px solid #2185d0" : "none",
                            }}>
                            <Card.Header>
                                <TextOverflow value={variable.name} />
                            </Card.Header>
                            <Card.Description style={{marginTop: "10px"}}>
                                <Label name="valueTypeCard" color={getColorOfVariableValueType(variable.valueType)}>
                                    {t("enums.VariableValueType." + variable.valueType)}
                                </Label>
                                <p style={{marginTop: "12px"}}>{variable.description}</p>
                            </Card.Description>
                        </Card.Content>
                    )}
                    filterEntity={(variableToFilter: Variable) => {
                        return (
                            (side === "left" ||
                                otherSideConditionPart.contentType === ContentTypeEnum.STRING ||
                                (otherSideConditionPart.contentType === ContentTypeEnum.NUMERIC &&
                                    (variableToFilter.valueType === VariableValueType.NUMERIC ||
                                        variableToFilter.valueType === VariableValueType.STRING)) ||
                                (otherSideConditionPart.contentType === ContentTypeEnum.DATE &&
                                    variableToFilter.valueType === VariableValueType.DATE)) &&
                            (params.variableId === undefined ||
                                (params.variableId !== undefined && String(variableToFilter.id) !== params.variableId))
                        );
                    }}
                    filterBySearch={(variableToFilter: Variable, search: string): boolean => {
                        return (
                            variableToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                            variableToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onClickOnEntity={(variable: Variable) => {
                        setOpenVariableRModal(false);
                        setOperandSelectedType(OperandEnum.VARIABLE_COMPUTING_RULE);
                        dispatchConditionExpressionList({
                            type: "edit",
                            payload: {
                                index: index,
                                field: side + "Operand",
                                value: {
                                    conditionValue: variable.name,
                                    conditionKind: OperandEnum.VARIABLE_COMPUTING_RULE,
                                    contentType: ContentTypeEnum[variable.valueType],
                                },
                            },
                        });
                    }}
                    onCancel={() => {
                        setOpenVariableRModal(false);
                    }}
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                    defaultSortMethod={(a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)}
                />
            )}
            {operandSelectedType === OperandEnum.VARIABLE_COMPUTING_RULE && (
                <div className="ui fluid action input">
                    <button
                        data-cy={side + "_operand"}
                        className="ui icon right labeled button fluid"
                        style={{
                            borderRadius: ".28571429rem",
                            background: "#fff",
                            border: "1px solid rgba(34,36,38,.15)",
                        }}
                        onClick={() => {
                            setOpenVariableRModal(true);
                        }}>
                        {<TextOverflow value={conditionPart.conditionValue} />}
                        <i
                            aria-hidden="true"
                            className="remove icon"
                            style={{
                                background: "#fff",
                                color: "#000",
                                fontSize: ".85714286em",
                                opacity: ".8",
                                width: "36px",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                resetOperand();
                            }}></i>
                    </button>
                </div>
            )}

            {/* SOFTWARE CRITERIA */}
            {openSoftwareCriteriaModal && (
                <SystemVariablePicker
                    object={"System variable"}
                    objectContext={"female"}
                    entityListGetMethod={useGetSystemVariableList}
                    entityListGetParameters={[params.versionId]}
                    renderCardContent={(systemVariable: SystemVariableDTO) => (
                        <Card.Content
                            style={{
                                border:
                                    systemVariable.variableLabel === conditionPart.conditionValue
                                        ? "1px solid #2185d0"
                                        : "none",
                            }}>
                            <Card.Header>
                                <TextOverflow value={isTranslated(systemVariable.variableLabel)} />
                            </Card.Header>
                            <Card.Description style={{marginTop: "10px"}}>
                                <Label
                                    name="valueTypeCard"
                                    color={getColorOfVariableValueType(systemVariable.variableCritereType)}>
                                    {systemVariable.variableCritereType}
                                </Label>
                            </Card.Description>
                        </Card.Content>
                    )}
                    filterEntity={(systemVariable: SystemVariableDTO) => {
                        return (
                            side === "left" ||
                            otherSideConditionPart.contentType === ContentTypeEnum.STRING ||
                            (otherSideConditionPart.contentType === ContentTypeEnum.NUMERIC &&
                                (systemVariable.variableCritereType === VariableValueType.NUMERIC ||
                                    systemVariable.variableCritereType === VariableValueType.STRING)) ||
                            (otherSideConditionPart.contentType === ContentTypeEnum.DATE &&
                                systemVariable.variableCritereType === VariableValueType.DATE)
                        );
                    }}
                    filterBySearch={(systemVariable: SystemVariableDTO, search: string): boolean => {
                        return (
                            t("enums.SystemVariable." + systemVariable.variableLabel)
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                            systemVariable.variableCritereType.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onClickOnEntity={(systemVariable: SystemVariableDTO) => {
                        setOpenSoftwareCriteriaModal(false);
                        setOperandSelectedType(OperandEnum.SOFTWARE_CRITERIA);
                        dispatchConditionExpressionList({
                            type: "edit",
                            payload: {
                                index: index,
                                field: side + "Operand",
                                value: {
                                    conditionValue: systemVariable.variableLabel,
                                    conditionKind: OperandEnum.SOFTWARE_CRITERIA,
                                    contentType: ContentTypeEnum[systemVariable.variableCritereType],
                                },
                            },
                        });
                    }}
                    onCancel={() => {
                        setOpenSoftwareCriteriaModal(false);
                    }}
                    sortOptions={[...sortOptionsVariableName, ...sortOptionsDataType]}
                    defaultSortMethod={(a: any, b: any) =>
                        a.variableName.toLowerCase() < b.variableName.toLowerCase() ? 1 : -1
                    }
                />
            )}
            {operandSelectedType === OperandEnum.SOFTWARE_CRITERIA && (
                <div className="ui fluid action input">
                    <button
                        data-cy={side + "_operand"}
                        className="ui icon right labeled button fluid"
                        style={{
                            borderRadius: ".28571429rem",
                            background: "#fff",
                            border: "1px solid rgba(34,36,38,.15)",
                        }}
                        onClick={() => {
                            setOpenSoftwareCriteriaModal(true);
                        }}>
                        {conditionPart.conditionValue.trim().includes("option") ? (
                            <TextOverflow value={conditionPart.conditionValue.trim()} />
                        ) : (
                            <TextOverflow value={t("enums.SystemVariable." + conditionPart.conditionValue.trim())} />
                        )}
                        <i
                            aria-hidden="true"
                            className="remove icon"
                            style={{
                                background: "#fff",
                                color: "#000",
                                fontSize: ".85714286em",
                                opacity: ".8",
                                width: "36px",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                resetOperand();
                            }}></i>
                    </button>
                </div>
            )}

            {/* SANS TYPE */}
            {operandSelectedType === OperandEnum.NOTHING && (
                <Dropdown
                    data-cy={side + "_operand"}
                    name={side + "_operand"}
                    fluid
                    placeholder={t("Select") + "..."}
                    selection
                    disabled={side === "right" && otherSideConditionPart.conditionValue === ""}
                    options={conditionKindList
                        .filter((kind) => {
                            return kind.value !== OperandEnum.FIXED_VALUE || side === "right";
                        })
                        .map((kind) => {
                            kind.text = t(kind.text);
                            return kind;
                        })}
                    value={operandSelectedType}
                    onChange={(e, {value}) => {
                        if ((value as OperandEnum) === OperandEnum.FIXED_VALUE) {
                            setOperandSelectedType(OperandEnum.FIXED_VALUE);
                        } else {
                            openPickerModal(value as OperandEnum);
                        }
                    }}
                />
            )}
        </GridColumn>
    );
};
