import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {CommissionMode, Company, ProrateDaysCountingMode, ProrateMode} from "../../../Services/Company/Types";
import {Button, Divider, Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Global/Form/ErrorMessage";
import {useUpdateCompanyMutation} from "../../../Services/Company/Queries";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";

type CompanyDetailsEditProps = {
    companyId: string;
    company: Company;
    setEdit: Function;
};

const CompanyDetailsEdit: React.FC<CompanyDetailsEditProps> = ({companyId, company, setEdit}) => {
    const {t} = useTranslation();

    // Update company
    const updateCompanyMutation = useUpdateCompanyMutation(companyId);
    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<Company>({mode: "onSubmit"});
    const updateCompany = (formData: Company) => {
        updateCompanyMutation.mutate({
            ...formData,
            internalNovanetUrl: formData.internalNovanetUrl === "" ? null : formData.internalNovanetUrl,
            webserviceLogin: formData.webserviceLogin === "" ? null : formData.webserviceLogin,
            webservicePassword: formData.webservicePassword === "" ? null : formData.webservicePassword,
            retrocessionSettingsActive: formData.retrocessionSettingsActive ?? false,
        });
    };

    // Options Mode de prorate
    const prorateModeOptions = Object.values(ProrateMode).map((prorateMode: ProrateMode) => {
        return {
            key: prorateMode.toString(),
            text: t("enums.ProrateMode." + prorateMode),
            value: prorateMode,
        };
    });

    // Options Mode de comptage des jours de prorate
    const prorateDaysCountingModeOptions = Object.values(ProrateDaysCountingMode).map(
        (prorateDaysCountingMode: ProrateDaysCountingMode) => {
            return {
                key: prorateDaysCountingMode.toString(),
                text: t("enums.ProrateDaysCountingMode." + prorateDaysCountingMode),
                value: prorateDaysCountingMode,
            };
        }
    );

    // Options Mode de commissionnement
    const commissionModeOptions = Object.values(CommissionMode).map((commissionMode: CommissionMode) => {
        return {
            key: commissionMode.toString(),
            text: t("enums.CommissionMode." + commissionMode),
            value: commissionMode,
        };
    });

    return (
        <Form>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="label_edit">
                        <strong>{t("Label")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Input
                            data-cy="label_edit_value"
                            disabled
                            required
                            fluid
                            name="label"
                            type="text"
                            placeholder={t("Code")}
                            value={company.label}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="prorateMode_edit">
                        <strong>{t("prorateMode")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"prorateMode"}
                            defaultValue={company.prorateMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    data-cy="prorateMode_edit_value"
                                    required
                                    name={name}
                                    fluid
                                    placeholder={t("prorateMode")}
                                    onChange={(_, {value}) => onChange(value)}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={prorateModeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="prorateDaysCountingMode_edit">
                        <strong>{t("prorateDaysCountingMode")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"prorateDaysCountingMode"}
                            defaultValue={company.prorateDaysCountingMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    data-cy="prorateDaysCountingMode_edit_value"
                                    required
                                    name={name}
                                    fluid
                                    placeholder={t("prorateDaysCountingMode")}
                                    onChange={(_, {value}) => onChange(value)}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={prorateDaysCountingModeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="internalNovanetUrl_edit">
                        <strong>{t("internalNovanetUrl")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"internalNovanetUrl"}
                            defaultValue={company.internalNovanetUrl}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    data-cy="internalNovanetUrl_edit_value"
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    type="text"
                                    placeholder={t("internalNovanetUrl")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="webserviceLogin_edit">
                        <strong>{t("webserviceLogin")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"webserviceLogin"}
                            defaultValue={company.webserviceLogin}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    data-cy="webserviceLogin_edit_value"
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    type="text"
                                    placeholder={t("webserviceLogin")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="webservicePassword_edit">
                        <strong>{t("webservicePassword")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"webservicePassword"}
                            defaultValue={company.webservicePassword}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    data-cy="webservicePassword_edit_value"
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    type="text"
                                    placeholder={t("webservicePassword")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="commissionMode_edit">
                        <strong>{t("commissionMode")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"commissionMode"}
                            defaultValue={company.commissionMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    data-cy="commissionMode_edit_value"
                                    required
                                    name={name}
                                    fluid
                                    placeholder={t("commissionMode")}
                                    onChange={(_, {value}) => onChange(value)}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={commissionModeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="consoleURL_edit">
                        <strong>{t("consoleURL")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"consoleURL"}
                            defaultValue={company.consoleURL}
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    data-cy="consoleURL_edit_value"
                                    inputref={ref}
                                    required
                                    fluid
                                    name={name}
                                    type="text"
                                    placeholder={t("consoleURL")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="environmentName_edit">
                        <strong>{t("environmentName")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"environmentName"}
                            defaultValue={company.environmentName}
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <>
                                    <Form.Input
                                        data-cy="environmentName_edit_value"
                                        inputref={ref}
                                        required
                                        fluid
                                        name={name}
                                        type="text"
                                        placeholder={t("environmentName")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        error={getErrorMessage(t, errors, name)}
                                        list="environment-names"
                                    />
                                    <datalist id="environment-names">
                                        <option value="LOCAL">LOCAL</option>
                                        <option value="RDO">RDO</option>
                                        <option value="TEST">TEST</option>
                                        <option value="RECETTE">RECETTE</option>
                                        <option value="PREPRODUCTION">PREPRODUCTION</option>
                                        <option value="PRODUCTION">PRODUCTION</option>
                                    </datalist>
                                </>
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8} data-cy="retrocessionSettingsActive_edit">
                        <strong>{t("retrocessionSettingsActive")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"retrocessionSettingsActive"}
                            defaultValue={company.retrocessionSettingsActive}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Checkbox
                                    data-cy="retrocessionSettingsActive_edit_value"
                                    toggle
                                    inputref={ref}
                                    name={name}
                                    onChange={(_, {checked}) => {
                                        onChange(checked);
                                    }}
                                    onBlur={onBlur}
                                    checked={value}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Column>
                    <Grid.Row>
                        <Divider />
                    </Grid.Row>
                    <Grid.Row>
                        <div className={"bottom-button-card"} style={{bottom: 0}}>
                            <Button secondary name="cancel-edit" onClick={() => setEdit(false)}>
                                {t("Cancel")}
                            </Button>

                            <ModalUpdate
                                isModalDisabled={Object.keys(errors).length !== 0}
                                isPending={updateCompanyMutation.isPending}
                                isSuccess={updateCompanyMutation.isSuccess}
                                isError={updateCompanyMutation.isError}
                                resetMutation={updateCompanyMutation.reset}
                                error={updateCompanyMutation.error}
                                onSuccess={() => setEdit(false)}
                                onValidate={handleSubmit(updateCompany)}
                                openModalButtonName="update-company"
                                objectToUpdate="Company"
                                objectType="female"
                            />
                        </div>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default CompanyDetailsEdit;
