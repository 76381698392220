import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Container, Form, Grid, Message } from "semantic-ui-react";
import { Axe, DimensionType, DimensionValueType } from "../../../../../Services/PricingGrid/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";

export const FormDimension = ({
    control,
    errors,
    dimension,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
    defaultValueDimensionType,
    setDefaultValueDimensionType,
    defaultValueDimensionValueType,
    setDefaultValueDimensionValueType,
    defaultValueDimensionAxeType,
    setDefaultValueDimensionAxeType,
}: any) => {
    const {t} = useTranslation();

    const [dimensionTypeChange, setDimensionTypeChange] = useState<boolean>(false);
    const [dimensionValueTypeChange, setDimensionValueTypeChange] = useState<boolean>(false);

    const [typeDimensionOptions, setTypeDimensionOptions] = useState<
        {key: number; text: string; value: DimensionType}[]
    >([
        {key: 1, text: t("enums.DimensionType." + DimensionType.F), value: DimensionType.F},
        {key: 2, text: t("enums.DimensionType." + DimensionType.T), value: DimensionType.T},
    ]);

    const [typeDimensionValueOptions, setTypeDimensionValueOptions] = useState<
        {key: number; text: string; value: DimensionValueType}[]
    >([
        {key: 1, text: t("enums.DimensionValueType." + DimensionValueType.INT), value: DimensionValueType.INT},
        {key: 2, text: t("enums.DimensionValueType." + DimensionValueType.STRING), value: DimensionValueType.STRING},
        {key: 3, text: t("enums.DimensionValueType." + DimensionValueType.DOUBLE), value: DimensionValueType.DOUBLE},
        {key: 4, text: t("enums.DimensionValueType." + DimensionValueType.DATE), value: DimensionValueType.DATE},
    ]);
    const typeDimensionAxeOptions = [
        {key: 1, text: t("enums.DimensionAxeType." + Axe.L), value: Axe.L},
        {key: 2, text: t("enums.DimensionAxeType." + Axe.C), value: Axe.C},
    ];

    const checkTypeDimensionOptions = (value: DimensionType) => {
        if (value === DimensionType.F) {
            setTypeDimensionValueOptions([
                {
                    key: 1,
                    text: t("enums.DimensionValueType." + DimensionValueType.INT),
                    value: DimensionValueType.INT,
                },
                {
                    key: 2,
                    text: t("enums.DimensionValueType." + DimensionValueType.STRING),
                    value: DimensionValueType.STRING,
                },
                {
                    key: 3,
                    text: t("enums.DimensionValueType." + DimensionValueType.DOUBLE),
                    value: DimensionValueType.DOUBLE,
                },
            ]);
        } else {
            setTypeDimensionValueOptions([
                {
                    key: 1,
                    text: t("enums.DimensionValueType." + DimensionValueType.INT),
                    value: DimensionValueType.INT,
                },
                {
                    key: 2,
                    text: t("enums.DimensionValueType." + DimensionValueType.DOUBLE),
                    value: DimensionValueType.DOUBLE,
                },
                {
                    key: 3,
                    text: t("enums.DimensionValueType." + DimensionValueType.DATE),
                    value: DimensionValueType.DATE,
                },
            ]);
        }
    };

    const checkTypeDimensionValueOptions = (value: DimensionValueType) => {
        if (value === DimensionValueType.DATE) {
            setTypeDimensionOptions([
                {key: 1, text: t("enums.DimensionType." + DimensionType.T), value: DimensionType.T},
            ]);
        } else if (value === DimensionValueType.STRING) {
            setTypeDimensionOptions([
                {key: 1, text: t("enums.DimensionType." + DimensionType.F), value: DimensionType.F},
            ]);
        } else {
            setTypeDimensionOptions([
                {key: 1, text: t("enums.DimensionType." + DimensionType.F), value: DimensionType.F},
                {key: 2, text: t("enums.DimensionType." + DimensionType.T), value: DimensionType.T},
            ]);
        }
    };

    const handleChangeDimensionType = (onchange: (param: string) => void, value: any) => {
        if (dimension !== undefined && value !== dimension.dimensionType) {
            setDimensionTypeChange(true);
        } else {
            setDimensionTypeChange(false);
        }

        checkTypeDimensionOptions(value);
        onchange(value);
    };

    const handleChangeDimensionValueType = (onchange: (param: string) => void, value: any) => {
        if (dimension !== undefined && value !== dimension.dimensionValueType) {
            setDimensionValueTypeChange(true);
        } else {
            setDimensionValueTypeChange(false);
        }
        checkTypeDimensionValueOptions(value);

        onchange(value);
    };

    return (
        <Container>
            <Form>
                <Grid centered columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={dimension === undefined ? defaultValueCode : dimension.code}
                                rules={{required: true}}
                                name={"code"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        autoFocus
                                        fluid
                                        required
                                        type={"text"}
                                        name={name}
                                        label={t("Code")}
                                        placeholder={t("Code")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueCode !== undefined && setDefaultValueCode(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="20"
                                        error={getErrorMessage(t, errors, "code")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                defaultValue={dimension === undefined ? defaultValueLabel : dimension.label}
                                rules={{required: true}}
                                name={"label"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        type={"text"}
                                        name={name}
                                        label={t("Label")}
                                        placeholder={t("Label")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueLabel !== undefined && setDefaultValueLabel(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={getErrorMessage(t, errors, "label")}
                                    />
                                )}
                            />

                            <Controller
                                rules={{required: true}}
                                control={control}
                                defaultValue={
                                    dimension === undefined ? defaultValueDimensionType : dimension.dimensionType
                                }
                                name={"dimensionType"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        required
                                        fluid
                                        label={t("Dimension_type")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Dimension_type")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueDimensionType !== undefined &&
                                                setDefaultValueDimensionType(value);
                                            handleChangeDimensionType(onChange, value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "dimensionType")}
                                        options={typeDimensionOptions}
                                    />
                                )}
                            />

                            <Controller
                                rules={{required: true}}
                                control={control}
                                defaultValue={
                                    dimension === undefined
                                        ? defaultValueDimensionValueType
                                        : dimension.dimensionValueType
                                }
                                name={"dimensionValueType"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        required
                                        fluid
                                        label={t("Dimension_value_type")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Dimension_value_type")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueDimensionValueType !== undefined &&
                                                setDefaultValueDimensionValueType(value);
                                            handleChangeDimensionValueType(onChange, value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "dimensionValueType")}
                                        options={typeDimensionValueOptions}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={
                                    dimension === undefined
                                        ? defaultValueDimensionAxeType
                                        : dimension.visualizationGridDTO.axe
                                }
                                name={"dimensionAxe"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        required
                                        fluid
                                        label={t("Dimension_axe")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Dimension_axe")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueDimensionAxeType !== undefined &&
                                                setDefaultValueDimensionAxeType(value);
                                            onChange(value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "dimensionAxe")}
                                        options={typeDimensionAxeOptions}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column>
                        {(dimensionTypeChange || dimensionValueTypeChange) && (
                            <Message warning>
                                <Message.Header>{t("Warning")}</Message.Header>
                                <p>
                                    {t(
                                        "Changing the dimension type or value type will delete the values currently associated with that dimension"
                                    )}
                                </p>
                            </Message>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
