import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { AccordionContent, AccordionTitle, Button, Divider, Grid, GridColumn, Header, Icon } from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import { ConditionPart, OperandEnum } from "../../../../../Services/Condition/Types";
import {
    useDeleteDerogatoryTaxMutation,
    useUpdateDerogatoryTaxMutation
} from "../../../../../Services/InsurancePackage/Queries";
import { ProductRegistryTaxJoin } from "../../../../../Services/ProductBehaviour/Types";
import { conditionPartListToString } from "../../../../../Utils/ConditionPartUtils";
import ConditionHandling from "../../../Condition/ConditionHandling";
import ConditionView from "../../../Condition/Fragments/ConditionView";
import BehaviourTaxesAssignement from "../../../ProductBehaviour/Fragments/BehaviourTaxesAssignement";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type DerogatoriesTaxesAccordionPropsType = {
    insurancePackageId: string;
    condition: ProductRegistryTaxJoin;
    index: number;
    activeIndex: number;
    setActiveIndex: Function;
    registryCode: string;
    numberOfConditions: number;
};

const DerogatoriesTaxesAccordion = ({
    insurancePackageId,
    condition,
    index,
    activeIndex,
    setActiveIndex,
    registryCode,
    numberOfConditions,
}: DerogatoriesTaxesAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const conditionKindList = [
        {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
        {key: 2, text: "Question", value: OperandEnum.QUESTION, type: "female"},
        {key: 3, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 4, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
        {key: 5, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
    ];

    const updateDerogatoryTaxMutation = useUpdateDerogatoryTaxMutation(
        insurancePackageId,
        condition.id.toString(),
        registryCode
    );
    const deleteDerogatoryTaxMutation = useDeleteDerogatoryTaxMutation(
        insurancePackageId,
        condition.id.toString(),
        registryCode
    );

    return (
        <div key={index}>
            <AccordionTitle
                style={{display: "flex", alignItems: "center"}}
                name={"condition-" + (index + 1)}
                active={activeIndex === index}
                index={index}
                onClick={() => {
                    if (activeIndex === index) setActiveIndex(-1);
                    else setActiveIndex(index);
                }}>
                <Icon name="dropdown" />
                <div style={{display: "flex", alignItems: "center"}}>
                    {t("Condition") + " " + (index + 1)}
                    {!(activeIndex === index) && numberOfConditions !== 1 && (
                        <>
                            {" "}
                            -
                            <p style={{marginLeft: "4px", fontWeight: "lighter", fontSize: "12px", color: "grey"}}>
                                {condition.ifCondition
                                    ? conditionPartListToString(condition.conditionPartList)
                                    : t("Else")}
                            </p>
                        </>
                    )}
                </div>
            </AccordionTitle>
            <AccordionContent active={activeIndex === index}>
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>

                {editCondition ? (
                    <ConditionHandling
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: ProductRegistryTaxJoin = {
                                ...condition,
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate,
                            };
                            updateDerogatoryTaxMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateDerogatoryTaxMutation}
                        versionId={params.versionId}
                    />
                ) : (
                    <>
                        <Grid>
                            <>
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("If")} :
                                </GridColumn>
                                <GridColumn width={14} textAlign="center" className="condition">
                                    <ConditionView conditionPartList={condition.conditionPartList} />
                                </GridColumn>
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    <Button
                                        name={"editCondition" + Number(index + 1)}
                                        color="grey"
                                        icon="edit"
                                        compact
                                        basic
                                        onClick={() => {
                                            setEditCondition(true);
                                        }}
                                    />
                                </GridColumn>
                            </>
                        </Grid>
                        <Divider horizontal>
                            <Header as="h4">{t("Assignment")}</Header>
                        </Divider>
                        <BehaviourTaxesAssignement
                            condition={condition}
                            onValidateMutate={(categoryTaxe: string) => {
                                updateDerogatoryTaxMutation.mutate({
                                    ...condition,
                                    taxCategoryCode: categoryTaxe,
                                });
                            }}
                            updateMutaion={updateDerogatoryTaxMutation}
                        />
                        <Grid>
                            <GridColumn floated={"right"} textAlign={"right"} style={{paddingRight: "15px"}}>
                                <ModalDelete
                                    isSuccess={deleteDerogatoryTaxMutation.isSuccess}
                                    isPending={deleteDerogatoryTaxMutation.isPending}
                                    isError={deleteDerogatoryTaxMutation.isError}
                                    error={deleteDerogatoryTaxMutation.error}
                                    resetMutation={deleteDerogatoryTaxMutation.reset}
                                    objectToDelete="Condition"
                                    objectType="female"
                                    openModalButtonName={"delete-condition-" + Number(index + 1)}
                                    onValidate={() => deleteDerogatoryTaxMutation.mutate()}
                                    onSuccess={() => setActiveIndex(-1)}
                                />
                            </GridColumn>
                        </Grid>
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default DerogatoriesTaxesAccordion;
