import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Dropdown, Grid, GridColumn, GridRow, Icon, Popup } from "semantic-ui-react";
import {
    ConditionExpressionView,
    ConditionKindEnum,
    ConditionPart,
    LogicalOperatorEnum,
    OperandEnum,
    SubBlock
} from "../../../../Services/Condition/Types";
import ConditionExpression from "./ConditionExpression";

type ConditionSubBlockPropsTypes = {
    indexSubBlock: number;
    subBlock: SubBlock;
    subBlockList: SubBlock[];
    subBlockLength: number;
    dispatchSubBlockList: Function;
    conditionKindList: {key: number; text: string; value: OperandEnum; type: string}[];
    versionId?: string;
};

type ActionType =
    | {type: "add"; payload: number}
    | {type: "remove"; payload: number}
    | {
          type: "edit";
          payload: {
              index: number;
              field: "operator" | "leftOperand" | "rightOperand" | "logicalOperator";
              value: ConditionPart;
          };
      }
    | {type: "reset"; payload: number}
    | {type: "updateList"; payload: ConditionExpressionView[]};

const ConditionSubBlock = ({
    indexSubBlock,
    subBlock,
    subBlockList,
    subBlockLength,
    dispatchSubBlockList,
    conditionKindList,
    versionId,
}: ConditionSubBlockPropsTypes) => {
    const {t} = useTranslation();

    const logicalOperatorOptions = [
        {key: 1, text: t("enums.LogicalOperatorEnum." + LogicalOperatorEnum.AND), value: LogicalOperatorEnum.AND},
        {key: 2, text: t("enums.LogicalOperatorEnum." + LogicalOperatorEnum.OR), value: LogicalOperatorEnum.OR},
    ];

    const reducerConditionList = (state: {conditionExpressionList: ConditionExpressionView[]}, action: ActionType) => {
        const conditionExpressionList = [...state.conditionExpressionList];
        switch (action.type) {
            case "add":
                conditionExpressionList.splice(action.payload + 1, 0, {
                    leftOperand: {
                        conditionValue: "",
                        conditionKind: null,
                        contentType: null,
                    },
                    operator: {conditionValue: "", conditionKind: null, contentType: null},
                    rightOperand: {conditionValue: "", conditionKind: null, contentType: null},
                    logicalOperator: undefined,
                });
                conditionExpressionList[conditionExpressionList.length - 2].logicalOperator =
                    conditionExpressionList[0].logicalOperator;
                dispatchSubBlockList({
                    type: "editSubBlock",
                    payload: {
                        indexSubBlock: indexSubBlock,
                        field: "listConditionExpression",
                        value: conditionExpressionList,
                    },
                });
                return {conditionExpressionList: conditionExpressionList};
            case "remove":
                conditionExpressionList.splice(action.payload, 1);
                conditionExpressionList[conditionExpressionList.length - 1].logicalOperator = undefined;
                dispatchSubBlockList({
                    type: "editSubBlock",
                    payload: {
                        indexSubBlock: indexSubBlock,
                        field: "listConditionExpression",
                        value: conditionExpressionList,
                    },
                });
                return {conditionExpressionList: conditionExpressionList};
            case "edit":
                conditionExpressionList[action.payload.index][action.payload.field] = action.payload.value;
                dispatchSubBlockList({
                    type: "editSubBlock",
                    payload: {
                        indexSubBlock: indexSubBlock,
                        field: "listConditionExpression",
                        value: conditionExpressionList,
                    },
                });
                return {conditionExpressionList: conditionExpressionList};
            case "reset":
                conditionExpressionList.splice(action.payload, 1, {
                    leftOperand: {
                        conditionValue: "",
                        conditionKind: null,
                        contentType: null,
                    },
                    operator: {conditionValue: "", conditionKind: null, contentType: null},
                    rightOperand: {conditionValue: "", conditionKind: null, contentType: null},
                    logicalOperator: undefined,
                });
                dispatchSubBlockList({
                    type: "editSubBlock",
                    payload: {
                        indexSubBlock: indexSubBlock,
                        field: "listConditionExpression",
                        value: conditionExpressionList,
                    },
                });
                return {conditionExpressionList: conditionExpressionList};
            case "updateList":
                return {conditionExpressionList: action.payload};
            default:
                throw new Error();
        }
    };

    const [state, dispatchConditionExpressionList] = useReducer(reducerConditionList, {
        conditionExpressionList: [],
    });

    useEffect(() => {
        dispatchConditionExpressionList({type: "updateList", payload: subBlock.listConditionExpression});
    }, [subBlock, dispatchSubBlockList]);

    return (
        <>
            <GridRow verticalAlign="middle" textAlign="center">
                <GridColumn width={1} textAlign="center">
                    <Popup
                        content={
                            subBlock.logicalNegation !== undefined
                                ? t("Desactivate_logical_negation")
                                : t("Activate_logical_negation")
                        }
                        position="top center"
                        trigger={
                            <Button
                                className="NOT"
                                compact
                                color={"grey"}
                                active={subBlock.logicalNegation !== undefined}
                                size="medium"
                                basic={subBlock.logicalNegation === undefined}
                                onClick={(e, value) =>
                                    dispatchSubBlockList({
                                        type: "editSubBlock",
                                        payload: {
                                            indexSubBlock: indexSubBlock,
                                            field: "logicalNegation",
                                            value:
                                                subBlock.logicalNegation === undefined
                                                    ? {
                                                          conditionValue: "NOT",
                                                          conditionKind: ConditionKindEnum.LOGICAL_OPERATOR,
                                                          contentType: null,
                                                      }
                                                    : undefined,
                                        },
                                    })
                                }>
                                {t("NOT")}
                            </Button>
                        }
                    />
                </GridColumn>
                <GridColumn width={14}>
                    {state.conditionExpressionList.map((condition, index) => (
                        <ConditionExpression
                            key={index}
                            index={index}
                            condition={condition}
                            conditionExpressionList={state.conditionExpressionList}
                            dispatchConditionExpressionList={dispatchConditionExpressionList}
                            conditionKindList={conditionKindList}
                            versionId={versionId}
                        />
                    ))}
                </GridColumn>
                <GridColumn width={1}>
                    <Grid>
                        <GridRow>
                            <GridColumn verticalAlign="bottom">
                                <Button
                                    className="deleteSubBlock"
                                    color="grey"
                                    icon="trash"
                                    onClick={() => {
                                        dispatchSubBlockList({type: "remove", payload: indexSubBlock});
                                    }}></Button>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </GridColumn>
            </GridRow>
            {indexSubBlock < subBlockLength - 1 ? (
                <Divider horizontal>
                    <Dropdown
                        placeholder={t("Logical_operator")}
                        fluid
                        selection
                        data-cy="logicalOperatorSubBlock"
                        options={logicalOperatorOptions}
                        value={subBlock.logicalOperator === undefined ? "" : subBlock.logicalOperator.conditionValue}
                        disabled={indexSubBlock > 0 && indexSubBlock < subBlockLength}
                        style={{border: "dashed grey"}}
                        onChange={(e, value) => {
                            subBlockList.forEach((subBlock, indexSubBlock) => {
                                if (indexSubBlock < subBlockList.length - 1) {
                                    dispatchSubBlockList({
                                        type: "editSubBlock",
                                        payload: {
                                            indexSubBlock: indexSubBlock,
                                            field: "logicalOperator",
                                            value: {
                                                conditionValue: value.value,
                                                conditionKind: ConditionKindEnum.LOGICAL_OPERATOR,
                                                contentType: null,
                                            },
                                        },
                                    });
                                }
                            });
                        }}
                    />
                </Divider>
            ) : (
                <Divider horizontal fitted>
                    <Button
                        className="addSubBlock"
                        basic
                        color="blue"
                        style={{border: "dashed"}}
                        onClick={() => {
                            dispatchSubBlockList({type: "add", payload: indexSubBlock});
                        }}>
                        <Icon name="add" fitted></Icon>
                    </Button>
                </Divider>
            )}
        </>
    );
};

export default ConditionSubBlock;
