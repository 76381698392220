import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Segment } from "semantic-ui-react";
import { useGetSplitting } from "../../../../Services/Splitting/Queries";
import SplittingDetailsEdit from "./SplittingDetailsEdit";
import SplittingDetailsRead from "./SplittingDetailsRead";

const SplittingDetails: React.FC<{productId: string; splittingId: string}> = ({productId, splittingId}) => {
    const {t} = useTranslation();
    const [edit, setEdit] = useState(false);
    const {data: splitting} = useGetSplitting(productId, splittingId);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Segment color="green">
                        <Header as="h2" dividing>
                            {t("Splitting")}
                        </Header>
                        {splitting !== undefined &&
                            (edit ? (
                                <SplittingDetailsEdit
                                    productId={productId}
                                    splittingId={splittingId}
                                    splitting={splitting}
                                    setEdit={setEdit}
                                />
                            ) : (
                                <SplittingDetailsRead productId={productId} splitting={splitting} setEdit={setEdit} />
                            ))}
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default SplittingDetails;
