import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import {
    AccordionContent,
    AccordionTitle,
    Button,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon,
    Message
} from "semantic-ui-react";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import {
    useCheckSyntax,
    useDeleteComputingRRuleCondition as useDeleteComputingRuleCondition,
    useUpdateComputingRuleCondition
} from "../../../Services/ComputingRule/Queries";
import { ComputingRule, ComputingRuleCondition } from "../../../Services/ComputingRule/Types";
import { ConditionPart, OperandEnum } from "../../../Services/Condition/Types";
import { ComputingRuleRoundingMode } from "../../../Services/InsurancePackage/Types";
import { VersionDTO } from "../../../Services/Version/Types";
import { conditionPartListToString } from "../../../Utils/ConditionPartUtils";
import { GenericReducerActionType } from "../../../Utils/ReducerUtils";
import ConditionHandling from "../Condition/ConditionHandling";
import ConditionView from "../Condition/Fragments/ConditionView";
import {
    AssignmentType,
    VariableConditionActionType
} from "../Variables/Fragments/VariableConditions/VariableConditionAssignementReducer";
import ComputingRuleAssignmentEdit from "./ComputingRuleAssignment/ComputingRuleAssignmentEdit";
import ComputingRuleAssignmentView from "./ComputingRuleAssignment/ComputingRuleAssignmentView";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
    coverageGroupId: string;
    coverageId: string;
    optionId: string;
}

interface ComputingRulePropsType {
    computingRule: ComputingRule;
    version: VersionDTO;
    computingRuleCondition: ComputingRuleCondition;
    index: number;
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    refetch: any;
    numberOfConditions: number;
}

const ComputingRuleAccordion = ({
    computingRule,
    version,
    computingRuleCondition,
    index,
    activeIndex,
    setActiveIndex,
    refetch,
    numberOfConditions,
}: ComputingRulePropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);
    const [editAssignment, setEditAssignment] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
    const [isComputingRuleChange, setIsComputingRuleChange] = useState<boolean>();

    const computingRuleReducer = (state: ComputingRuleCondition, action: VariableConditionActionType) => {
        switch (action.type) {
            case GenericReducerActionType.EDIT: {
                switch (action.assignmentType) {
                    case AssignmentType.VARIABLE_COMPUTING_RULE:
                        return {
                            ...state,
                            calculFormule: null,
                            rulePartList: action.payload.rulePartList.length === 0 ? [] : action.payload.rulePartList,
                            roundingMode: action.payload.roundingMode,
                            decimalCount: action.payload.decimalCount,
                        };
                    default:
                        return {
                            ...state,
                        };
                }
            }
            case GenericReducerActionType.UNDO:
                return action.payload;
            default:
                return {
                    ...state,
                };
        }
    };
    const [conditionState, dispatchCondition] = useReducer(computingRuleReducer, {
        id: null,
        ifCondition: null,
        orderCondition: null,
        calculFormule: "",
        conditionPartList: [],
        rulePartList: [],
        roundingMode: ComputingRuleRoundingMode.ROUND_HALF_UP,
        decimalCount: 2,
    });

    const conditionKindList = [
        {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
        {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
        {
            key: 4,
            text: "Computing rule variables",
            value: OperandEnum.VARIABLE_COMPUTING_RULE,
            type: "female",
        },
        {
            key: 5,
            text: "Fixed value",
            value: OperandEnum.FIXED_VALUE,
            type: "female",
        },
    ];

    const checkSyntax = useCheckSyntax();
    const updateComputingRuleConditionMutation = useUpdateComputingRuleCondition(
        computingRule.id !== null ? computingRule.id : 0,
        computingRuleCondition.id !== null ? computingRuleCondition.id : 0
    );

    const deleteComputingRuleConditionMutation = useDeleteComputingRuleCondition(
        computingRule.id !== null ? computingRule.id : 0,
        computingRuleCondition.id !== null ? computingRuleCondition.id : 0
    );

    const closeAssignmentEdition = () => {
        dispatchCondition({
            type: GenericReducerActionType.UNDO,
            assignmentType: AssignmentType.NOTHING,
            payload: computingRule.computingRuleConditions[index],
        });
        setEditAssignment(false);
    };

    const verify = () => {
        setIsComputingRuleChange(false);
        checkSyntax.mutate(conditionState.rulePartList);
    };

    useEffect(() => {
        dispatchCondition({
            type: GenericReducerActionType.EDIT,
            assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
            payload: computingRuleCondition,
        });
    }, [computingRuleCondition]);

    useEffect(() => {
        if (checkSyntax.isError && !isComputingRuleChange) setIsErrorVisible(true);
    }, [checkSyntax, isComputingRuleChange]);

    useEffect(() => {
        if (updateComputingRuleConditionMutation.isSuccess) refetch();
    }, [updateComputingRuleConditionMutation.isSuccess, refetch]);

    return (
        <>
            <AccordionTitle
                active={activeIndex === index}
                style={{display: "flex", alignItems: "center"}}
                onClick={() => {
                    if (activeIndex === index) setActiveIndex(-1);
                    else setActiveIndex(index);
                }}
                index={index}>
                <Icon name="dropdown" />
                <div style={{display: "flex", alignItems: "center"}}>
                    {t("Condition") + " " + (index + 1)}
                    {!(activeIndex === index) && numberOfConditions !== 1 && (
                        <>
                            {" "}
                            -
                            <p style={{marginLeft: "4px", fontWeight: "lighter", fontSize: "12px", color: "grey"}}>
                                {computingRuleCondition.ifCondition
                                    ? conditionPartListToString(computingRuleCondition.conditionPartList)
                                    : t("Else")}
                            </p>
                        </>
                    )}
                </div>
            </AccordionTitle>
            <AccordionContent active={activeIndex === index}>
                {computingRuleCondition.ifCondition === "( ( 1 == 1 ) )" && (
                    <Message warning>
                        {t(
                            "This condition contains default values, it would be convenient to update it before validating product version's"
                        )}
                    </Message>
                )}
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>

                {editCondition ? (
                    <ConditionHandling
                        versionId={params.versionId}
                        conditionKindList={conditionKindList}
                        conditionPartList={computingRuleCondition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: Partial<ComputingRuleCondition> = {
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate,
                                orderCondition: computingRuleCondition.orderCondition,
                                calculFormule: computingRuleCondition.calculFormule,
                                rulePartList: computingRuleCondition.rulePartList,
                                decimalCount: computingRuleCondition.decimalCount,
                                roundingMode: computingRuleCondition.roundingMode,
                            };
                            updateComputingRuleConditionMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateComputingRuleConditionMutation}
                    />
                ) : (
                    <Grid columns={3}>
                        {computingRule.computingRuleConditions.length === 1 ? (
                            <GridColumn width={2} textAlign="left" verticalAlign="middle">
                                {t("Default value")}
                            </GridColumn>
                        ) : index !== computingRule.computingRuleConditions.length - 1 ? (
                            <>
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("If")} :
                                </GridColumn>
                                <GridColumn width={14} textAlign="center" className="condition">
                                    <ConditionView conditionPartList={computingRuleCondition.conditionPartList} />
                                </GridColumn>
                                {version !== undefined && version.pipDate === null && (
                                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                        <Button
                                            name={"editCondition" + Number(index + 1)}
                                            color="grey"
                                            icon="edit"
                                            compact
                                            basic
                                            onClick={() => {
                                                setEditCondition(true);
                                            }}
                                        />
                                    </GridColumn>
                                )}
                            </>
                        ) : (
                            <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                {t("Else")}
                            </GridColumn>
                        )}
                    </Grid>
                )}

                <Divider horizontal>
                    <Header as="h4">{t("Assignment")}</Header>
                </Divider>

                {editAssignment ? (
                    <Grid>
                        <GridRow>
                            <GridColumn
                                width={1}
                                textAlign="center"
                                verticalAlign="middle"
                                style={{whiteSpace: "nowrap"}}>
                                {t("Then")} :
                            </GridColumn>
                            <GridColumn width={15} textAlign="left">
                                <ComputingRuleAssignmentEdit
                                    computingRuleRoundingMode={conditionState.roundingMode}
                                    computingRuleDecimalCount={conditionState.decimalCount}
                                    computingRuleRulePartList={conditionState.rulePartList}
                                    dispatchCondition={dispatchCondition}
                                    checkSyntax={checkSyntax}
                                    setIsErrorVisible={setIsErrorVisible}
                                    setIsComputingRuleChange={setIsComputingRuleChange}
                                    isComputingRuleChange={isComputingRuleChange}
                                />
                            </GridColumn>
                        </GridRow>

                        <GridRow>
                            <GridColumn width={12} textAlign={"left"}>
                                <Button name="verify" primary onClick={() => verify()}>
                                    {t("Verify")}
                                </Button>
                                {checkSyntax.error && isErrorVisible && (
                                    <Message compact negative>
                                        <p>{t("request_errors." + checkSyntax.error.message)}</p>
                                    </Message>
                                )}
                            </GridColumn>
                            <GridColumn width={4} textAlign={"right"}>
                                <Button name="cancel" secondary onClick={() => closeAssignmentEdition()}>
                                    {t("Cancel")}
                                </Button>
                                <ModalUpdate
                                    isPending={updateComputingRuleConditionMutation.isPending}
                                    isSuccess={updateComputingRuleConditionMutation.isSuccess}
                                    isError={updateComputingRuleConditionMutation.isError}
                                    onValidate={() => updateComputingRuleConditionMutation.mutate(conditionState)}
                                    resetMutation={updateComputingRuleConditionMutation.reset}
                                    error={updateComputingRuleConditionMutation.error}
                                    onSuccess={() => {
                                        setEditAssignment(false);
                                        refetch();
                                    }}
                                    objectToUpdate={t("Assignment")}
                                    objectType="nonbinary"
                                />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                ) : (
                    <Grid>
                        <GridRow>
                            <GridColumn
                                width={1}
                                textAlign="center"
                                verticalAlign="middle"
                                style={{whiteSpace: "nowrap"}}>
                                {t("Then")} :
                            </GridColumn>
                            <GridColumn width={14} textAlign="left" verticalAlign="middle">
                                <ComputingRuleAssignmentView computingRuleCondition={computingRuleCondition} />
                            </GridColumn>
                            {version !== undefined && version.pipDate === null && (
                                <GridColumn floated="right" textAlign="center" verticalAlign="middle">
                                    <Button
                                        className="editAssignment"
                                        name="editAssignment"
                                        color="grey"
                                        icon="edit"
                                        compact
                                        basic
                                        onClick={async () => {
                                            setEditAssignment(true);
                                        }}
                                    />
                                </GridColumn>
                            )}
                        </GridRow>
                    </Grid>
                )}

                {index !== computingRule.computingRuleConditions.length - 1 &&
                    version !== undefined &&
                    version.pipDate === null && (
                        <>
                            <Divider />
                            <Grid>
                                <GridRow>
                                    <GridColumn width={14} />
                                    <GridColumn width={2}>
                                        <ModalDelete
                                            isSuccess={deleteComputingRuleConditionMutation.isSuccess}
                                            isPending={deleteComputingRuleConditionMutation.isPending}
                                            isError={deleteComputingRuleConditionMutation.isError}
                                            error={deleteComputingRuleConditionMutation.error}
                                            resetMutation={deleteComputingRuleConditionMutation.reset}
                                            objectToDelete="Condition"
                                            objectType="female"
                                            openModalButtonName={"deleteCondition" + Number(index + 1)}
                                            onValidate={() =>
                                                deleteComputingRuleConditionMutation.mutate()
                                            }
                                            onSuccess={() => {
                                                refetch();
                                                setActiveIndex(0);
                                            }}
                                        />
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </>
                    )}
            </AccordionContent>
        </>
    );
};

export default ComputingRuleAccordion;
