import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import { useDeleteVersionMutation } from "../../../../../Services/Version/Queries";
import { VersionDTO } from "../../../../../Services/Version/Types";

type VersionCardPropsType = {
    cardTitle: string;
    version: VersionDTO;
    isRemovableVersion: boolean;
};

const VersionCard: React.FC<VersionCardPropsType> = ({...props}) => {
    const {t} = useTranslation();

    const deleteVersionMutation = useDeleteVersionMutation(String(props.version.productId), String(props.version.id));

    const handleDelete = () => {
        deleteVersionMutation.mutate();
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {props.cardTitle}
            </Header>

            <Grid columns="equal" className="grid-padding">
                <Grid.Row role="row">
                    <Grid.Column width={6} role="cell">
                        <strong>{t("Creation date")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell">
                        {t("format_date", {value: props.version.creationDate})}
                    </Grid.Column>

                    <Grid.Column width={6} role="cell">
                        <strong>{t("Label")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell">
                        {props.version.label}
                    </Grid.Column>

                    <Grid.Column width={6} role="cell">
                        <strong>{t("Effective date")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell">
                        {t("format_date", {value: props.version.effectiveDate})}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="bottom-button-card">
                <Divider />
                <Button
                    name="goToVersion"
                    secondary
                    as={Link}
                    to={`/products/${props.version.productId}/versions/${props.version.id}`}>
                    {t("View")}
                </Button>
                {props.isRemovableVersion && (
                    <ModalDelete
                        onValidate={handleDelete}
                        objectToDelete={t("version")}
                        isSuccess={deleteVersionMutation.isSuccess}
                        isPending={deleteVersionMutation.isPending}
                        isError={deleteVersionMutation.isError}
                        objectType={"female"}
                    />
                )}
            </div>
        </Segment>
    );
};

export default VersionCard;
